import { UserRequest, UserResponse } from "../../types/types_api";
import { RootStore } from "../RootStore";
import { action, makeAutoObservable, observable } from 'mobx';
import { userService } from "../../services/UserService";
import { Constants } from "../../contants/Constants";
import { IUserStore, loginCallback } from "../interfaces/IUserStore";


export class UserStore implements IUserStore {

    /**
     *
     *
     * @type {(RootStore | undefined)}
     * @memberof UserStore
     */
    _rootStore: RootStore | undefined = undefined;

    @observable
    _currentUser?: UserResponse;

    @observable
    _isLoggedIn: boolean = false

    _accessToken: string = ''

    @observable
    _error: string | undefined = undefined

    @observable
    _loading: boolean = false

    _userId: string = ''

    constructor() {
        makeAutoObservable(this);
        this._error = undefined;
        this._loading = false;
        this._rootStore = undefined
        this._isLoggedIn = false
        this._loading = false
        this._userId = ''
    }


    set rootStore(rootStore: RootStore | undefined) {
        this._rootStore = rootStore
    }

    get rootStore(): RootStore | undefined {
        return this._rootStore
    }

    get isLoggedIn(): boolean {
        return this._isLoggedIn
    }

    @action
    setLoggedIn(isLoggedIn: boolean) {
        this._isLoggedIn = isLoggedIn
    }

    get accessToken(): string {
        return this._accessToken
    }


    async getAccessToken(): Promise<string | undefined> {
        return Promise.resolve(this._accessToken)
    }

    @action
    setAccessToken(accessToken: string) {
        this._accessToken = accessToken
    }

    @action
    setUser(user: UserResponse | undefined) {
        this._currentUser = user;
    }

    @action
    clearUser() {
        this._currentUser = undefined;
    }

    get currentUser(): UserResponse | undefined {
        return this._currentUser
    }

    get errorMessage(): string | undefined {
        return this._error
    }

    @action
    setErrorMessage(errorMessage: string | undefined) {
        // console.log(errorMessage)
        this._error = errorMessage;
    }

    @action
    setLoading(loading: boolean) {
        this._loading = loading;
    }

    get loading(): boolean {
        return this._loading
    }

    @action
    async login(username: string, password: string, loginCallback?: loginCallback): Promise<boolean | undefined> {

        try {
            this.setLoading(true)
            const response = await userService.login(username, password);
            if (response !== undefined) {

                let loginResponse = response
                // this.userId = response.data.userId
                this.setAccessToken(loginResponse.access_token);
                this.setLoggedIn(true)

                // await this.getUser(loginResponse.guid)
                // console.log(loginResponse)
                let userId = loginResponse.user_id
                // console.log(userId)
                this._userId = userId

                //userId = "fc43e4a2-5559-44c7-b668-0880d1133930"
                let userResponse: UserResponse = {
                    username: username,
                    guid: userId,
                }

                this.setUser(userResponse)
                this.setLoading(false)
                this.setErrorMessage(undefined);
                return Promise.resolve(true)
            }
            this.setErrorMessage(Constants.LoginErrors.InvalidCredentials);
            this.setLoading(false)
            return Promise.resolve(false)
        } catch (error) {
            console.error('Error logging in:', error);
            this.setErrorMessage(Constants.LoginErrors.InvalidCredentials);
            this.setLoading(false)
            return Promise.resolve(false)
        }
    }

    @action
    async logout() {

        try {
            if (this._accessToken !== undefined) {
                userService.logout(this._accessToken);
            }
            if (this.rootStore !== undefined) {
                // console.log('clearing root store')
                this.rootStore.clear()
            }

            this.clear();
        } catch (error) {
            this.setErrorMessage('Logout failed.');
        }
    }

    // async getUser(): Promise<void> {

    //     if (!this.accessToken) {
    //         throw new Error('Access token is not initialized');
    //     }

    //     try {
    //         let userId = this._userId
    //         if (this._accessToken !== undefined) {
    //             const newUser = await userService.getUser(userId, this._accessToken);
    //             this.setUser(newUser);
    //         }
    //         return Promise.resolve()
    //     } catch (error) {
    //         // console.error('Error creating user:', error);
    //         // throw error;

    //         this.setErrorMessage('Error creating user.');
    //         return Promise.reject(error)
    //     }
    // }

    checkUserLogIn(): Promise<boolean> {
        return Promise.resolve(this.isLoggedIn)
    }

    async retrieveUser(username: string, token: string): Promise<UserResponse | undefined> {
        return userService.getUser(username, token)
    }

    async createUser(userRequest: UserRequest, token: string): Promise<UserResponse | undefined> {
        
        return userService.createUser(userRequest, token)
    }

    async getOrCreateUser(username: string, token: string): Promise<UserResponse | undefined> {
        try {
            let userResponse = await this.retrieveUser(username, token)
            if (userResponse?.guid !== null) {
                return userResponse
            } else {
                let userRequest: UserRequest = {
                    username: username
                }
                let newUserResponse = await this.createUser(userRequest, token)
                return newUserResponse
            }
        } catch (error) {
            console.log(error)
            return undefined
        }
        
    }

    async getUser(username: string, token: string): Promise<UserResponse | undefined> {
        let userResponse = await this.retrieveUser(username, token)
        if (userResponse?.guid !== null) {
            return userResponse
        }
    }


    clear() {
        this.setLoggedIn(false)
        this.setAccessToken('')
        this.setUser(undefined)
        this.setErrorMessage(undefined)
    }
}