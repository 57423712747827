import React from 'react';


import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { QuickActions } from '../../../../types/ChatTypes';
import { useSummarizationStore } from '../../hook/useSummarizationStore';
import { Box, CircularProgress, Typography, Paper } from '@mui/material';



/**
 * Description placeholder
 * @date 25/09/2023 - 08:07:06
 *
 * @interface SummarizationViewProps
 * @typedef {SummarizationViewProps}
 */
interface SummarizationViewProps {
    quickActions: QuickActions[]
    onFeedbackThumbUp?(messageId: string): void
    onFeedbackThumbDown?(messageId: string): void
}

/**
 * Description placeholder
 * @date 25/09/2023 - 08:06:53
 *
 * @type {React.FC<SummarizationViewProps>}
 */
const SummarizationView: React.FC<SummarizationViewProps> = observer(({
    quickActions,
    onFeedbackThumbUp,
    onFeedbackThumbDown
}) => {

    const { t, i18n } = useTranslation();
    const chatStore = useSummarizationStore()

    const [contentWarningOpen, setContentWarningOpen] = React.useState(false);
    const [contentWarningCategories, setContentWarningCategories] = React.useState<string[] | undefined>();

    const handleClose = () => {
        setContentWarningOpen(false);
    };

    const onCopyToClipboard = (message: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(message)
        }
    }

    return (
        <>

            <Box sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#primary.main'
            }}>
                <Paper elevation={3} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2rem',
                    borderRadius: '15px',
                    backgroundColor: '#f3f4f6'
                }}>
                    <Typography variant={'h2'} gutterBottom color="primary">
                        {t('feature.summarization.content.title')}
                    </Typography>
                    {/* <CircularProgress size={60} sx={{ marginBottom: '1rem' }} color="primary" /> */}
                    <Typography variant={'h5'} color="textSecondary" gutterBottom>
                        {t('feature.summarization.content.subtitle')}
                    </Typography>
                    <Typography variant={'body1'} color="textSecondary" textAlign="center">
                        {t('feature.summarization.content.subtitle2')}
                    </Typography>
                </Paper>
            </Box>
        </>
    );
})

export { SummarizationView, SummarizationViewProps };