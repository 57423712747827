import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    styled,
    Chip,
    Box,
} from '@mui/material';
import { observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";


const BulletTypography = styled(Typography)(({ theme }) => ({
    '&:after': {
        content: '"•"',
        position: 'absolute',
        right: '-20px', // Adjust the position accordingly
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

interface AttentionBannerProps {
    open: boolean;
    headerText: string;
    contentText: string;
    categoryTitle: string;
    categories?: string[];
    yesButton: string;
    noButton: string;
    severity?: 'error' | 'warning' | 'info' | 'success';
    handleClose?: () => void;
    handleSubmit?: () => void;
    showSubmitAnyway?: boolean;
}

const backgroundColors = {
    error: '#ff4444',
    warning: '#ff9800',
    info: '#2196f3',
    success: '#4caf50',
};

const AttentionBanner: React.FC<AttentionBannerProps> = observer(({
    open,
    headerText,
    contentText,
    categoryTitle,
    categories,
    yesButton,
    noButton,
    severity = 'info',
    handleClose,
    handleSubmit,
    showSubmitAnyway
}) => {

    const handleCancel = () => {
        handleClose?.();
    };

    const handleYes = () => {
        handleSubmit?.();
    };

    // Set dialog background color based on severity
    const dialogBackground = '#faedee'; // '40' for transparency

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { backgroundColor: dialogBackground },
            }}
        >
            <DialogTitle id="alert-dialog-title" >{headerText}</DialogTitle>
            <DialogContent>
                {/* <ReactMarkdown>
                    {contentText}
                </ReactMarkdown>
                */}

                <ReactMarkdown children={contentText} rehypePlugins={[rehypeRaw]} />

                <Typography variant={'body1'}>{categoryTitle}</Typography>
                <Box sx={{ m: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {categories?.map((category, index) => (
                        <Chip key={`${category}-${index}`} label={category} color="error" variant="outlined" />
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='outlined' onClick={handleCancel} color={'error'}>
                    {noButton !== undefined ? noButton : "No"}
                </Button>
                {showSubmitAnyway &&
                    <Button variant='contained' onClick={handleYes} autoFocus color={'error'}>
                        {yesButton !== undefined ? yesButton : "Yes"}
                    </Button>
                }
            </DialogActions>
        </Dialog >
    );
});

export default AttentionBanner;
