import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { UserMenuOptions } from "../../types/UITypes"
import React from "react";

interface UserMenuProps {
    userMenuOptions?: UserMenuOptions[]
}

const UserMenu: React.FC<UserMenuProps> = ({ userMenuOptions }) => {

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (<Box sx={{ flexGrow: 0 }}>

        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="" />
        </IconButton>

        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            {userMenuOptions !== undefined && userMenuOptions.map((setting: UserMenuOptions) => (
                <MenuItem key={setting.title} onClick={() => {
                    handleCloseUserMenu()
                    setting.action()
                }}>
                    <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
            ))}
        </Menu>
    </Box>
    )


}

export default UserMenu