
import { action, makeObservable, observable } from 'mobx';
import { HandleMesssageResult, IHandleSendMessage } from '../../../stores/BaseStreamingChatStore';
import { RootStore } from '../../../stores/RootStore';

import { documentService } from '../../../services/DocumentService';
import { Constants } from '../../../contants/Constants';

import { ChatMessageProps, QuickActions } from '../../../types/ChatTypes';


import i18n from '../../../i18n';
import { DocumentProcessingStatusTracker, ProcessedStatus } from '../utils/DocumentProcessingState';
import {  IFeaturePluginStore } from '../../../plugin/types';
import { ParamaterAttachment, StreamingChatStore } from '../../../stores/StreamingChatStore';

import axios from 'axios';
import errorHandler from '../../../services/ErrorHandler';
import { mode } from 'crypto-js';
import { Attachment } from '@mui/icons-material';
import { ConversationAttachmentDocument } from '../../../types/types_api';


export class DocumentChatStore implements IFeaturePluginStore {

    _rootStore: RootStore | undefined = undefined;

    _uploadInProgress: boolean = false
    _uploadFinished: boolean = false

    _documentsPresent: boolean = false
    _tmpInitialMessage: string = ''

    allowedExtensions = ['pdf'];

    _openClearDialog: boolean = false

    _openDeleteDocumentDialog: boolean = false

    _inplaceMenuVisible: boolean = true

    _openDialogDocumentFromUrl: boolean = false

    _documentProcessingVisible: boolean = false
    _processintStatusTracker: DocumentProcessingStatusTracker | undefined = undefined
    _documentProcessingPercentage: number = 0

    _documentPreProcessingVisible: boolean = false
    _openContentWarningDialog: boolean = false
    _streamingChatStore: StreamingChatStore

    _documentTitle: string = ''

    _currentDocumentIds: Array<string> = []
    _conversationId: string | undefined = undefined

    
    _quickActions: QuickActions[] = []

    constructor(rootStore: RootStore) {

        this._rootStore = rootStore;
        this._uploadInProgress = false
        this._uploadFinished = false

        this._documentsPresent = false

        makeObservable(this, {
            _uploadInProgress: observable,
            _uploadFinished: observable,
            setUploadInProgress: action,
            setUploadFinished: action,

            // actions
            _openClearDialog: observable,
            setOpenClearDialog: action,

            // actions
            _openDeleteDocumentDialog: observable,
            setOpenDialogDeleteDocument: action,

            // inplaceme menu 
            _inplaceMenuVisible: observable,
            setInplaceMenuVisible: action,

            // inplaceme menu 
            _documentProcessingVisible: observable,
            setDocumentProcessingVisible: action,

            _documentProcessingPercentage: observable,
            setDocumentProcessingPercentage: action,

            // upload
            _openDialogDocumentFromUrl: observable,
            setOpenDialogDocumentFromUrl: action,

            _documentPreProcessingVisible: observable,
            setDocumentPreProcessingVisible: action,

            _openContentWarningDialog: observable,
            setOpenContentWarningDialog: action,

            // _quickActions: observable,
            // updateActions: action,
        });


        this._streamingChatStore = new StreamingChatStore(rootStore, Constants.Services.DocChat)
        this._streamingChatStore.setNewChatAllowed(false)

        if (this._streamingChatStore.conversationStore !== undefined) {
            this._streamingChatStore.conversationStore.conversationSelectedCallback = this.onConversationSelected.bind(this)
            this._streamingChatStore.conversationStore.conversationDeletedCallback = this.onConversationDeleted.bind(this)
        }

        this.setInplaceMenuVisible(false)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    
    setQuickActions(quickActions: QuickActions[]) {
        this._quickActions = quickActions
    }

    updateActions(quickActions: QuickActions[]) {
        this._quickActions = quickActions
    }

    get quickActions(): QuickActions[] {
        return this._quickActions
    }

    get documentsPresent(): boolean {
        return this._documentsPresent
    }

    setDocumentsPresent(documentsPresent: boolean): void {
        this._documentsPresent = documentsPresent
    }

    get documentTitle(): string {
        return this._documentTitle
    }

    setDocumentTitle(documentTitle: string) {
        this._documentTitle = documentTitle
    }

    promptSelectionCallback(initialMessage: string) {
        if (this.documentsPresent === true) {
            this._tmpInitialMessage = initialMessage
            this._streamingChatStore.setInitialMessage(initialMessage)
        } else {
            // let selection = 'chat-with-document-from-url'
            // this._streamingChatStore.setInitialMessage(initialMessage)
            // this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            // this.handleInplaceMenuSelection(selection)
            this._rootStore?.notify(i18n.t('Bitte erst ein Dokument hochladen'), { variant: 'warning' })
        }
    }

    setOpenClearDialog(openClearDialog: boolean) {
        this._openClearDialog = openClearDialog
    }

    get openClearDialog(): boolean {
        return this._openClearDialog
    }

    setOpenContentWarningDialog(openContentWarningDialog: boolean) {
        this._openContentWarningDialog = openContentWarningDialog
    }

    get openContentWarningDialog() {
        return this._openContentWarningDialog
    }

    quickActionClearChatHistory() {
        this.setOpenClearDialog(true);
    };

    quickActionUploadDocument() {
        let selection = 'chat-with-document-from-url'
        // TODO: check if document is already present

        this._streamingChatStore.setInitialMessage('')
        this.handleInplaceMenuSelection(selection)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    setOpenDialogDeleteDocument(openDialogDeleteDocument: boolean) {
        // console.log('setOpenDialogDeleteDocument', openDialogDeleteDocument)
        this._openDeleteDocumentDialog = openDialogDeleteDocument
    }

    get openDialogDeleteDocument() {
        return this._openDeleteDocumentDialog
    }

    get documentProcessingVisible(): boolean {
        return this._documentProcessingVisible
    }

    setDocumentProcessingVisible(documentProcessingVisible: boolean) {
        this._documentProcessingVisible = documentProcessingVisible
    }

    get documentProcessingPercentage(): number {
        return this._documentProcessingPercentage
    }

    setDocumentPreProcessingVisible(documentPreProcessingVisible: boolean) {
        this._documentPreProcessingVisible = documentPreProcessingVisible
    }

    get documentPreProcessingVisible(): boolean {
        return this._documentPreProcessingVisible
    }

    setDocumentProcessingPercentage(documentProcessingPercentage: number) {
        this._documentProcessingPercentage = documentProcessingPercentage
    }

    quickActionDeleteDocument() {
        this.setOpenDialogDeleteDocument(true);
    }

    handleClearChatYes() {
        this.setOpenClearDialog(false);
        this.onNewChat()
        this._streamingChatStore.setInputFieldDisabled(false)
        this.setInplaceMenuVisible(false)
        this._streamingChatStore.setNewChatAllowed(false)
    };

    handleClearChatNo() {
        this.setOpenClearDialog(false);
    };

    async handleDeleteDocumentYes() {

        this.setOpenDialogDeleteDocument(false);
        this.deleteCurrentDocument()
        //this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        this._streamingChatStore.setNewChatAllowed(false)

        this.onNewChat()
        //this.setInplaceMenuVisible(true)
        this.setDocumentsPresent(false)

        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)

        this._streamingChatStore.setInputFieldDisabled(true)
        this._streamingChatStore.setNewChatAllowed(false)
        this.setDocumentTitle('')
    }

    handleDeleteDocumentNo() {
        this.setOpenDialogDeleteDocument(false);
    }

    // inplace menu
    get inplaceMenuVisible() {
        return this._inplaceMenuVisible
    }

    setInplaceMenuVisible(inplaceMenuVisible: boolean) {
        this._inplaceMenuVisible = inplaceMenuVisible
    }

    // document from url
    get openDialogDocumentFromUrl() {
        return this._openDialogDocumentFromUrl
    }

    setOpenDialogDocumentFromUrl(openDialogDocumentFromUrl: boolean) {
        this._openDialogDocumentFromUrl = openDialogDocumentFromUrl
    }

    async openDialogDocumentFromUrlSubmit(url: string) {
        this.setOpenDialogDocumentFromUrl(false)
        try {
            let isAccessible = await Promise.resolve(true)
            //let isAccessible = await isDocumentAccessible(url)
            if (isAccessible === false) {
                this._rootStore?.notify(i18n.t('feature.docChat.errorMessages.document.urlNotAccessible'), { variant: 'error' })
                return
            } else {
                this.handleUploadFromLink(url)
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler.handle(error)

            } else {
                console.log(error)
            }
        }
    }

    openDialogDocumentFromUrlCancel() {
        this.setOpenDialogDocumentFromUrl(false)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    handleInplaceMenuSelection(selection: string) {
        if (selection === 'chat-with-document-from-url') {
            if (this._streamingChatStore?.conversationStore?._selectedConversation?.attachments !== undefined &&
                this._streamingChatStore?.conversationStore?._selectedConversation?.attachments !== null &&
                this._streamingChatStore?.conversationStore?._selectedConversation?.attachments.length > 0) {
                console.log('document already present')
            } else {
                this.setOpenDialogDocumentFromUrl(true)
            }
        }
    }

    //upload
    setUploadInProgress(uploadInProgress: boolean) {
        this._uploadInProgress = uploadInProgress
    }

    get uploadInProgress() {
        return this._uploadInProgress
    }

    setUploadFinished(uploadFinished: boolean) {
        this._uploadFinished = uploadFinished
    }

    get uploadFinished() {
        return this._uploadFinished
    }

    handleStreamingError() {
        if (this._streamingChatStore.getHistory.length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        } else {
            this._streamingChatStore?.setNewChatAllowed(false)
        }
    }

    async checkContent(newMessage: string): Promise<HandleMesssageResult> {
        return this._streamingChatStore?.checkContent(newMessage)
    }

    async sendMessage(newMessage: string,  updateAIShieldEvent?: boolean): Promise<boolean> {
        let title = `${this.documentTitle} + ${newMessage}`
        let maxTitleLength = Constants.ChatMessages.MaxTitleLength
        let converstationTitle = title.slice(0, Math.min(title.length, maxTitleLength))

        let data = {
            document_ids: this._currentDocumentIds 
        }
        console.log('sendMessage', this._currentDocumentIds)

        let result = await this._streamingChatStore?.sendMessage(newMessage, converstationTitle, updateAIShieldEvent, data as ParamaterAttachment, this._conversationId)
        
    
        return Promise.resolve(result)
    }

    async initialize() {
        console.log('Initializing DocumentChat Store')
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        let userId = this._rootStore?.userStore?.currentUser?.guid
        let token = await this._rootStore?.userStore?.getAccessToken();
        
        if (userId !== undefined && token !== undefined) {
            let serviceName = Constants.Services.DocChat
            if (this._streamingChatStore._conversationStore !== undefined) {
                await this._streamingChatStore._conversationStore.retrieveConversationsForUserWithAttachments(userId, serviceName, token)
            }

            if (this._streamingChatStore._promptSuggestionsStore !== undefined) {
                let currentLanguage = i18n.language

                // this._streamingChatStore._promptSuggestionsStore.setShowPromptSuggestions(true)
                // await this._streamingChatStore._promptSuggestionsStore.retrievePromptSuggestions(serviceName, currentLanguage)
                // this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
                if (this._streamingChatStore?.messagesUI !== undefined && 
                    this._streamingChatStore?.messagesUI.length > 0) {
                    this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)    
                } else {
                    await this._streamingChatStore?._promptSuggestionsStore.retrievePromptSuggestions(serviceName, currentLanguage)
                    this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
                }
            }
            if (this._streamingChatStore?._modelSelectionStore !== undefined) {
                let skipModels = ['gpt-4']
                if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                    this._streamingChatStore?._promptSuggestionsStore?.setShowPromptSuggestions(true)
                    await this._streamingChatStore?._modelSelectionStore.retrieveAvailableModels(skipModels)
                }
            }
        }
        localStorage.setItem('lastFeature', Constants.Routes.DocumentChat)
        this._rootStore?.fireUpdateUI()
    }

    async onFeedbackThumbUp(messageId: string) {

        let token = await this._rootStore?.userStore?.getAccessToken()
        if (this._streamingChatStore._conversationStore) {
            this._streamingChatStore._conversationStore.setFeedback(messageId, true, token)
        }
    }

    async onFeedbackThumbDown(messageId: string) {
        //chatStore.onFeedbackThumbUp(messageId)
        let token = await this._rootStore?.userStore?.getAccessToken()
        if (this._streamingChatStore._conversationStore) {
            this._streamingChatStore._conversationStore.setFeedback(messageId, false, token)
        }
    }

    async handleUploadPDF(file: File) {
        // Simulate the file upload
        console.log(`Received file: ${file.name}`);
        this.setUploadInProgress(true);
        if (this._conversationId === undefined) {
            this._conversationId = this._streamingChatStore._conversationStore?.createConversationId()
        }
        // reinitialize the document id list
        this._currentDocumentIds = []
        try {
            if (this._rootStore?.userStore !== undefined) {
                let token = await this._rootStore?.userStore.getAccessToken()
                if (token !== undefined) {
                    // let response = await webletService.addDataFromPDF({pdf: file, split: true}, token);
                    let response = await documentService.addDataFromPDF(file, token, this._conversationId);
                    // add the document id to the list of current documents
                    
                    this.setUploadInProgress(false);
                    if (response.id !== undefined) { 
                        this._currentDocumentIds.push(response.id)
                        
                        this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentUpload.success'), { variant: 'success' })
                        this.setInplaceMenuVisible(false)
                        this.setUploadFinished(true)
                        this._streamingChatStore.resetPromptSuggestions()
                        this._streamingChatStore.setNewChatAllowed(false)
                    } else {
                        this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                            documentLink: file.name
                        }), { variant: 'error' })
                    }
                } else {
                    // can this happen
                    this._rootStore?.notify('Error sending message. Login Again.', { variant: 'error' })
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler.handle(error)
            } else {
                console.log(error)
            }
        }
        this.setUploadInProgress(false);
    };

    async handleUploadFromLink(documentLink: string) {
        // Simulate the file upload
        console.log(`Received file: ${documentLink}`);
        this.setUploadInProgress(true);

        // reinitialize the document id list
        this._currentDocumentIds = []
        if (this._conversationId === undefined) {
            this._conversationId = this._streamingChatStore._conversationStore?.createConversationId()
        }

        if (this._rootStore?.userStore !== undefined) {
            try {
                let token = await this._rootStore.userStore.getAccessToken()
                let userId = this._rootStore.userStore.currentUser?.guid

                if (token !== undefined && userId !== undefined) {

                    // let response = await webletService.addDataFromPDF({pdf: file, split: true}, token);
                    console.log('converstationId: upload', this._conversationId)
                    let response = await documentService.addDataFromLink(userId, documentLink, this._conversationId, token);
                    console.log('document_id', response)
                    if (response.id !== undefined) {

                        this._currentDocumentIds.push(response.id)
                        console.log('_currentDocumentIds', response)

                        this._processintStatusTracker = new DocumentProcessingStatusTracker()
                        this._processintStatusTracker.connect(response.id, token, this.onDocumentProcessingUpdate.bind(this))
                        
                        this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentUpload.success'), { variant: 'success' })
                        // init progressbar backgroup

                        this.setDocumentPreProcessingVisible(true)
                        this.setDocumentProcessingPercentage(0)

                        this.setInplaceMenuVisible(false)
                        this.setUploadFinished(true)
                        this._streamingChatStore.setNewChatAllowed(false)
                        // this.resetPromptSuggestions()
                    }
                    else {
                        // this._rootStore.notify(`Error uploading: ${documentLink}`, { variant: 'error' })
                        this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                            documentLink: documentLink
                        }), { variant: 'error' })
                    }
                } else {
                    this._rootStore.notify('Error sending message. Login Again.', { variant: 'error' })
                    // TODO: could this case happen?
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorHandler.handle(error)
                    let maxTitleLength = Constants.ChatMessages.MaxTitleLength
                    let documentLinkShort = documentLink.slice(0, Math.min(documentLink.length, maxTitleLength))
                    this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                        documentLink: documentLinkShort
                    }), { variant: 'error' })
                } else {
                    console.log(error)
                }
            }
        }
        this.setUploadInProgress(false);
    };

    async onDocumentProcessingUpdate(id: string, message: string) {
        console.log('IncommingMessage: onMessage')
        console.log(message)

        const receivedData: string = message;
        let object = JSON.parse(receivedData) as ProcessedStatus

        if (object.status === 'processing') {
            if (object.processed > 0) {
                this.setDocumentPreProcessingVisible(false)
                this.setDocumentProcessingVisible(true)
            }
            let documentProcessingPercentage = Math.floor(object.processed / object.num_chunks * 100)
            console.log('documentProcessingPercentage', documentProcessingPercentage)
            this.setDocumentProcessingPercentage(Math.floor(object.processed / object.num_chunks * 100))
        }

        if (object.status === 'completed') {

            this._streamingChatStore.resetModelSelection()
            this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentProcessingCompleted'), { variant: 'success' })
            let token = await this._rootStore?.userStore?.getAccessToken();
            let userId = this._rootStore?.userStore?.currentUser?.guid
            if (userId !== undefined) {
                if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                    let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                    if (selectedModel === undefined) {
                        selectedModel = "gpt-35"
                    }
                    
                    let serviceName = Constants.Services.DocChat
                    if (selectedModel !== undefined && token !== undefined) {
                        let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Doc Chat', serviceName, selectedModel, token)
                    }
                }
            }

            let conversationId = this._streamingChatStore._conversationStore?._selectedConversation?.id
            
            if (userId !== undefined && conversationId !== undefined) {
                let token = await this._rootStore?.userStore?.getAccessToken()
                
                
                if (token !== undefined && this._conversationId !== undefined) {
                    let documentId = this._currentDocumentIds[this._currentDocumentIds.length - 1]
                    await documentService.linkDocumentToConversation(conversationId, documentId, token )
                }

                if (token !== undefined) {
                    let conversation = await this._streamingChatStore._conversationStore?.retrieveConversation(userId, conversationId, Constants.Services.DocChat, token )
                    console.log('document conversation', conversation)
                    if (conversation !== undefined) {
                        this._streamingChatStore._conversationStore?.updateConversation(conversation)
                    }
                }
            }

            // update quick actions
            // disable uploadDocuments
            let quickActions : QuickActions[] = []
            this._quickActions.forEach((quickAction) => {
                if (quickAction.key === 'uploadDocument') {
                    quickAction.disabled = true
                    quickActions.push(quickAction)
                } else {
                    
                    quickActions.push(quickAction)
                }
            })
            this.updateActions(quickActions)

            console.log('document processing',  this._conversationId)
            
            this.setDocumentProcessingVisible(false)
            this.setDocumentPreProcessingVisible(false)
            this.setInplaceMenuVisible(false)

            this.setUploadFinished(true)

            this.setDocumentProcessingPercentage(0)

            // enable input field
            this._streamingChatStore.setInputFieldDisabled(false)
            // because we dont have a conversation yet
            this._streamingChatStore.setNewChatAllowed(false)
            this.setDocumentsPresent(true)

            // link the document to the conversation
        }
    }

    async deleteCurrentDocument() {
        try {
            let token = await this._rootStore?.userStore?.getAccessToken()
            let userId = this._rootStore?.userStore?.currentUser?.guid;
            // console.log('Delete Document', userId, token)
            if (token !== undefined && userId !== undefined) {
                let result = documentService.deleteDocument(userId, token)
                if (result !== undefined) {
                    this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentDelete.success'), { variant: 'success' })
                }
            }
        } catch (error) {
            console.log(error)
            this._rootStore?.notify(i18n.t('errorMessages.document.delete'), { variant: 'error' })
        }
    }

    onConversationSelected(conversationId: string, chatMessagePropsArray: ChatMessageProps[]): void {

        this._streamingChatStore.setMessagesUI(chatMessagePropsArray)
        // update the model selection
        let conversationModel = this._streamingChatStore?._conversationStore?._selectedConversation?.model
        console.log('selected conversation', this._streamingChatStore?.conversationStore?._selectedConversation)
        let attachements = this._streamingChatStore?.conversationStore?._selectedConversation?.attachments
        if (attachements !== undefined && attachements !== null &&attachements.length > 0) {
            this._currentDocumentIds = []
            for (let i = 0; i < attachements.length; i++) {
                if (attachements[i].attachment_type === 'document' ) {
                    this._currentDocumentIds.push((attachements[i] as ConversationAttachmentDocument).document_id)
                }
            }
            console.log('currentDocumentIds', this._currentDocumentIds)
            this.setDocumentsPresent(true)

            let quickActions : QuickActions[] = []
            this._quickActions.forEach((quickAction) => {
                if (quickAction.key === 'uploadDocument') {
                    quickAction.disabled = true
                    quickActions.push(quickAction)
                } else {
                    
                    quickActions.push(quickAction)
                }
            })

            this.updateActions(quickActions)
            this._streamingChatStore.setInputFieldDisabled(false)
        }
        if (conversationModel !== undefined) {
            this._streamingChatStore._modelSelectionStore?.initializeFromModelId(conversationModel)
        }
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        if (this._streamingChatStore.getHistory().length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        }
    }

    onConversationDeleted(conversationId: string): void {

        if (conversationId === this._streamingChatStore?._conversationStore?._selectedConversation?.id) {
            this.onNewChat()
            this._streamingChatStore.clearChat()
            this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)
            this._streamingChatStore.setNewChatAllowed(false)
        }
    }

    async onNewChat() {
        this._streamingChatStore.clearChat()

        // reset quick actions
        let quickActions : QuickActions[] = []
        this._quickActions.forEach((quickAction) => {
            quickAction.disabled = false
            quickActions.push(quickAction)
            
        })
        this.updateActions(quickActions)

        this._streamingChatStore.resetPromptSuggestions()
        // this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
        this._streamingChatStore.resetModelSelection()
        this._streamingChatStore.resetSelectedConverstation()
        this._conversationId = undefined
        this._currentDocumentIds = []

        let token = await this._rootStore?.userStore?.getAccessToken();
        let userId = this._rootStore?.userStore?.currentUser?.guid
        if (userId !== undefined) {
            if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                if (selectedModel === undefined) {
                    selectedModel = "gpt-35"
                }
                
                let serviceName = Constants.Services.DocChat
                if (selectedModel !== undefined && token !== undefined) {
                    let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Chat', serviceName, selectedModel, token)
                }
            } else {
                if (this._streamingChatStore?.conversationStore?.currentMessages !== undefined
                    && this._streamingChatStore?.conversationStore?.currentMessages.length > 0) {
                        
                    let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                    if (selectedModel === undefined) {
                        selectedModel = "gpt-35"
                    }
                    
                    let serviceName = Constants.Services.DocChat
                    if (selectedModel !== undefined && token !== undefined) {
                        let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Chat', serviceName, selectedModel, token)
                    }
                }
            }
        }

        
    }

    clear() {
        console.log('DocumentChatStore.clear')
        this.deleteCurrentDocument()

        this._streamingChatStore.clearChat()
        this.setInplaceMenuVisible(true)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)

        this.setUploadInProgress(false)
        this.setUploadFinished(false)
        this._streamingChatStore.setInputFieldDisabled(true)

        this._streamingChatStore._conversationStore?.clear()
        this._streamingChatStore._modelSelectionStore?.clear()
        this._streamingChatStore._promptSuggestionsStore?.clear()
    }
}
