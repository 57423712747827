import { Constants } from "../../../contants/Constants";
import { SidebarSubMenuItem } from "../../../layouts/Dashboard/types";
import { IFeaturePlugin, IFeaturePluginStore, InitFunction, PluginInfo } from "../../../plugin/types";
import { RootStore } from "../../../stores/RootStore";
import { QuickActions } from "../../../types/ChatTypes";
import { AskQuestionStreamingMessage } from "../../../types/WebletServiceTypes";
import { chatFeatureNode, chatHistorySidebarElement } from "../components/ChatFeatureNode";

import { chatMenuItemFactory, } from "../components/ChatSidebarMenu";
import { ChatStore } from "../stores/ChatStore";

import i18n from '../../../i18n';
import { SideBarView } from "../../../types/UITypes";

export class ChatFeaturePlugin implements IFeaturePlugin {

    _rootStore: RootStore | undefined = undefined;
    _initialized: boolean;
    _pluginStore: ChatStore | undefined = undefined
    _sidebarViews: SideBarView[] = []


    constructor(rootStore: RootStore) {
        this._initialized = false;
        this._rootStore = undefined;
        this._rootStore = rootStore
        //this.initialize(rootStore)
    }

    get featureStore(): IFeaturePluginStore | undefined {
        return this._pluginStore as IFeaturePluginStore
    }

    initialize(store: RootStore): void {
        if (this._initialized === false) {
            this._initialized = true;
            this._rootStore = store;
            this._pluginStore = new ChatStore(store)
            this._rootStore.addFeaturePlugin(this)
        }
    }

    activated(): void {
        if (this._rootStore !== undefined) {
            console.log("ChatFeaturePlugin.initialize: setting active plugin")
            this._rootStore.setActivePlugin(this as IFeaturePlugin)
        }
        if (this._pluginStore !== undefined) {
            this._pluginStore.initialize()
        }
        //this._rootStore?.fireUpdateUI()
    }

    get initialized(): boolean {
        return this._initialized;
    }

    get name(): string {
        return "chat";
    }

    enabled(): boolean {

        return true;
    }

    menuItems(initFunction: InitFunction): SidebarSubMenuItem {
        let menuItem: SidebarSubMenuItem = chatMenuItemFactory(initFunction);
        return menuItem as SidebarSubMenuItem;
    }

    route(): string {
        return Constants.Routes.Chat
    }

    node(): React.ReactNode {
        if (this._pluginStore !== undefined) {
            let quickAction = this.quickActions()
            let onFeedbackThumbUp = undefined
            let onFeedbackThumbDown = undefined

            if (this._pluginStore._streamingChatStore?._conversationStore !== undefined) {
                onFeedbackThumbDown = this._pluginStore._streamingChatStore?._conversationStore.onFeedbackThumbDown.bind(
                    this._pluginStore._streamingChatStore?._conversationStore)
                onFeedbackThumbUp = this._pluginStore._streamingChatStore?._conversationStore.onFeedbackThumbUp.bind(
                    this._pluginStore._streamingChatStore?._conversationStore)

                let node = chatFeatureNode(this._pluginStore,
                    quickAction,
                    onFeedbackThumbUp,
                    onFeedbackThumbDown
                )
                return node
            }

            return null
        }
    }

    onMessage(data: string): void {
        const message = JSON.parse(data) as AskQuestionStreamingMessage

        if (message.service === Constants.Services.Chat) {
            if (message.status === 'completed') {
                this._pluginStore?._streamingChatStore?.onMessage(message)
                this._pluginStore?._streamingChatStore?.onCompleted()
                this._pluginStore?._streamingChatStore?.setNewChatAllowed(true)

            } else if (message.status === 'error') {

                this._pluginStore?._streamingChatStore?.onError(message)
                // show error message

                this._pluginStore?.handleStreamingError()
                this._rootStore?.notify(i18n.t('errorMessages.chat.error'), { variant: 'error' })
            } else {
                this._pluginStore?._streamingChatStore?.onMessage(message)
            }
        }
    }

    quickActions(): QuickActions[] {
        let quickActions: QuickActions[] = []
        if (this._pluginStore !== undefined) {
            quickActions.push({
                key: 'clearChatHistory',
                title: i18n.t('feature.chat.buttonClearChatHistory'),
                action: () => {
                    this._pluginStore?.quickActionClearChatHistory()
                },
                disabled: false
            } as QuickActions)
        }
        return quickActions
    }

    get sidebarViews(): SideBarView[] {

        this._sidebarViews = []
        if (this._pluginStore !== undefined) {

            if (this._pluginStore._streamingChatStore?._conversationStore !== undefined) {
                let chatHistory = chatHistorySidebarElement(
                    i18n.t('feature.chat.sidebar.chatHistory'),
                    this._pluginStore,
                    this._pluginStore._streamingChatStore?._conversationStore?.onConversationSelected.bind(this._pluginStore._streamingChatStore?._conversationStore),
                    this._pluginStore._streamingChatStore?._conversationStore.onConversationDeleted.bind(this._pluginStore._streamingChatStore?._conversationStore),
                    this._pluginStore._streamingChatStore?._conversationStore.updateConversationTitle.bind(this._pluginStore._streamingChatStore?._conversationStore))
                this._sidebarViews.push(chatHistory)
            }

            // let chatSettings = chatSettingsSidebarElement(
            //     i18n.t('feature.chat.sidebar.chatSettings'),
            //     this._pluginStore)
            // this._sidebarViews.push(chatSettings)
        }
        return this._sidebarViews
    }

    onNewButton() {
        console.log("onNewButton")
        this._pluginStore?.onNewChat()
    }

    pluginInfo(): PluginInfo {
        return {
            title: i18n.t('feature.chat.description.title'),
            description: i18n.t('feature.chat.description.text'),
            version: '1.0.0'
        } as PluginInfo
    }

    get newInteractionAllowed(): boolean {
        if (this._pluginStore === undefined) {
            return false
        }
        return this._pluginStore?._streamingChatStore?.newChatAllowed
    }
}
