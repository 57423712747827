import { useContext } from "react";
import ChatStoreContext from "../context/ChatStoreProvider";

export const useChatStore = () => {
    const context = useContext(ChatStoreContext);

    if (!context.chatStore) {
        // Optionally, you can throw an error if the userStore is not available.
        // This would mean that you're trying to use the hook outside of the UserStoreProvider.
        throw new Error("useUserStore must be used within a UserStoreProvider");
    }

    return context.chatStore;
}