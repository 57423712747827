import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'; // Example icon
import GavelIcon from '@mui/icons-material/Gavel'; // Example icon
import PolicyIcon from '@mui/icons-material/Policy'; // Example icon
import DescriptionIcon from '@mui/icons-material/Description'; // Example icon
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton, Typography, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { env } from '../../env';



export interface DocumentItem {
    name: string;
    link: string;
    Icon: React.ElementType; // Type for MUI icons
    LinkIcon: React.ElementType
}


const DocumentListDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {


    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const documents: DocumentItem[] = [
        { name: t("downloadDocumentsDialog.documents.terms_of_use"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231208_Nutzungsbedingungen_blueGPT_FinalV2.pdf", Icon: DescriptionIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.richtlinie"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231208_Vorlaeufige_Richtlinie_blueGPT_Final.pdf", Icon: PolicyIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.code_of_conduct"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/Code_of_Conduct_for_Azure_OpenAI_Service-Content_requirements.pdf", Icon: GavelIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.data_protection"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231205_Datenschutzhinweise_blueGPT.pdf", Icon: PrivacyTipIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.community_guidelines"), link: "https://bluenet.lbbw.sko.de/de/ahfmgsb4tt", Icon: LaunchIcon, LinkIcon: LaunchIcon }
    ];
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ elevation: 6 }}>
            <DialogTitle sx={{ backgroundColor: theme.palette.background.paper }}>
                <Typography variant="h6" color="primary">
                    {t('downloadDocumentsDialog.title', { appName: env.REACT_APP_NAME_DISPLAY })}
                </Typography>

            </DialogTitle>
            <DialogContent sx={{ backgroundColor: theme.palette.background.paper }}>
                <Typography gutterBottom>
                    {t('downloadDocumentsDialog.text', { appName: env.REACT_APP_NAME_DISPLAY })}
                </Typography>
                <List>
                    {documents.map((doc, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="download" component="a" href={doc.link} target="_blank" rel="noopener noreferrer">
                                    {/* <DownloadIcon htmlColor={theme.palette.primary.main} /> */}
                                    <doc.LinkIcon htmlColor={theme.palette.primary.main} />
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                <doc.Icon htmlColor={theme.palette.primary.main} />
                            </ListItemIcon>
                            <ListItemText primary={doc.name} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
                <Button variant="outlined" color="primary" onClick={onClose}>
                    {t('downloadDocumentsDialog.buttonClose')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DocumentListDialog;

// export {
//     documents
// }
