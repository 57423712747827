

import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

const RouteAuthenticationGuardMsal = ({ userStore }) => {

    const { instance } = useMsal();

    const isAuthenticated = useIsAuthenticated();
    console.log('RouteAuthenticationGuardMsal:isAuthenticated', isAuthenticated)

    useEffect(() => {
        console.log('RouteAuthenticationGuardMsal')
        let checkUserLogIn = async () => {
            console.log('window.location.pathname', window.location.pathname)
            let redirectablePaths = ['/', '/chat', '/documentchat']
            let match = redirectablePaths.some((path) => {
                if (window.location.pathname === path) {
                    return true
                }
                return false
            })
            console.log('RouteAuthenticationGuardMsal:match', match)
            if (match) {
                let userLogin = await userStore.checkUserLogIn()
                if (userLogin) {
                    // console.log(rootStore)
                    // if (rootStore !== undefined) {

                    //     let featurePlugins = getFeaturePlugins(rootStore)
                    //     if (featurePlugins.length > 0) {

                    //         console.log('initializePlugins')
                    //         rootStore.initializePlugins(featurePlugins)
                    //         rootStore.setPlugins(featurePlugins)
                    //     }
                    // }

                    // if (rootStore?.plugins !== undefined) {
                    //     let tools: SidebarSubMenuItem[] = []
                    //     rootStore?.plugins.forEach((feature: IFeaturePlugin) => {

                    //         let item: SidebarSubMenuItem = feature.menuItems(feature.activated.bind(feature))
                    //         if (item !== undefined) {
                    //             tools.push(item)
                    //         }
                    //     })
                    //     setSelectableTools(tools)
                    // }
                    // navigate('/chat')
                } else {
                    console.log("RouteAuthenticationGuardMsal :User not logged in")
                    const account = instance.getActiveAccount();
                    if (!account) {
                        console.log('UserStoreMsal: getAccessToken: no account found');
                        try {
                            await instance.loginRedirect();
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        console.log('Account:', account);
                        const accessTokenRequest = {
                            scopes: ['openid', 'offline_access', 'profile'],
                            account: account
                        };
                        try {
                            await instance.ssoSilent(accessTokenRequest)
                        } catch (error) {
                            console.log('UserStoreMsal: getAccessToken: error', error);
                        }
                    }
                    // const account = instance.getActiveAccount();
                    // if (!account) {
                    //     const accessTokenRequest = {
                    //         scopes: ['openid', 'offline_access', "profile"],
                    //         account: account
                    //     };
                    //     try {
                    //         await this._instance.ssoSilent(accessTokenRequest)
                    //     } catch (error) {
                    //         console.log('UserStoreMsal: getAccessToken: error', error);
                    //         try {
                    //             await this._instance.loginRedirect(accessTokenRequest);
                    //         } catch (redirectError) {
                    //             console.log('Login redirect error:', redirectError);
                    //         }
                    //     }
                    // }
                }
            }
        }

        checkUserLogIn()
    }, [])


    return (<AuthenticatedTemplate>
        <Outlet />
    </AuthenticatedTemplate >)
}

export default RouteAuthenticationGuardMsal
