
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Drawer, IconButton, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';


import { Menu as MenuIcon } from '@mui/icons-material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Import necessary components and hooks
import { AppBar, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import RootStoreContext from '../../contexts/RootStoreProvider';

import { OptionsWithExtraProps, VariantType, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SplitButton from '../../components/SplitButton';
import { SideBarView, UserMenuOptions } from '../../types/UITypes';
import UserMenu from '../../components/UserMenu/UserMenu';
import { observer } from 'mobx-react-lite';
import SoftwareVersion from '../../components/SoftwareVersion/SoftwareVersion';
import ToolSelection from '../../components/ToolSelection';
import UserStoreContext from '../../contexts/UserStoreProvider';



import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { env } from '../../env';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

// Import your pages components
const drawerWidth = 320;

interface AppLoadingSkeletonProps {
    onlyAppLogo?: boolean;
    poweredBySection?: boolean;
}

const AppLoadingSkeleton: React.FC<AppLoadingSkeletonProps> = ({ onlyAppLogo, poweredBySection }) => {

    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const { rootStore } = useContext(RootStoreContext)
    const { userStore } = useContext(UserStoreContext)


    const notificationHandler = (message: string, variant: OptionsWithExtraProps<VariantType>) => {
        variant.persist = true;
        enqueueSnackbar(message, variant)
    }

    useEffect(() => {
        if (rootStore !== undefined) {
            rootStore.notificationHandler = notificationHandler
        }

        const initializeApp = async () => {

            if (rootStore !== undefined) {
                console.log('initializeApp:beforeinitAIShield')
                await rootStore.initAIShield()
                console.log('initializeApp:afterinitAIShield')
                return Promise.resolve()
            }
        }

        if (userStore !== undefined) {
            if (userStore.isLoggedIn === true) {
                console.log('appReady: false')

                initializeApp()

            }
        }

    }, [])


    const reoccurInterval = 10000;
    const [toolbarOpen, setToolbarOpen] = useState(true);


    useEffect(() => {
        if (!toolbarOpen) {
            const interval = setTimeout(() => {
                setToolbarOpen(true);
            }, reoccurInterval);

            return () => clearTimeout(interval); // Cleanup the timeout on component unmount or when toolbarOpen state changes
        }
    }, [toolbarOpen, reoccurInterval]);



    return (

        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
            <>
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ml: !isSmallScreen ? `${drawerWidth}px` : 0 }}>
                    {onlyAppLogo === false &&
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {isSmallScreen && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                {!isSmallScreen && (
                                    <Box>{' '}</Box>
                                )}

                                <LazyLoadImage
                                    height={'45px'}
                                    src={'./GFT_Logo_A2_RGB_web.png'}
                                    alt={`App Logo`}
                                    effect="blur"
                                    style={{
                                        filter:
                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Box sx={{ p: 0.2, backgroundColor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <LazyLoadImage
                                        height={'45px'}
                                        src={'./GFT_Logo_A2_RGB_web.png'}
                                        alt={`App Logo`}
                                        effect="blur"
                                        style={{
                                            filter:
                                                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                </Box>


                            </Box>
                        </Toolbar>
                    }
                    {onlyAppLogo === true &&
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {isSmallScreen && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                {!isSmallScreen && (
                                    <Box>{' '}</Box>
                                )}

                                {env.REACT_APP_NAME === "LBBW" &&
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <LazyLoadImage
                                            height={'45px'}
                                            src={'./LBBW_weiss_neg_RGB.png'}
                                            alt={`App Logo`}
                                            effect="blur"
                                            style={{
                                                filter:
                                                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                        <LazyLoadImage
                                            height={'45px'}
                                            src={'./Schriftzug_bluegpt_Negativ_RGB_2.png'}
                                            alt={`App Logo`}
                                            effect="blur"
                                            style={{
                                                filter:
                                                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>
                                }
                                {env.REACT_APP_NAME !== "LBBW" &&
                                    < LazyLoadImage
                                        height={'45px'}
                                        src={'./GFT_Logo_A2_RGB_web.png'}
                                        alt={`App Logo`}
                                        effect="blur"
                                        style={{
                                            filter:
                                                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                }
                            </Box>
                            { env.REACT_APP_STAGE_NAME !== undefined && 

                                <Box sx={{ bgcolor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography variant='h4' sx={{ m: 1, fontWeight: 'bold', color: '#FF0000'  }}>  {env.REACT_APP_STAGE_NAME}</Typography>
                                </Box>  
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            </Box>


                        </Toolbar>
                    }
                </AppBar>
                <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
                    {/* Drawer Skeleton */}

                    {/* <Skeleton variant="rounded" width={drawerWidth} height="100vh" /> */}

                    {/* Main Content Skeleton */}
                    <Box sx={{ position: 'relative', m: 3, flexGrow: 1, height: '100%' }}>
                        {/* <Skeleton variant="rounded" width="100%" height="100%" /> */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0, // Ensures the box stretches to the full width of the parent
                                bottom: 0, // Ensures the box stretches to the full height of the parent
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 3, // padding to ensure text is within the skeleton
                            }}
                        >
                            {/* <Typography variant="h6" color="text.primary">
                                {t("loading.title")}
                            </Typography> */}
                            {rootStore?.appLoadingMessage !== undefined &&
                                <Box display="flex" flexDirection="column" justifyContent={"center"}>
                                    <Typography variant="h6" color="text.primary">
                                        {rootStore?.appLoadingMessage}
                                    </Typography>

                                    <LinearProgressWithLabel value={rootStore?.appLoadingProgress} />

                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </>

        </Box >
    );
};

export default observer(AppLoadingSkeleton);
