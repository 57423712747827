import React from 'react';
import {
    Box, Card, Typography, useTheme, CardContent, List, ListItem, ListItemText
} from '@mui/material';
import { Reference } from '../../types/ChatTypes';


interface AnswerAttachmentProps {
    title: string;
    references: Reference[];
}

const AnswerAttachment: React.FC<AnswerAttachmentProps> = ({ title, references }) => {

    const groupedByDocument = references.reduce((acc, reference) => {
        if (!acc[reference.document_id]) {
            acc[reference.document_id] = {
                document_name: reference.document_name,
                refs: [],
            };
        }
        acc[reference.document_id].refs.push(reference);
        return acc;
    }, {} as Record<string, { document_name: string, refs: Reference[] }>);

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', // Align text to the left
                width: '100%',
            }}
        >
            <Typography textAlign='left' fontSize={14} fontStyle={'italic'} fontWeight={'bold'} variant={'caption'} gutterBottom>{title}</Typography>
            {Object.values(groupedByDocument).map((group, idx) => (
                <Card key={idx} sx={{ width: '100%', m: 0, mb: 0.1, mt: 1, p: 0, minHeight: 'auto' }}>
                    <CardContent sx={{ m: 0, p: 0, display: 'flex', flexDirection: 'column', alignContent: 'left' }}>
                        <Box sx={{ m: 0, ml: 0.5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <Typography fontWeight={'bold'} align={'left'} variant="caption" >{group.document_name}</Typography>
                        </Box>
                        {/*<Divider sx={{ margin: theme.spacing(1, 0) }} /> */}
                        <List dense sx={{ p: 0, m: 0, ml: 1 }}>
                            {group.refs.map((ref, index) => {
                                return (<ListItem key={ref.id} dense sx={{ p: 0, m: 0 }}>
                                    {/* <Avatar sx={{ marginRight: theme.spacing(1), width: theme.spacing(4), height: theme.spacing(4) }}>{ref.id}</Avatar> */}
                                    <ListItemText
                                        sx={{ p: 0, m: 0 }}
                                        primaryTypographyProps={{ variant: "caption" }}
                                        primary={
                                            <>
                                                <Typography component="span" variant="caption" sx={{ fontStyle: "italic" }}>
                                                    {`${index + 1}`}
                                                </Typography>
                                                {" - "}
                                                <Typography component="span" variant="caption" sx={{ fontStyle: "italic" }}>
                                                    {ref.source}
                                                </Typography>
                                                {" "}
                                                <Typography component="span" variant="caption" sx={{ fontWeight: "bold" }}>
                                                    (p. {ref.page})
                                                </Typography>
                                            </>
                                        }
                                    />

                                </ListItem>)
                            })}
                        </List>
                    </CardContent>
                </Card>
            ))}
        </Box>

    );
};

export { AnswerAttachment };
