import axios, { AxiosInstance } from 'axios';
import {
    AddDataFromPDFResponseModel, IdResponseModel,
    LinkDocumentResponseModel,
} from '../types/WebletServiceTypes';
import { Constants } from '../contants/Constants';
import { env } from '../env';

export class DocumentService {

    private client: AxiosInstance;

    constructor(baseURL: string, service: string = '') {
        this.client = axios.create({ baseURL: baseURL + `/api` });
    }

    async addDataFromPDF(file: File, token?: string, conversationId?: string): Promise<IdResponseModel> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        };
        const formData = new FormData();
        formData.append('pdf', file);

        try {
            const response = await this.client.post<IdResponseModel>(Constants.Endpoints.DocCreate, formData, config)
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async addDataFromLink(userId: string, fileLink: string, conversationId?: string, token?: string): Promise<IdResponseModel> {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {
        }

        const encodedURL = btoa(fileLink);
        try {
            console.log('conversationId', conversationId)
            let api = '/doc_chat/upload' + `?user_id=${userId}&url=${encodedURL}&conversation_id=${conversationId}`;
            const response = await this.client.post<IdResponseModel>(api, body, config)
            console.log(response.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async linkDocumentToConversation(conversationId: string, documentId: string, token?: string): Promise<LinkDocumentResponseModel> {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {
        }

        let api = `/doc_chat/document/${documentId}/conversation/${conversationId}/link`;
        try {
            const response = await this.client.post<LinkDocumentResponseModel>(api, body, config)
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async deleteDocument(userId: string, token?: string): Promise<AddDataFromPDFResponseModel> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {
        }

        let api = '/doc_chat/delete' + `?user_id=${userId}`;
        try {
            const response = await this.client.delete<AddDataFromPDFResponseModel>(api, config)
            return response.data;
        } catch (error) {
            throw error
        }
    }
}

export const documentService = new DocumentService(env.REACT_APP_BACKEND_URL ?? "http://localhost:3001", "cognitive");
