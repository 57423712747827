

import React from 'react';
import { ChatStoreProvider } from '../context/ChatStoreProvider';
import { ChatBoxView } from './ChatBoxView/ChatBoxView';
import { ChatStore } from '../stores/ChatStore';
import { QuickActions } from '../../../types/ChatTypes';
import { Box, Typography } from '@mui/material';
import { SideBarView } from '../../../types/UITypes';
import ChatHistory from '../../../components/ChatHistory/ChatHistory';


export function chatFeatureNode(chatStore: ChatStore,
    quickActions: QuickActions[],
    onFeedbackThumbUp?: (messageId: string) => void,
    onFeedbackThumbDown?: (messageId: string) => void
): React.ReactNode {
    return (
        <ChatStoreProvider chatStore={chatStore}>
            <ChatBoxView quickActions={quickActions}
                onFeedbackThumbUp={onFeedbackThumbUp}
                onFeedbackThumbDown={onFeedbackThumbDown} />
        </ChatStoreProvider>
    );
}

export function chatHistorySidebarElement(title: string, chatStore: ChatStore,
    onConversationSelected: (converstationId: string) => void,
    onConversationDeleted: (conversationId: string) => void,
    onConversationTitleUpdated: (conversationId: string, title: string) => void): SideBarView {

    return {
        title: title,
        icon: "settings",
        sidebarElement: (<ChatStoreProvider chatStore={chatStore}>
            <Box display={'flex'} flexDirection={'column'}  >
                {chatStore._streamingChatStore?._conversationStore !== undefined &&
                    <ChatHistory
                        loading={chatStore._streamingChatStore?._conversationStore.conversationsLoading}
                        conversations={chatStore._streamingChatStore?._conversationStore.currentConversations}
                        onConversationSelected={onConversationSelected}
                        onConversationDeleted={onConversationDeleted}
                        onConversationTitleChanged={onConversationTitleUpdated} />}
            </Box>
        </ChatStoreProvider>)
    } as SideBarView;
}

export function chatSettingsSidebarElement(title: string, chatStore: ChatStore): SideBarView {
    return {
        title: title,
        icon: "settings",
        sidebarElement: (<ChatStoreProvider chatStore={chatStore}>
            <Box display={'flex'} flexDirection={'column'} >
                <Typography variant={'h6'}>Chat Settings</Typography>
            </Box>
        </ChatStoreProvider>)
    } as SideBarView;
}
