import { useContext } from "react";
import Summar from "../context/SummarizationStoreProvider";
import SummarizationContext from "../context/SummarizationStoreProvider";

export const useSummarizationStore = () => {
    const context = useContext(SummarizationContext);

    if (!context.summarizationStore) {
        // Optionally, you can throw an error if the userStore is not available.
        // This would mean that you're trying to use the hook outside of the UserStoreProvider.
        throw new Error("useUserStore must be used within a UserStoreProvider");
    }

    return context.summarizationStore;
}