import { useContext } from "react";
import RootStoreContext from "../contexts/RootStoreProvider";
import { Navigate, Outlet } from "react-router-dom";

export interface RouteAuthenticationGuardProps {

}

const RouteAuthenticationGuard = () => {
    const { rootStore } = useContext(RootStoreContext)
    if (rootStore === undefined) {
        return null
    }
    const authStore = rootStore.userStore
    console.log(`authStore: ${authStore}`)
    console.log(authStore?.isLoggedIn)

    if (authStore === undefined) {
        return <Navigate to='/login' />
    } else {
        if (authStore.isLoggedIn === false) {
            return <Navigate to='/login' />
        }
        return <Outlet />
    }
}



export default RouteAuthenticationGuard
