import axios, { AxiosInstance } from 'axios';
import { UserLoginResponse, UserRequest, UserResponse } from "../types/types_api";
import { Constants } from '../contants/Constants';
import { env } from '../env';


class UserService {
    private httpClient: AxiosInstance;

    constructor(baseURL: string) {
        this.httpClient = axios.create({  baseURL: baseURL + `/api` });
    }

    public async createUser(user: UserRequest, token: string): Promise<UserResponse | undefined> {
        let config = {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true
        }
        let data = {
            username: user.username,
            
        }
        try {
            const response = await this.httpClient.post<UserResponse>('/users', data, config);
            return response.data;
        } catch (error) {
            
            throw error;
        }
    }

    public async getUser(username: string, token: string): Promise<UserResponse | undefined> {

        let config = {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true
        }
        try {
            const response = await this.httpClient.get<UserResponse>(`/users/${username}`, config);
            return response.data;
        } catch (error) {
            console.error(`Error retrieving user with id ${username}: `, error);
            if (axios.isAxiosError(error)) {
                const response = error?.response;
                const request = error?.request;
        
                if (error.code === 'ERR_NETWORK') {
                    console.log('Connection problems..');
                } else if (error.code === 'ERR_CANCELED') {
                    console.log('Connection canceled..');
                }
        
                if (response) {
                    const statusCode = response?.status;
                    if (statusCode === 404) {
                        console.log('The requested user resource does not exist or has been deleted');
                        return undefined
                    }
                }
            }
            return undefined
        }
    }

    public async updateUser(user_id: string, user: UserRequest, token: string): Promise<UserResponse> {
        let config = {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true
        }
        try {
            const response = await this.httpClient.put<UserResponse>(`/chat/user/${user_id}`, user, config);
            return response.data;
        } catch (error) {
            console.error(`Error updating user with id ${user_id}: `, error);
            throw error;
        }
    }

    public async deleteUser(user_id: string, token: string): Promise<any> {
        let config = {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true
        }
        try {
            const response = await this.httpClient.delete<any>(`/chat/user/${user_id}`, config);
            return response.data;
        } catch (error) {
            console.error(`Error deleting user with id ${user_id}: `, error);
            throw error;
        }
    }

    async login(username: string, password: string): Promise<UserLoginResponse | undefined> {
        try {
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('password', password);
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                withCredentials: true
            };
            const response = await this.httpClient.post<UserLoginResponse>(Constants.Endpoints.Login, params, config);
            if (response !== null || response !== undefined) {
                let loginResponse = response.data as UserLoginResponse

                return Promise.resolve(loginResponse)
            }
            return Promise.reject(undefined)
        } catch (error) {
            throw error
        }
    }

    async logout(token: string) {

    }
}



export const userService = new UserService(env.REACT_APP_BACKEND_URL ?? "http://localhost:3001");