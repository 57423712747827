import { ChatFeaturePlugin } from "../features/chat/plugin/ChatPlugin"
import { DocumentChatFeaturePlugin } from "../features/documentchat/plugin/DocumentChatPlugin"
import { IFeaturePlugin } from "./types"
import { RootStore } from "../stores/RootStore"
import { Constants } from "../contants/Constants"
import { SummarizationPlugin } from "../features/summarization/plugin/SummarizationPlugin"
import { env } from "../env"


export function getFeaturePlugins(rootStore: RootStore): IFeaturePlugin[] {
    let featurePlugins = [] as IFeaturePlugin[]
    let features = env.REACT_APP_FEATURES || ''
    // console.group('getFeaturePlugins')
    // console.log(`features: ${features}`)
    features.split(Constants.Strings.Comma).forEach(feature => {
        if (feature === Constants.FeaturePlugins.Chat) {
            //console.log('adding chat feature plugin')
            featurePlugins.push(new ChatFeaturePlugin(rootStore))
        }
        if (feature === Constants.FeaturePlugins.DocumentChat) {
            featurePlugins.push(new DocumentChatFeaturePlugin(rootStore))
        }
        if (feature === Constants.FeaturePlugins.Summaries) {
            featurePlugins.push(new SummarizationPlugin(rootStore))
        }
    })
    // console.groupEnd()
    return featurePlugins
}
