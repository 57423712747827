
import { HandleMesssageResult } from "../stores/StreamingChatStore";
import { ContentStatus, IContentFilter, IContentFilterResult } from "./AIShieldTypes";

export class PassThroughContentFilter implements IContentFilter {

    async init(callback: (progress: number) => void) {
        console.log("PassThroughContentFilter initialized")
    }

    async checkInput(text: string): Promise<IContentFilterResult> {
        let contentFilterResult: IContentFilterResult = {
            allowed: true,
            contentStatus: ContentStatus.Okay,
            categories: [],
            categories_short: [],
            entities: []
        }
        return contentFilterResult;
    }

    

    containsCriticalCategories(categories: String[]): boolean {
        return false
    }

    clear() {

    }
}