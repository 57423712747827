import { UserRequest, UserResponse } from "../../types/types_api";
import { RootStore } from "../RootStore";
import { action, makeAutoObservable, observable } from 'mobx';
import { IUserStore, loginCallback } from "../interfaces/IUserStore";
import { AuthenticationResult, CacheLookupPolicy, EventType, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

import { v5 as uuidv5 } from 'uuid'
import { env } from "../../env";
import { userService } from "../../services/UserService";


/**
 * Description placeholder
 * @date 25/09/2023 - 07:39:51
 *
 * @export
 * @class UserStoreMSAL
 * @typedef {UserStoreMSAL}
 * @implements {IUserStore}
 */
export class UserStoreMSAL implements IUserStore {

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {(RootStore | undefined)}
     */
    _rootStore: RootStore | undefined = undefined;



    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {boolean}
     */
    @observable
    _isLoggedIn: boolean = false

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {string}
     */
    //@observable
    _accessToken: string = ''

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {?UserResponse}
     */
    //@observable
    _currentUser?: UserResponse;

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(string | undefined)}
     */
    @observable
    _error: string | undefined = undefined

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {boolean}
     */
    @observable
    _loading: boolean = false

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(PublicClientApplication | undefined)}
     */
    _instance: PublicClientApplication | undefined = undefined

    _msalReady: boolean = false

    /**
     * Creates an instance of UserStoreMSAL.
     * @date 25/09/2023 - 07:39:50
     *
     * @constructor
     */
    constructor() {
        console.log('UserStoreMSAL: constructor')
        this._instance = undefined
        makeAutoObservable(this);
    }

    setMsalReady(msalReady: boolean) {
        this._msalReady = msalReady
    }

    _tokenRequestInProgress: Promise<AuthenticationResult> | null = null;
    _createUserInProgress: Promise<UserResponse | undefined> | null = null;

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {*}
     */
    set rootStore(rootStore: RootStore | undefined) {
        this._rootStore = rootStore
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(RootStore | undefined)}
     */
    get rootStore(): RootStore | undefined {
        return this._rootStore
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(PublicClientApplication | undefined)}
     */
    get instance(): PublicClientApplication | undefined {
        return this._instance
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {*}
     */
    set instance(instance: PublicClientApplication | undefined) {
        this._instance = instance
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {boolean}
     */
    get isLoggedIn(): boolean {
        return this._isLoggedIn
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {boolean} isLoggedIn
     */
    @action
    setLoggedIn(isLoggedIn: boolean): void {
        this._isLoggedIn = isLoggedIn
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {string}
     */
    get accessToken(): string {

        return "undefined"
    }


    async getTokenSilent(request: any) {
        if (!this._tokenRequestInProgress) {
            if (this._instance !== undefined) {
                this._tokenRequestInProgress = this._instance.acquireTokenSilent(request);
            }
        }

        if (this._tokenRequestInProgress) {
            try {
                const response = await this._tokenRequestInProgress;
                return response;
            } catch (error) {
                throw error;
            } finally {
                this._tokenRequestInProgress = null;
            }
        }
    }

    /**
     * Retrieves the current access token from the MSAL instance
     * @date 25/09/2023 - 07:39:50
     *
     * @async
     * @returns {Promise<string | undefined>}
     */
    async getAccessToken(): Promise<string | undefined> {
        console.log('UserStoreMsal: getAccessToken');

        if (!this._isLoggedIn) {
            console.log('UserStoreMsal: getAccessToken: not logged in');
            return undefined;
        }

        if (!this._instance) {
            console.log('UserStoreMsal: getAccessToken: instance not defined');
            return undefined;
        }

        const account = this._instance.getActiveAccount();
        if (!account) {
            console.log('UserStoreMsal: getAccessToken: no account found');
            return undefined;
        }

        console.log('Account:', account);
        const accessTokenRequest = {
            scopes: ['openid', 'offline_access', "profile"],
            account: account
        };

        try {
            const response = await this.getTokenSilent(accessTokenRequest);
            if (response) {
                console.log('acquireTokenSilent succeeded', response);
                return response.idToken;
            }
        } catch (error) {
            console.log('acquireTokenSilent error:', error);

            if (error instanceof InteractionRequiredAuthError) {
                try {
                    console.log('UserStoreMsal: getAccessToken: loginRedirect');

                    try {
                        await this._instance.ssoSilent(accessTokenRequest)
                    } catch (error) {
                        console.log('UserStoreMsal: getAccessToken: error', error);
                        try {
                            await this._instance.loginRedirect(accessTokenRequest);
                        } catch (redirectError) {
                            console.log('Login redirect error:', redirectError);
                        }
                    }
                    
                    // Note: loginRedirect does not return a response. It redirects the user.
                } catch (redirectError) {
                    console.log('Login redirect error:', redirectError);
                }
            }

        } finally {
            // Reset the token request progress tracker
            this._tokenRequestInProgress = null;
        }
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {string} accessToken
     */
    @action
    setAccessToken(accessToken: string): void {
        console.log('UserStoreMsal: setAccessToken')
        console.log(accessToken)
        this._accessToken = accessToken
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     */
    @action
    clear() {
        this._accessToken = ''
        this._isLoggedIn = false
        this._currentUser = undefined
        this._error = undefined
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {UserResponse} user
     */
    
    setUser(user: UserResponse) {
        console.log('UserStoreMsal: setUser')
        console.log(user)
        this._currentUser = user;
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {(UserResponse | undefined)}
     */
    get currentUser(): UserResponse | undefined {
        return this._currentUser
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     */
    @action
    clearUser() {
        this._currentUser = undefined;
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {(string | undefined)}
     */
    get errorMessage(): string | undefined {
        return this._error
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {(string | undefined)} errorMessage
     */
    @action
    setErrorMessage(errorMessage: string | undefined) {
        this._error = errorMessage
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {boolean} loading
     */
    @action
    setLoading(loading: boolean) {
        this._loading = loading
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {boolean}
     */
    get loading(): boolean {
        return false
    }

    @action
    async login(username: string, password: string, loginCallback?: loginCallback): Promise<boolean | undefined> {
        console.log('login userStoreMSAL')
        console.log(this._instance)

        if (this._instance !== undefined) {
            try {

                await this._instance.loginRedirect();

            } catch (error) {
                console.log(error)
                this.setErrorMessage('Error logging in. Please try again.')
            }
        }
        return Promise.resolve(true)
    }

    @action
    async logout() {
        if (this._instance !== undefined) {
            this.clear()
            console.log('UserStoreMSAL:clearCache')
            let activeAccount = this._instance.getActiveAccount();
            const logoutUrl = env.REACT_APP_MSAL_LOGOUT_URL ? env.REACT_APP_MSAL_LOGOUT_URL : '';
            const postLogoutRedirectUri = encodeURIComponent(env.REACT_APP_MSAL_LOGOUT_REDIRECT_URL ? env.REACT_APP_MSAL_LOGOUT_REDIRECT_URL : '');
            let logout = `${logoutUrl}?post_logout_redirect_uri=${postLogoutRedirectUri}`;
            await this._instance.clearCache();
            await this._instance.logoutRedirect({
                account: activeAccount,
                postLogoutRedirectUri: logout,
            });
        }
    }

    async checkUserLogIn(): Promise<boolean> {

        // if (this._instance !== undefined) {
        //     if (this._msalReady === false) {
        //         return Promise.resolve(false)
        //     }
        //     const account = await this._instance.getAllAccounts()[0];
        //     console.log(account)
        //     const accessTokenRequest = {
        //         scopes: [],
        //         account: account,
        //     };
        //     let accessTokenReponse = await this._instance.acquireTokenSilent(accessTokenRequest)
        //     if (accessTokenReponse !== undefined) {
        //         console.log(accessTokenReponse)
        //         return Promise.resolve(true)
        //     }
        // }
        return Promise.resolve(this.isLoggedIn)
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @async
     * @returns {Promise<void>}
     */
    // async getUser(): Promise<void> {
    //     if (this._instance) {
    //         let activeAccount = this._instance.getActiveAccount();
    //         if (activeAccount) {

    //             let currentUser = {
    //                 guid: activeAccount.localAccountId,
    //                 username: activeAccount.username
    //             } as UserResponse
    //             this.setUser(currentUser)
    //         }
    //     }
    // }

    // for user table
    async retrieveUser(username: string, token: string): Promise<UserResponse | undefined> {
        return await userService.getUser(username, token)
    }

    async _createUser(userRequest: UserRequest, token: string) : Promise<UserResponse | undefined> {
        if (!this._createUserInProgress) {
            if (this._instance !== undefined) {
                this._createUserInProgress = userService.createUser(userRequest, token);
            }
        }

        if (this._createUserInProgress) {
            try {
                const response = await this._createUserInProgress;
                return response;
            } catch (error) {
                throw error;
            } finally {
                this._createUserInProgress = null;
            }
        }
    }

    async createUser(userRequest: UserRequest, token: string): Promise<UserResponse | undefined> {
        
        return userService.createUser(userRequest, token)
    }

    async getOrCreateUser(username: string, token: string): Promise<UserResponse | undefined> {
        try {
            let userResponse = await this.retrieveUser(username, token)
            if (userResponse?.guid !== null) {
                return userResponse
            } else {
                let userRequest: UserRequest = {
                    username: username
                }
                console.log('create user')
                let newUserResponse = await this._createUser(userRequest, token)
                return newUserResponse
            }
        } catch (error) {
            console.log(error)
            return undefined
        } 
    }

    async getUser(username: string, token: string): Promise<UserResponse | undefined> {
        let userResponse = await this.retrieveUser(username, token)
        if (userResponse?.guid !== null) {
            return userResponse
        }
    }
}