import { useContext, useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import UserStoreContext from '../contexts/UserStoreProvider';
import { v5 as uuidv5 } from 'uuid';
import RootStoreContext from '../contexts/RootStoreProvider';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { SilentRequest } from '@azure/msal-browser';
import AppLoadingSkeleton from '../components/AppLoadingSkeleton';
import { is } from 'date-fns/locale';

interface IdTokenClaims {
    sub: string; // Assuming 'sub' is a string
    // ... other properties if needed
}

interface ResponseType {
    idTokenClaims: IdTokenClaims;
    account: {
        idToken: string;
        // ... other properties if needed
    };
    // ... other properties if needed
}

const AuthRedirect = () => {
    const { instance, inProgress, accounts } = useMsal();

    const navigate = useNavigate();

    
    const isAuthenticated = useIsAuthenticated()

    console.log('isAuthenticated', isAuthenticated)
    console.log('inProgress', inProgress)
    console.log('accounts', accounts)
    
    const { userStore } = useContext(UserStoreContext);

    const { rootStore } = useContext(RootStoreContext);

    const [queryParams, setQueryParams] = useState<Record<string, string>>({});

    const acc = instance?.getActiveAccount()

    // useEffect(() => {
        
    //     if (instance !== undefined) {
    //         let acc = instance.getActiveAccount()
    //         if (acc !== null)  {
    //             console.log('acc', acc)
    //             const silentRequest = {
    //                 scopes: ["openid", "offline_access", "profile"], // Adjust with your own scopes
    //                 account: acc,  // The account object you want to acquire the token for
    //             } as SilentRequest ;

    //             instance.acquireTokenSilent(silentRequest)
    //                 .then(response => {
    //                     let token = response.idToken
    //                     let responseTyped = response as ResponseType;
    //                     const account = responseTyped.account;
    //                     let sub = responseTyped.idTokenClaims.sub
    //                     console.log('acquireTokenSilent succeeded', response)
                        
    //                     if (sub === undefined) {
    //                         navigate('/unauthorized'); // Navigate to the main page.
    //                         return
    //                     }
    //                     //sub = "hartie"
    //                     userStore?.getOrCreateUser(sub, token).then( async (user) => {
    //                         console.log('user', user)
    //                         if (user !== undefined && user.guid !== undefined) {
    //                             userStore?.setUser({
    //                                 guid: user.guid, // uuid converter
    //                                 username: user.username
    //                             })
    //                             userStore?.setLoggedIn(true);
    //                             console.log('AuthRedirect: navigate to chat')
    //                             await rootStore?.initAIShield()
    //                             //navigate('/chat'); // Navigate to the main page.
    //                         }
    //                     })
    //                 })
    //             }
    //         }
    // }, [instance]);

    useEffect(() => {
        console.log('AuthRedirect: isAuthenticated', isAuthenticated)
        if (instance !== undefined) {
            let acc = instance.getActiveAccount()
            if (acc !== null)  {
                console.log('acc', acc)
            } else {    
                console.log('AuthRedirect: acc is null')
            }
        } else {
            console.log('AuthRedirect: instance is undefined')
        }
    }, [isAuthenticated]);


    // useEffect(() => {
    //     console.log('AuthRedirect: active plugin ready', isAuthenticated)
    //     console.log('moving to chat')
    //     navigate('/chat'); // Navigate to the main page.
    // }, [rootStore?.activePlugin]);


    // Function to render the query parameters
    const renderQueryParams = () => {
        return Object.entries(queryParams).map(([key, value], index) => (
            <div key={index}>
                <strong>{key}:</strong> {value}
            </div>
        ));
    };

    return (

        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
            {/* <div>
                {renderQueryParams()}
            </div> */}
            <AppLoadingSkeleton onlyAppLogo={true} />
        </Box>

    );
};

export default observer(AuthRedirect);
