import { env } from "../../../env"
import { connectionFactory } from "../../../utils/serverside/ConnectionFactory"
import { IEventConnection } from "../../../utils/serverside/ConnectionInterfaces"

export interface ProcessedStatus {
    status: string
    num_chunks: number
    processed: number
}

type ProcessingUpdate = (id: string, message: string) => void

export class DocumentProcessingStatusTracker {

    _eventConnection: IEventConnection | null = null
    _onProcessingUpdate: ProcessingUpdate | undefined = undefined

    constructor() {
        this._eventConnection = null
        this._onProcessingUpdate = undefined
    }

    async connect(id: string, token?: string, onProcessingUpdate?: ProcessingUpdate): Promise<void> {

        this._onProcessingUpdate = onProcessingUpdate
        let backendUrl = env.REACT_APP_BACKEND_URL
        if (backendUrl !== undefined) {
            try {
                if (this._eventConnection === null) {
                    let url = `${backendUrl}/api/doc_chat/status/${id}?token=${token}`
                    //let url = `${backendUrl}/api/doc_chat/status/${id}`
                    // const config = {
                    //     headers: {
                    //         Authorization: `Bearer ${token}`
                    //     },
                    //     withCredentials: true
                    // };
                    //document.cookie = `token=${token}; path=/;`
                    this._eventConnection = connectionFactory.createConnection('sse', url)
                }
                if (this._eventConnection !== null) {
                    this._eventConnection.connect()
                    if (this._eventConnection !== null) {
                        this._eventConnection.onMessage = this.onMessage.bind(this, id)
                    }
                }
            } catch (error) {
                throw error
            }
        }

        return Promise.resolve(undefined);
    }

    async simulateMessage(id: string, token?: string, onProcessingUpdate?: ProcessingUpdate, totalChunks: number = 10): Promise<void> {
        this._onProcessingUpdate = onProcessingUpdate
        let currentChunk = 0;

        const intervalId = setInterval(() => {
            // Increment the current chunk number
            currentChunk += 1;

            // Simulate the message data
            const simulatedData: ProcessedStatus = {
                status: currentChunk < totalChunks ? 'processing' : 'completed',
                num_chunks: totalChunks,
                processed: currentChunk,
            };

            // Convert the simulated data to a string and call the onMessage method
            this.onMessage(id, JSON.stringify(simulatedData));

            // If the status is completed, clear the interval
            if (simulatedData.status === 'completed') {
                clearInterval(intervalId);
            }
        }, 500);

    }

    onMessage(id: string, message: string) {
        console.log('IncommingMessage: onMessage')
        console.log(message)

        const receivedData: string = message;
        let object = JSON.parse(receivedData) as ProcessedStatus

        if (this._onProcessingUpdate) {
            this._onProcessingUpdate(id, message)
        }

        console.log(object)
        if (object.status == 'completed') {
            this._eventConnection?.disconnect()
            this._eventConnection = null
        }
    }
}