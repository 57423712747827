
import { createContext, FC } from "react";
import { ChatStore } from "../stores/ChatStore";


const ChatStoreContext = createContext({ chatStore: undefined as ChatStore | undefined })

interface ChatStoreProviderProps {
    children?: React.ReactNode
    chatStore?: ChatStore | undefined
}

export const ChatStoreProvider: FC<ChatStoreProviderProps> = ({ children, chatStore }) => {

    return (
        <ChatStoreContext.Provider value={{ chatStore }}>
            {children}
        </ChatStoreContext.Provider >
    )
}

export default ChatStoreContext
