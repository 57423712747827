import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Drawer, IconButton, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';


import { Menu as MenuIcon } from '@mui/icons-material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Import necessary components and hooks
import { AppBar, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { SidebarSubMenuItem } from './types';
import RootStoreContext from '../../contexts/RootStoreProvider';

import { OptionsWithExtraProps, VariantType, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SplitButton from '../../components/SplitButton';
import { SideBarView, UserMenuOptions } from '../../types/UITypes';
import UserMenu from '../../components/UserMenu/UserMenu';
import { observer } from 'mobx-react-lite';
import SoftwareVersion from '../../components/SoftwareVersion/SoftwareVersion';
import ToolSelection from '../../components/ToolSelection';
import UserStoreContext from '../../contexts/UserStoreProvider';

import TermsOfServiceDialog from '../../components/TermsOfUseDialog';
import DocumentListDialog from '../../components/DocumentListDialog';
import AppLoadingSkeleton from '../../components/AppLoadingSkeleton';
import { env } from '../../env';


// Import your pages components
const drawerWidth = 320;

interface DashboardProps {
    selectableTools: SidebarSubMenuItem[];
    onlyAppLogo?: boolean;
    poweredBySection?: boolean;
    userMenuOptions?: UserMenuOptions[];
}

const Dashboard: React.FC<DashboardProps> = ({ selectableTools, onlyAppLogo, poweredBySection, userMenuOptions }) => {

    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const { rootStore } = useContext(RootStoreContext)
    const { userStore } = useContext(UserStoreContext)


    const notificationHandler = (message: string, variant: OptionsWithExtraProps<VariantType>) => {
        variant.persist = true;
        enqueueSnackbar(message, variant)
    }

    useEffect(() => {
        if (rootStore !== undefined) {
            rootStore.notificationHandler = notificationHandler
        }

        const initializeApp = async () => {

            if (rootStore !== undefined) {
                console.log('initializeApp:beforeinitAIShield')
                await rootStore.initAIShield()
                console.log('initializeApp:afterinitAIShield')
                return Promise.resolve()
            }
        }

        if (userStore !== undefined) {
            if (userStore.isLoggedIn === true) {
                console.log('appReady: false')

                initializeApp()

            }
        }

    }, [])


    const reoccurInterval = 10000;
    const [toolbarOpen, setToolbarOpen] = useState(true);


    useEffect(() => {
        if (!toolbarOpen) {
            const interval = setTimeout(() => {
                setToolbarOpen(true);
            }, reoccurInterval);

            return () => clearTimeout(interval); // Cleanup the timeout on component unmount or when toolbarOpen state changes
        }
    }, [toolbarOpen, reoccurInterval]);

    const handleClose = () => {
        setToolbarOpen(false);
    };

    const LoadingComponent: React.FC = () => {
        return <div>Loading terms of service...</div>;
    };

    const handleTermsOfServiceAccepted = () => {
        if (rootStore !== undefined) {
            console.log('handleTermsOfServiceAccepted')
            rootStore.setTermsOfUseAccepted(true)
        }
    }



    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ml: !isSmallScreen ? `${drawerWidth}px` : 0 }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>

                </Toolbar>
            </AppBar> */}

                {rootStore !== undefined && rootStore.appReady === false &&
                    <>
                        <AppLoadingSkeleton onlyAppLogo={onlyAppLogo} />
                    </>
                }
                {
                    rootStore !== undefined && rootStore.appReady === true && rootStore.termsofUseAccepted === false &&
                    <>
                        <Suspense fallback={<LoadingComponent />}>
                            <TermsOfServiceDialog
                                open={rootStore.termsofUseAccepted === false}
                                title={t('termsOfUseDialog.title', { appName: env.REACT_APP_NAME_DISPLAY })}
                                url={env.REACT_APP_TERMS_OF_SERVICE_MARKDOWN}
                                onAccept={handleTermsOfServiceAccepted}
                                buttonAgreeText={t('termsOfUseDialog.buttonAgreeText')}
                            />
                        </Suspense>
                        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ml: !isSmallScreen ? `${drawerWidth}px` : 0 }}>
                            {onlyAppLogo === false &&
                                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {isSmallScreen && (
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={handleDrawerToggle}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        {!isSmallScreen && (
                                            <Box>{' '}</Box>
                                        )}

                                        <LazyLoadImage
                                            height={'45px'}
                                            src={'./GFT_Logo_A2_RGB_web.png'}

                                            alt={`App Logo`}
                                            effect="blur"
                                            style={{
                                                filter:
                                                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Box sx={{ p: 0.2, backgroundColor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <LazyLoadImage
                                                height={'45px'}
                                                id="gft_logo"
                                                //src={'/GFT_Logo_A2_RGB_web.png'}
                                                alt={`App Logo`}
                                                effect="blur"
                                                style={{
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </Box>
                                        {rootStore !== undefined &&
                                            <SplitButton options={rootStore.uiStore._availableLanguages}
                                                initialIndex={0}
                                                onClickCallback={rootStore.uiStore.onSelectedLanguageCallback.bind(rootStore.uiStore)} />
                                        }
                                        <UserMenu userMenuOptions={userMenuOptions} />
                                    </Box>
                                </Toolbar>
                            }
                            {onlyAppLogo === true &&
                                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {isSmallScreen && (
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={handleDrawerToggle}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        {!isSmallScreen && (
                                            <Box>{' '}</Box>
                                        )}

                                        {env.REACT_APP_NAME === "LBBW" &&
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <LazyLoadImage
                                                    height={'45px'}
                                                    src={'./LBBW_weiss_neg_RGB.png'}
                                                    alt={`App Logo`}
                                                    effect="blur"
                                                    style={{
                                                        filter:
                                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                                <LazyLoadImage
                                                    height={'45px'}
                                                    src={'./Schriftzug_bluegpt_Negativ_RGB_2.png'}
                                                    alt={`App Logo`}
                                                    effect="blur"
                                                    style={{
                                                        filter:
                                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                            </Box>
                                        }
                                        {env.REACT_APP_NAME !== "LBBW" &&
                                            < LazyLoadImage
                                                height={'45px'}
                                                src={'./GFT_Logo_A2_RGB_web.png'}
                                                alt={`App Logo`}
                                                effect="blur"
                                                style={{
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        }
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {rootStore !== undefined &&
                                            <SplitButton options={rootStore.uiStore._availableLanguages}
                                                initialIndex={0}
                                                onClickCallback={rootStore.uiStore.onSelectedLanguageCallback.bind(rootStore.uiStore)} />
                                        }
                                        <UserMenu userMenuOptions={userMenuOptions} />
                                    </Box>
                                </Toolbar>
                            }
                        </AppBar>
                        {/* Drawer Skeleton */}
                        <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
                            <Skeleton variant="rounded" width={drawerWidth} height="100vh" />

                            {/* Main Content Skeleton */}
                            <Box sx={{ m: 3, flexGrow: 1, height: '100%' }}>
                                <Skeleton variant="rounded" width="100%" height="100%" />
                            </Box>
                        </Box>
                    </>
                }

                {
                    rootStore !== undefined && rootStore.appReady === true && rootStore.termsofUseAccepted === true &&
                    <>
                        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ml: !isSmallScreen ? `${drawerWidth}px` : 0 }}>
                            {onlyAppLogo === false &&
                                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {isSmallScreen && (
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={handleDrawerToggle}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        {!isSmallScreen && (
                                            <Box>{' '}</Box>
                                        )}

                                        <LazyLoadImage
                                            height={'45px'}
                                            src={'./GFT_Logo_A2_RGB_web.png'}
                                            alt={`App Logo`}
                                            effect="blur"
                                            style={{
                                                filter:
                                                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Box sx={{ p: 0.2, backgroundColor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <LazyLoadImage
                                                height={'45px'}
                                                //id="gft_logo"
                                                src={'./GFT_Logo_A2_RGB_web.png'}
                                                alt={`App Logo`}
                                                effect="blur"
                                                style={{
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </Box>
                                        {rootStore !== undefined &&
                                            <SplitButton options={rootStore.uiStore._availableLanguages}
                                                initialIndex={0}
                                                onClickCallback={rootStore.uiStore.onSelectedLanguageCallback.bind(rootStore.uiStore)} />
                                        }
                                        <UserMenu userMenuOptions={userMenuOptions} />
                                    </Box>
                                </Toolbar>
                            }
                            {onlyAppLogo === true &&
                                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {isSmallScreen && (
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={handleDrawerToggle}
                                                sx={{ mr: 2 }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        {!isSmallScreen && (
                                            <Box>{' '}</Box>
                                        )}

                                        {env.REACT_APP_NAME === "LBBW" &&
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <LazyLoadImage
                                                    height={'45px'}
                                                    src={'./LBBW_weiss_neg_RGB.png'}
                                                    alt={`App Logo`}
                                                    effect="blur"
                                                    style={{
                                                        filter:
                                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                                <LazyLoadImage
                                                    height={'45px'}
                                                    src={'./Schriftzug_bluegpt_Negativ_RGB_2.png'}
                                                    alt={`App Logo`}
                                                    effect="blur"
                                                    style={{
                                                        filter:
                                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                            </Box>
                                        }
                                        
                                        {env.REACT_APP_NAME !== "LBBW" &&
                                            < LazyLoadImage
                                                height={'45px'}
                                                src={'./GFT_Logo_A2_RGB_web.png'}
                                                alt={`App Logo`}
                                                effect="blur"
                                                style={{
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        }
                                    </Box>
                                    { env.REACT_APP_STAGE_NAME !== undefined && 

                                        <Box sx={{ bgcolor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Typography variant='h4' sx={{ m: 1, fontWeight: 'bold', color: '#FF0000'  }}>  {env.REACT_APP_STAGE_NAME}</Typography>
                                        </Box>  
                                    }
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {rootStore !== undefined &&
                                            <SplitButton options={rootStore.uiStore._availableLanguages}
                                                initialIndex={0}
                                                onClickCallback={rootStore.uiStore.onSelectedLanguageCallback.bind(rootStore.uiStore)} />
                                        }
                                        <UserMenu userMenuOptions={userMenuOptions} />
                                    </Box>
                                </Toolbar>
                            }
                        </AppBar>
                        <Drawer variant={isSmallScreen ? "temporary" : "permanent"} open={!isSmallScreen || drawerOpen} onClose={handleDrawerToggle}
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                [`& .MuiDrawer-paper`]: { width: drawerWidth },

                            }}>
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'} >
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} sx={{ overflow: 'hidden' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: 'primary' }}>
                                        <Toolbar />

                                        <ToolSelection label={t('sidebar.labelTools')} selectableTools={selectableTools} />

                                        <Button sx={{ m: 1 }} variant="outlined" onClick={() => {
                                            rootStore?.activePlugin?.onNewButton()
                                        }} disabled={!rootStore?.activePlugin?.newInteractionAllowed}>{t('sidebar.newChat')}</Button>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                        {/* <Typography fontWeight={'bold'} textAlign={'left'} sx={{ m: 1 }}>{t('sidebar.labelTools')}</Typography> */}
                                        {rootStore !== undefined && rootStore.activePlugin !== undefined &&

                                            // <Box display={'flex'} flexDirection={'column'} sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 220px)' }} >
                                            //     {rootStore.activePlugin.sidebarViews.map((sidebarView: SideBarView, index: number) => {

                                            //         return (<Accordion disableGutters key={`${sidebarView.title}_sidebar`}
                                            //             expanded={expanded === `${sidebarView.title}_sidebar`}
                                            //             onChange={handleChange(`${sidebarView.title}_sidebar`)}

                                            //         >
                                            //             <AccordionSummary
                                            //                 expandIcon={<ExpandMoreIcon />}
                                            //                 aria-controls="panel1a-content"
                                            //                 id="panel1a-header"
                                            //             >
                                            //                 <Typography fontWeight={'bold'}>{sidebarView.title}</Typography>
                                            //             </AccordionSummary>
                                            //             <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                            //                 {sidebarView.sidebarElement}
                                            //             </AccordionDetails>
                                            //         </Accordion>)
                                            //     })}
                                            // </Box>

                                            // <Box display={'flex'} flexDirection={'column'} sx={{ height: 'calc(100vh - 300px)' }} >

                                            //     {rootStore.activePlugin.sidebarViews.map((sidebarView: SideBarView, index: number) => {

                                            //         return (
                                            //             <>
                                            //                 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            //                     <Typography fontWeight={'bold'}>{sidebarView.title}</Typography>
                                            //                     <Box flex={1} overflow="auto">
                                            //                         {sidebarView.sidebarElement}
                                            //                     </Box>
                                            //                 </Box>
                                            //             </>
                                            //         )
                                            //     })}
                                            // </Box>

                                            <Box display={'flex'} flexDirection={'column'} sx={{ height: 'calc(100vh - 250px)' }} >

                                                {rootStore.activePlugin.sidebarViews.map((sidebarView: SideBarView, index: number) => {
                                                    return (
                                                        <React.Fragment key={sidebarView.title}>
                                                            <Typography textAlign={'left'} fontWeight={'bold'} sx={{ m: 1 }}>{sidebarView.title}</Typography>
                                                            <Box flex={1} overflow="auto">
                                                                {sidebarView.sidebarElement}
                                                            </Box>
                                                        </React.Fragment >
                                                    )
                                                })}
                                            </Box>
                                        }
                                    </Box>

                                </Box>
                                <Box>
                                    <SoftwareVersion version={process.env.REACT_APP_VERSION_NUMBER ? process.env.REACT_APP_VERSION_NUMBER : '<not set>'}
                                        gitHash={process.env.REACT_APP_VERSION_HASH ? process.env.REACT_APP_VERSION_HASH : '<not set>'} />
                                </Box>

                                {/* <Box sx={{ m: 1 }} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                        <Box sx={{ m: 1, borderColor: 'primary', border: 1 }} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                            <DisclaimerComponent title={t('sidebar.disclaimer.title')}
                                content={t('sidebar.disclaimer.message')} timeoutInterval={reoccurInterval}
                                severity='info' />
                        </Box>
                    </Box> */}

                            </Box>
                        </Drawer >
                        <Box
                            component={"main"}
                            sx={{
                                flexDirection: 'column',
                                flexGrow: 1,
                                display: 'flex',
                                height: '100%',
                                p: 0,

                                ...(!isSmallScreen && { width: `calc(100% - ${drawerWidth}px)`, height: '100%' }),
                                ...(isSmallScreen && { width: `100%`, height: '100%' })
                            }}>
                            <Toolbar />
                            <Outlet />
                        </Box>
                    </>
                }
            </Box >

            {rootStore?.uiStore !== undefined &&
                <DocumentListDialog open={rootStore?.uiStore.showDocumentListDialog} onClose={rootStore?.uiStore.onDocumentListDialogClose.bind(rootStore?.uiStore)} />
            }
        </>
    );
};

export default observer(Dashboard);
