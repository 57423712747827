
import { action, makeAutoObservable, observable } from 'mobx';
import { HandleMesssageResult } from '../../../stores/BaseStreamingChatStore';
import { RootStore } from '../../../stores/RootStore';

import { Constants } from '../../../contants/Constants';
import { IFeaturePluginStore } from '../../../plugin/types';

import i18n from '../../../i18n';
import { StreamingChatStore } from '../../../stores/StreamingChatStore';
import { ChatMessageProps } from '../../../types/ChatTypes';

export class ChatStore implements IFeaturePluginStore {

    _rootStore: RootStore | undefined = undefined;

    @observable
    _openClearDialog: boolean = false

    @observable
    _openContentWarningDialog: boolean = false

    _streamingChatStore: StreamingChatStore

    constructor(rootStore: RootStore) {

        makeAutoObservable(this)
        this._openClearDialog = false
        this._rootStore = rootStore
        this._openContentWarningDialog = false

        this._streamingChatStore = new StreamingChatStore(rootStore, Constants.Services.Chat)
        this._streamingChatStore.setNewChatAllowed(false)
        if (this._streamingChatStore.conversationStore !== undefined) {
            this._streamingChatStore.conversationStore.conversationSelectedCallback = this.onConversationSelected.bind(this)
            this._streamingChatStore.conversationStore.conversationDeletedCallback = this.onConversationDeleted.bind(this)
        }
    }

    @action
    setOpenClearDialog(openClearDialog: boolean) {
        this._openClearDialog = openClearDialog
    }

    get openClearDialog() {
        return this._openClearDialog
    }

    @action
    setOpenContentWarningDialog(openContentWarningDialog: boolean) {
        this._openContentWarningDialog = openContentWarningDialog
    }

    get openContentWarningDialog() {
        return this._openContentWarningDialog
    }

    quickActionClearChatHistory() {
        this.setOpenClearDialog(true);
    };

    handleClearChatYes() {
        this.setOpenClearDialog(false);
        this.onNewChat()
    };

    handleClearChatNo() {
        this.setOpenClearDialog(false);
    };

    handleStreamingError() {
        if (this._streamingChatStore.getHistory.length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        } else {
            this._streamingChatStore?.setNewChatAllowed(false)
        }
    }

    async checkContent(newMessage: string): Promise<HandleMesssageResult> {
        let  result = this._streamingChatStore?.checkContent(newMessage)

        return result
    }

    async sendMessage(newMessage: string, title?: string, updateAIShieldEvent?: boolean): Promise<boolean> {

        // if no conversation is yet created, create one
        let token = await this._rootStore?.userStore?.getAccessToken();
        let userId = this._rootStore?.userStore?.currentUser?.guid
        if (userId !== undefined) {
            if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                if (selectedModel === undefined) {
                    selectedModel = "gpt-35"
                }
                
                let serviceName = Constants.Services.Chat
                if (selectedModel !== undefined && token !== undefined) {
                    let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Chat', serviceName, selectedModel, token)
                }
            }
        }

        return this._streamingChatStore?.sendMessage(newMessage, title, updateAIShieldEvent)
    }

    async initialize() {
        console.log('Initializing Chat Store')
        

        this._streamingChatStore.setRequestInProgress(true)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        let userId = this._rootStore?.userStore?.currentUser?.guid
        console.log('userStore', this._rootStore?.userStore)
        if (userId !== undefined) {

            let token = await this._rootStore?.userStore?.getAccessToken();

            

            let serviceName = Constants.Services.Chat
            if (this._streamingChatStore?._conversationStore !== undefined) {
                await this._streamingChatStore?._conversationStore.retrieveConversationsForUser(userId, serviceName, token)
            }
            if (this._streamingChatStore?._promptSuggestionsStore !== undefined) {
                let currentLanguage = i18n.language
                
                if (this._streamingChatStore?.messagesUI !== undefined && 
                        this._streamingChatStore?.messagesUI.length > 0) {
                        
                        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)    
                } else {
                    await this._streamingChatStore?._promptSuggestionsStore.retrievePromptSuggestions(serviceName, currentLanguage)
                    this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
                }
            
            }
            if (this._streamingChatStore?._modelSelectionStore !== undefined) {
                if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                    await this._streamingChatStore?._modelSelectionStore.retrieveAvailableModels()
                }
            }
            localStorage.setItem('lastFeature', Constants.Routes.DocumentChat)
            this._streamingChatStore.setRequestInProgress(false)
            this._rootStore?.fireUpdateUI()
        }
    }

    async onNewChat() {
        this._streamingChatStore?.clearChat()
        this._streamingChatStore?.resetPromptSuggestions()
        this._streamingChatStore?.setInitialMessage(undefined)
        this._streamingChatStore?.modelSelectionStore?.setModelSelectionVisible(true)
        this._streamingChatStore?.resetSelectedConverstation()
        // create conversation of no conversation was created
        let token = await this._rootStore?.userStore?.getAccessToken();
        let userId = this._rootStore?.userStore?.currentUser?.guid
        if (userId !== undefined) {
            if (this._streamingChatStore?._conversationStore?._selectedConversation === undefined) {
                let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                if (selectedModel === undefined) {
                    selectedModel = "gpt-35"
                }
                
                let serviceName = Constants.Services.Chat
                if (selectedModel !== undefined && token !== undefined) {
                    let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Chat', serviceName, selectedModel, token)
                }
            } else {
                if (this._streamingChatStore?.conversationStore?.currentMessages !== undefined
                    && this._streamingChatStore?.conversationStore?.currentMessages.length > 0) {
                        
                    let selectedModel = this._streamingChatStore?._modelSelectionStore?._currentModel?.id
                    if (selectedModel === undefined) {
                        selectedModel = "gpt-35"
                    }
                    
                    let serviceName = Constants.Services.Chat
                    if (selectedModel !== undefined && token !== undefined) {
                        let conversation = await this._streamingChatStore?._conversationStore?.createConversation(userId, 'New Chat', serviceName, selectedModel, token)
                    }
                }
            }
        }
    }

    onConversationSelected(conversationId: string, chatMessagePropsArray: ChatMessageProps[]): void {
        this._streamingChatStore.setMessagesUI(chatMessagePropsArray)
        // update the model selection
        let conversationModel = this._streamingChatStore?._conversationStore?._selectedConversation?.model
        if (conversationModel !== undefined) {
            this._streamingChatStore._modelSelectionStore?.initializeFromModelId(conversationModel)
        }
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        if (this._streamingChatStore.getHistory().length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        }
        this._streamingChatStore?.modelSelectionStore?.setModelSelectionVisible(false)
    }

    onConversationDeleted(conversationId: string): void {

        if (conversationId === this._streamingChatStore?._conversationStore?._selectedConversation?.id) {
            this.onNewChat()
            this._streamingChatStore.clearChat()
            this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)
            this._streamingChatStore.setNewChatAllowed(false)
        }
    }

    clear() {
        console.log('ChatStore.clear')
        this._streamingChatStore?.clearChat()
        this.setOpenClearDialog(false)
        this._streamingChatStore?.clear()
    }
}
