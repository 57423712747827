import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface AutoLogoutProps {
    children: React.ReactNode;
    timeout: number; // timeout in milliseconds
}

const AutoLogout: React.FC<AutoLogoutProps> = ({ children, timeout }) => {
    const [timer, setTimer] = useState<number>(timeout);
    const navigate = useNavigate();

    const resetTimer = () => {
        setTimer(timeout);
    };

    useEffect(() => {
        const events = ['click', 'keydown'];
        events.forEach(event => window.addEventListener(event, resetTimer));

        const interval = setInterval(() => {
            setTimer(prevTimer => {
                // console.log('prevTimer', prevTimer);
                if (prevTimer <= 0) {
                    clearInterval(interval);
                    navigate('/login'); // Redirect to login
                    return 0;
                }
                return prevTimer - 1000;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
            events.forEach(event => window.removeEventListener(event, resetTimer));
        };
    }, [navigate, timeout]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default AutoLogout;
