

import i18n from '../../../i18n';
import { SidebarSubMenuItem } from '../../../layouts/Dashboard/types';
import { Constants } from '../../../contants/Constants';
import ChatIcon from '@mui/icons-material/Chat';

export function chatMenuItemFactory(initFunction: any): SidebarSubMenuItem {
    return {
        text: i18n.t('sidebar.menuEntries.summaries'),
        icon: <ChatIcon />,
        route: Constants.Routes.Summaries,
        initFunction
    } as SidebarSubMenuItem;
}
