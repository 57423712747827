// NotFound.tsx
import React, { useContext, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { use } from 'i18next';
import UserStoreContext from '../../contexts/UserStoreProvider';

const NotFound: React.FC = () => {

    const navigate = useNavigate()
    const { userStore } = useContext(UserStoreContext)
    useEffect(() => {

        if (userStore !== undefined) {
            if (userStore.isLoggedIn) {
                navigate("/chat")
            } else {
                navigate("/login")
            }
        }
    }, [userStore])


    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                gap: 3
            }}
        >
            <Typography variant="h1" sx={{ color: 'red' }}>
                404
            </Typography>
            <Typography variant="h4">
                Oops! Page Not Found
            </Typography>
            <Typography variant="body1">
                The page you're looking for doesn't exist or has been moved.
            </Typography>
            <Button
                variant="outlined"
                component={Link}
                to="/login"
            >
                Go to Login
            </Button>
        </Container>
    );
}

export default NotFound;
