import React from 'react';
import { Box, ButtonBase, Grid, Paper, Skeleton, Typography } from '@mui/material';

import { keyframes } from "@emotion/react";
import { observer } from 'mobx-react';

export interface PromptSuggestion {
    title: string;
    description: string;
    prompt: string;
}

interface PromptSuggestionsProps {
    show?: boolean
    loading?: boolean
    promptSuggestions?: PromptSuggestion[]
    promptSelectionCallback?: (prompt: string) => void;
}

const PromptSuggestionView: React.FC<PromptSuggestionsProps> = observer(({ show, loading, promptSuggestions, promptSelectionCallback }) => {

    const handlePromptSelection = (prompt: string) => {
        promptSelectionCallback && promptSelectionCallback(prompt)
    };

    const pulsateAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    `;

    let examples = [1, 2, 3, 4]

    return (
        <>
            {show === true && promptSuggestions !== undefined && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        padding: '16px'
                    }}
                >
                    <Grid container spacing={1} justifyContent={'center'}>
                        {
                            promptSuggestions.map((promptSuggestion: PromptSuggestion, index: number) => {
                                return (
                                    < Grid key={`prompt-index- ${index}`} item xs={12} md={6}>
                                        <ButtonBase onClick={() => { handlePromptSelection(promptSuggestion.prompt) }} sx={{ m: 0, p: 0, width: '100%', height: '100%' }}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    p: 0, m: 1, borderRadius: 5, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',

                                                    transition: 'transform 0.3s',
                                                    '&:hover': {
                                                        transform: 'scale(1.02)',
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        p: 0, m: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                                                    }}>
                                                    <Box sx={{ p: 0, m: 1, height: "100%", display: 'flex', flexDirection: 'column' }}>
                                                        <Typography fontSize={14} textAlign={'left'} sx={{ m: 0, fontWeight: 'bold' }}>{promptSuggestion.title}</Typography>
                                                        <Typography fontSize={14} textAlign={'left'} sx={{}}>{promptSuggestion.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box >
            </Box>
            }
            {
                loading === true && <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        padding: '16px'
                    }}
                >
                    <Grid container spacing={1} justifyContent={'center'}>
                        {
                            examples.map((promptSuggestion: number, index: number) => {
                                return (
                                    < Grid key={`prompt-index- ${index}`} item xs={12} md={6}>
                                        <ButtonBase onClick={() => { }} sx={{ m: 0, p: 0, width: '100%', height: '100%' }}>
                                            <Skeleton variant="rounded" width={'100%'} height={100} />
                                        </ButtonBase>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box >
            }
        </>

    );
});

export {
    PromptSuggestionView,
    PromptSuggestionsProps
} 
