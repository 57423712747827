

import { createContext, FC } from "react";
import { DocumentChatStore } from "../stores/DocumentChatStore";

const DocumentChatStoreContext = createContext({ documentChatStore: undefined as DocumentChatStore | undefined })

interface DocumentChatStoreProviderProps {
    children?: React.ReactNode
    documentChatStore?: DocumentChatStore | undefined
}

export const DocumentChatStoreProvider: FC<DocumentChatStoreProviderProps> = ({ children, documentChatStore }) => {

    return (

        <DocumentChatStoreContext.Provider value={{ documentChatStore }}>
            {children}
        </DocumentChatStoreContext.Provider >
    )
}

export default DocumentChatStoreContext
