
import { action, makeAutoObservable, observable } from "mobx"
import { RootStore } from "./RootStore"
import { PromptSuggestion } from "../components/ChatBox/PromptSuggestions/PromptSuggestions"
import { promptSuggestionService } from "../services/PromptSuggestionService"

function sampleUnique<T>(arr: T[], n: number): T[] {
    if (n > arr.length) {
        throw new Error('Sample size is greater than array length.');
    }

    const shuffled = [...arr];
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // ES6 destructuring swap
    }

    return shuffled.slice(0, n);
}

export class PromptSuggestionsStore {

    rootStore: RootStore | undefined = undefined

    @observable
    _promptSuggestionVisible: boolean = true

    _availablePrompts: Array<PromptSuggestion> = []

    @observable
    _visiblePrompts: Array<PromptSuggestion> = []

    @observable
    _promptSuggestionsLoading: boolean = false

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)

        this._promptSuggestionVisible = true
        this._availablePrompts = []
        this.rootStore = rootStore
        this._promptSuggestionsLoading = false
        this.setPromptSuggestionsLoading(true)
    }

    get promptSuggestionsLoading() {
        return this._promptSuggestionsLoading
    }

    @action
    setPromptSuggestionsLoading(promptSuggestionsLoading: boolean) {
        this._promptSuggestionsLoading = promptSuggestionsLoading
    }

    get showPromptSuggestions() {
        return this._promptSuggestionVisible
    }

    @action
    setShowPromptSuggestions(showPromptSuggestions: boolean) {
        console.log('setShowPromptSuggestions', showPromptSuggestions)
        this._promptSuggestionVisible = showPromptSuggestions
    }

    get availablePrompts(): PromptSuggestion[] {
        return this._availablePrompts
    }

    set availablePrompts(availablePrompts: PromptSuggestion[]) {
        this._availablePrompts = availablePrompts
    }

    @action
    setVisiblePrompts(visiblePrompts: PromptSuggestion[]) {
        this._visiblePrompts = visiblePrompts
    }

    get visiblePrompts(): PromptSuggestion[] {
        return this._visiblePrompts
    }

    samplePrompts() {
        let sampledPrompts = sampleUnique<PromptSuggestion>(this.availablePrompts, 4)
        this.setVisiblePrompts(sampledPrompts)
    }

    async retrievePromptSuggestions(serviceName: string, language: string) {
        try {

            let token = await this.rootStore?.userStore?.getAccessToken()
            if (token) {
                console.log('retrievePromptSuggestions', token, serviceName, language, new Date().toISOString())
                let promptSuggestion = await promptSuggestionService.getPromptSuggestions(token, serviceName, language)
                this._availablePrompts = promptSuggestion

                this.samplePrompts()
            }
            this.setPromptSuggestionsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    clear() {
        console.log('PromptSuggestionStore.clear')
        this._availablePrompts = []
        this._visiblePrompts = []
        this._promptSuggestionVisible = true
    }
}