import { OptionsWithExtraProps, VariantType } from "notistack";

import { UIStore } from "./UIStore";

import { IUserStore } from "./interfaces/IUserStore";
import { IFeaturePlugin } from "../plugin/types";
import { IEventConnection } from "../utils/serverside/ConnectionInterfaces";

import { IServerConnectionHandler, SSEConnectionHandler } from "../utils/serverside/ServerConnectionHandler";
import { action, makeAutoObservable, observable } from "mobx";
import { IContentFilter } from "../AIShield/AIShieldTypes";
import { PassThroughContentFilter } from "../AIShield/PassThroughShield";
import { AIShield } from "../AIShield/AIShield";

import i18n from "../i18n";
import { env } from "../env";

export class RootStore {

    // chatStore: ChatStore;
    // documentChatStore: DocumentChatStore;
    uiStore: UIStore;
    _userStore: IUserStore | undefined = undefined;

    _eventConnection: IEventConnection | undefined = undefined

    _serverConnectionHandler: IServerConnectionHandler | undefined = undefined

    baseUrl: string | undefined

    _plugins: Array<IFeaturePlugin> = []

    @observable
    _activePlugin: IFeaturePlugin | undefined = undefined

    _notificationHandler: ((message: string, variant: OptionsWithExtraProps<VariantType>) => void) | undefined = undefined

    _contentFilter: IContentFilter = new PassThroughContentFilter()

    _pluginsInitialized: boolean = false

    @observable
    _termsofUseAccepted: boolean = false

    @observable
    _appReady: boolean = false

    @observable
    _appLoadingMessage: string | undefined

    @observable
    _appLoadingProgress: number

    @observable
    _invalidGroup: boolean = false

    constructor() {
        makeAutoObservable(this)
        // this.chatStore = new ChatStore(this)
        // this.documentChatStore = new DocumentChatStore(this)
        this.setAppLoadingMessage(i18n.t("loading.start"))
        this.uiStore = new UIStore(this)
        //this._userStore = new UserStoreMSAL(this)
        this._eventConnection = undefined
        this._appLoadingProgress = 0

        this._serverConnectionHandler = new SSEConnectionHandler()

        if (env.REACT_APP_SHOW_TERMS_OF_USE === 'true') {
            this._termsofUseAccepted = false
        } else {
            this._termsofUseAccepted = true
        }

        // if (isProduction === false) {
        //     try {
        //         let user_id = uuidv4()
        //         this.connectStreaming(user_id)
        //     } catch (error) {
        //         throw error
        //     }
        // }

        if (env.REACT_APP_ENABLE_CONTENT_FILTER === "true") {
            this._contentFilter = new AIShield()
        } else {
            this._contentFilter = new PassThroughContentFilter()
        }
    }

    get invalidGroup(): boolean {
        return this._invalidGroup
    }

    @action
    setInvalidGroup(invalidGroup: boolean) {
        this._invalidGroup = invalidGroup
    }

    get pluginsInitialized(): boolean {
        return this._pluginsInitialized
    }

    get termsofUseAccepted(): boolean {
        return this._termsofUseAccepted
    }

    @action
    setTermsOfUseAccepted(accepted: boolean) {
        this._termsofUseAccepted = accepted
        console.log("RootStore.setTermsOfUseAccepted", accepted)
    }

    get appReady(): boolean {
        return this._appReady
    }

    @action
    setAppReady(appReady: boolean) {
        console.log('setAppReady:', appReady)
        this._appReady = appReady
    }

    get appLoadingMessage(): string | undefined {
        return this._appLoadingMessage
    }

    @action
    setAppLoadingMessage(appLoadingMessage: string | undefined) {
        this._appLoadingMessage = appLoadingMessage
    }

    get appLoadingProgress(): number {
        return this._appLoadingProgress
    }

    @action
    setAppLoadingProgress(appLoadingProgress: number) {

        this._appLoadingProgress = appLoadingProgress
    }

    callback(value: number) {
        this.setAppLoadingProgress(value)
    }

    async initAIShield() {
        if (this._contentFilter) {
            this.setAppLoadingProgress(0)
            this.setAppLoadingMessage(i18n.t("loading.aishield", { appName: env.REACT_APP_NAME_DISPLAY }))
            await this._contentFilter.init(this.callback.bind(this))
            this.setAppLoadingMessage(i18n.t("loading.end"))
            this.setAppLoadingProgress(100)
            this.setAppReady(true)
        }
    }

    @action
    setActivePlugin(plugin: IFeaturePlugin) {
        this._activePlugin = plugin
    }

    get plugins(): Array<IFeaturePlugin> {
        return this._plugins
    }

    get activePlugin(): IFeaturePlugin | undefined {
        return this._activePlugin
    }

    get userStore(): IUserStore | undefined {
        return this._userStore
    }

    set userStore(userStore: IUserStore | undefined) {
        this._userStore = userStore
    }

    
    setPlugins(plugins: Array<IFeaturePlugin>) {
        this._plugins = plugins
    }

    set notificationHandler(notificationHandler: ((message: string, variant: OptionsWithExtraProps<VariantType>) => void) | undefined) {
        this._notificationHandler = notificationHandler
    }

    initializePlugins(plugins: Array<IFeaturePlugin>) {

        if (this._pluginsInitialized === false) {


            plugins.forEach((plugin: IFeaturePlugin) => {
                plugin.initialize(this)
            })

            console.log("_serverConnectionHandler", this._serverConnectionHandler)
            if (this._serverConnectionHandler) {
                this._serverConnectionHandler.plugins = this._plugins
            }
            //this.setActivePlugin(plugins[0])
            this._pluginsInitialized = true
        }
    }

    addFeaturePlugin(featurePlugin: IFeaturePlugin) {

        // check if plugin is already added
        let plugin = this._plugins.find((plugin) => {
            return plugin.name === featurePlugin.name
        })
        if (plugin === undefined) {
            this._plugins.push(featurePlugin)
        }

        if (this._serverConnectionHandler) {
            this._serverConnectionHandler.plugins = this._plugins
        }
    }

    notify(message: string, variant: OptionsWithExtraProps<VariantType>) {
        if (this._notificationHandler) {
            this._notificationHandler(message, variant)
        }
    }

    fireUpdateUI() {
        this.uiStore.setUpdateUI()
    }


    clear() {

        console.log('RootStore.clear')
        this._activePlugin = undefined

        this._notificationHandler = undefined
        this._pluginsInitialized = false

        this._plugins.forEach((plugin) => {
            if (plugin.featureStore) {
                plugin.featureStore.clear()
            }
        })

        this._plugins = []
        this._termsofUseAccepted = false
        this.uiStore.clear()
        this.userStore?.clear()
    }
}


