import { IEventConnection } from "./ConnectionInterfaces";
import { StreamingCommand } from "../WebSocketClient";

export class GlobalWebSocket implements IEventConnection {
    private static instances: Record<string, GlobalWebSocket> = {};
    private websocket: WebSocket | null = null;
    private _url: string = ''

    private _onMessage: ((data: string) => void) | undefined = undefined

    private constructor(private url: string) {
        this._url = url
    }

    public static getInstance(url: string): GlobalWebSocket {
        if (!GlobalWebSocket.instances[url]) {
            GlobalWebSocket.instances[url] = new GlobalWebSocket(url);
        }
        return GlobalWebSocket.instances[url];
    }

    set onMessage(onMessage: ((data: string) => void) | undefined) {
        this._onMessage = onMessage
    }

    isConnectionOpen() {
        if (this.websocket !== null) {
            return this.websocket.readyState === WebSocket.OPEN
        }
        return false
    }

    connect() {
        this.websocket = new WebSocket(this.url);
        // Bind the context of class methods to the class instance

        this.websocket.addEventListener('open', this.handleSocketOpen.bind(this));
        this.websocket.addEventListener('message', this.handleSocketMessage.bind(this));
        this.websocket.addEventListener('close', this.handleSocketClose.bind(this));
    }

    public sendCommand(command: StreamingCommand) {
        if (this.websocket !== null) {
            console.log('sending command')
            console.log(command)
            this.websocket.send(JSON.stringify(command));
        }
    }

    send(data: string) {
        this.websocket?.send(data);
    }

    onmessage(handler: (event: MessageEvent) => void) {
        if (this.websocket) {
            this.websocket.onmessage = handler;
        }
    }

    disconnect() {
        this.websocket?.close();
    }

    private handleSocketOpen(event: Event) {
        console.log('WebSocket connection established');
    }

    private handleSocketMessage(event: MessageEvent) {
        const receivedData: string = event.data;

        const parsedData = JSON.parse(receivedData);

        if (this._onMessage) {
            this._onMessage(receivedData)
        }
    }

    private handleSocketClose(event: CloseEvent) {
        console.log('WebSocket connection closed');
    }


}