import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DocumentUploadDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: DocumentUploadValues) => void;
    fieldConfig: FieldConfig;
}

export interface DocumentUploadValues {
    title?: string;
    description?: string;
    filename?: string;
    publicUrl: string;
}

export interface FieldConfig {
    showTitle?: boolean;
    showDescription?: boolean;
    showFilename?: boolean;
}

const DocumentUploadDialog: React.FC<DocumentUploadDialogProps> = ({ open, onClose, onSubmit, fieldConfig }) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object({
        title: fieldConfig.showTitle ? Yup.string().required(t('feature.docChat.documentUploadDialog.documentTitleRequired')) : Yup.string(),
        description: fieldConfig.showDescription ? Yup.string().required(t('feature.docChat.documentUploadDialog.descriptionRequired')) : Yup.string(),
        filename: fieldConfig.showFilename ? Yup.string().required(t('feature.docChat.documentUploadDialog.filenameRequired')) : Yup.string(),
        // The regular expression /^https:\/\/.+/ ensures an url that starts with https.
        publicUrl: Yup.string()
            .matches(/^https:\/\/.+$/, t('feature.docChat.documentUploadDialog.validHttpsUrlRequired'))
            .url(t('feature.docChat.documentUploadDialog.validUrlRequired'))
            .required(t('feature.docChat.documentUploadDialog.publicUrlRequired')),
    });

    const formik = useFormik<DocumentUploadValues>({
        initialValues: {
            title: '',
            description: '',
            filename: '',
            publicUrl: '',
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
            onClose();
        },
    });

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="document-upload-dialog-title">
            <DialogTitle id="document-upload-dialog-title">{t('feature.docChat.documentUploadDialog.title')}</DialogTitle>

            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        {fieldConfig.showTitle && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="title"
                                    name="title"
                                    label={t('feature.docChat.documentUploadDialog.labelTitle')}
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                        )}
                        {fieldConfig.showDescription && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="description"
                                    name="description"
                                    label={t('feature.docChat.documentUploadDialog.labelDescription')}
                                    multiline
                                    rows={4}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </Grid>
                        )}
                        {fieldConfig.showFilename && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="filename"
                                    name="filename"
                                    label={t('feature.docChat.documentUploadDialog.labelFilename')}
                                    value={formik.values.filename}
                                    onChange={formik.handleChange}
                                    error={formik.touched.filename && Boolean(formik.errors.filename)}
                                    helperText={formik.touched.filename && formik.errors.filename}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="publicUrl"
                                name="publicUrl"
                                label={t('feature.docChat.documentUploadDialog.labelPublicUrl')}
                                value={formik.values.publicUrl}
                                onChange={formik.handleChange}
                                error={formik.touched.publicUrl && Boolean(formik.errors.publicUrl)}
                                helperText={formik.touched.publicUrl && formik.errors.publicUrl}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onClose} color="primary">
                        {t('feature.docChat.documentUploadDialog.buttonCancel')}
                    </Button>
                    <Button variant="contained" type="submit" color="secondary">
                        {t('feature.docChat.documentUploadDialog.buttonUpload')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DocumentUploadDialog;