

import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';

interface CircularProgressBarProps {
    percentage: number;
    filename?: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = (
    { percentage, filename }
) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={60} thickness={5} variant="determinate" value={percentage} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="body1"
                    component="div"
                    color="text.secondary"
                >{`${percentage}%`}</Typography>
            </Box>
        </Box>
    );
}

export default observer(CircularProgressBar);