import axios, { AxiosInstance } from 'axios';
import { ModelUsageInfo, SelectableModel } from '../types/types_api';
import { env } from '../env';

class ModelInfoService {

    private client: AxiosInstance;

    constructor(baseURL: string, service: string = 'info') {

        this.client = axios.create({ baseURL: baseURL + `/api/${service}` });
    }

    async getAvailableModels(token?: string): Promise<SelectableModel[]> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        const response = await this.client.get<SelectableModel[]>(`/models`, config);
        console.log(response.data);
        return response.data as SelectableModel[];
    }

    async getModelSpecificInfo(modelId: string, token?: string): Promise<ModelUsageInfo> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        const response = await this.client.get<ModelUsageInfo>(`/models/${modelId}`, config);
        // console.log(response.data);
        return response.data as ModelUsageInfo;
    }
}

export const modelInfoService = new ModelInfoService(
    env.REACT_APP_BACKEND_URL ?? "http://localhost:3001",
    "info"
);
