import { AxiosError } from "axios"

class ErrorHandler {
    private _navigate: ((path: string) => void) | undefined;

    public setNavigator(navigateFunction: (path: string) => void): void {
        this._navigate = navigateFunction;
    }

    public handle(error: AxiosError): void {
        const response = error?.response;
        const request = error?.request;

        if (error.code === 'ERR_NETWORK') {
            console.log('Connection problems..');
        } else if (error.code === 'ERR_CANCELED') {
            console.log('Connection canceled..');
        }

        if (response) {
            const statusCode = response?.status;
            if (statusCode === 404) {
                console.log('The requested resource does not exist or has been deleted');
            } else if (statusCode === 401) {
                console.log('Unauthorized access - please login to access this resource');
                // Redirect user to login if navigate is set
                if (this._navigate) {
                    this._navigate('/login');
                } else {
                    console.error('Navigate function is not set.');
                }
            }
        } else if (request) {
            console.log('The request was made but no response was received');
        } else {
            console.log('An error occurred in setting up the request');
        }
    }
}

let errorHandler = new ErrorHandler();

export default errorHandler;