
import React from 'react';
import { DocumentChatStore } from '../stores/DocumentChatStore';
import { DocumentChatStoreProvider } from '../context/DocumentChatProvider';
import { DocumentChatView } from './DocumentChatBoxView/DocumentChatView';
import { SideBarView } from '../../../types/UITypes';
import { Box } from '@mui/system';
import ChatHistory from '../../../components/ChatHistory/ChatHistory';
import { QuickActions } from '../../../types/ChatTypes';

export function documentChatFeatureNode(chatStore: DocumentChatStore,
    quickActions: QuickActions[],
    onFeedbackThumbUp?: (messageId: string) => void,
    onFeedbackThumbDown?: (messageId: string) => void): React.ReactNode {

    return (
        <DocumentChatStoreProvider documentChatStore={chatStore}>
            <DocumentChatView 
                onFeedbackThumbUp={onFeedbackThumbUp}
                onFeedbackThumbDown={onFeedbackThumbDown} />
        </DocumentChatStoreProvider>
    );
}

export function chatHistorySidebarElement(title: string, chatStore: DocumentChatStore,
    onConversationSelected: (converstationId: string) => void,
    onConversationDeleted: (conversationId: string) => void,
    onConversationTitleChanged: (conversationId: string, title: string) => void): SideBarView {

    return {
        title: title,
        icon: "settings",
        sidebarElement: (<DocumentChatStoreProvider documentChatStore={chatStore}>
            <Box display={'flex'} flexDirection={'column'}  >
                {chatStore._streamingChatStore._conversationStore !== undefined &&
                    <ChatHistory
                        loading={chatStore._streamingChatStore._conversationStore.conversationsLoading}
                        conversations={chatStore._streamingChatStore._conversationStore.currentConversations}
                        onConversationSelected={onConversationSelected}
                        onConversationDeleted={onConversationDeleted}
                        onConversationTitleChanged={onConversationTitleChanged} />}
            </Box>
        </DocumentChatStoreProvider>)
    } as SideBarView;
}