import React from 'react';
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ChatBox from '../../../../components/ChatBox/ChatBox';
import { MessageInput } from '../../../../components/ChatBox/MessageInput/MessageInput';
import { YesNoDialog } from '../../../../components/YesNoDialog/YesNoDialog';
import { useDocumentChatStore } from '../../hooks/useDocumentChatStore';
import AttentionBanner from '../../../../components/AttentionBanner';
import { HandleMesssageResult } from '../../../../stores/BaseStreamingChatStore';


import ModelSelectionBanner from '../../../../components/ModelSelectionBanner';
import { DocumentChatInplaceMenuItems } from '../DocumentChatInplaceMenu/DocumentChatInplaceMenu';
import { SelectableModel } from '../../../../types/types_api';
import { ContentStatus } from '../../../../AIShield/AIShieldTypes';
import { PromptSuggestionView } from '../../../../components/ChatBox/PromptSuggestions/PromptSuggestions';
import CircularProgressBar from '../../../../components/CircularProgressBar/CircularProgressBar';
import DocumentUploadDialog from '../../../../components/DocumentDialog';
import { DocumentUploadValues } from '../../../../components/DocumentDialog/DocumentDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { env } from '../../../../env';
import AttachmentViewSmall from '../../../../components/AttachmentView/AttachmentViewSmall/AttachmentViewSmall';


interface DocumentChatViewProps {
    
    onFeedbackThumbUp?(messageId: string): void
    onFeedbackThumbDown?(messageId: string): void
}

const DocumentChatView: React.FC<DocumentChatViewProps> = observer(({
    
    onFeedbackThumbUp,
    onFeedbackThumbDown }) => {

    const [contentStatus, setContentStatus] = React.useState<ContentStatus>(ContentStatus.Okay);
    // const [containsCritical, setContainsCritical] = React.useState(false);
    const [contentWarningCategories, setContentWarningCategories] = React.useState<string[] | undefined>();
    const [entities, setEntities] = React.useState<string[] | undefined>();

    const { t, i18n } = useTranslation();
    const documentChatStore = useDocumentChatStore()

    // const [ actions ] = useDocumentChat({docChatStore: documentChatStore})
    // console.log("actions", actions)

    const handleClose = () => {
        documentChatStore.setOpenContentWarningDialog(false);
    };

    const handleSubmit = () => {
        if (documentChatStore._streamingChatStore.initialMessage !== undefined) {
            let updateAIShieldEvent = true
            documentChatStore.setOpenContentWarningDialog(false);
            documentChatStore.sendMessage(documentChatStore._streamingChatStore.initialMessage, 
                updateAIShieldEvent)
            documentChatStore._streamingChatStore.setInitialMessage('')
        }
    };
    const onCopyToClipboard = (message: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(message)
        }
    }

    const handleInplaceMenuSelectionSelection = (selection: string) => {
        documentChatStore.handleInplaceMenuSelection(selection)
    }

    const terms_of_use_short_url = decodeURIComponent(env.REACT_APP_TERMS_OF_SERVICE_SHORT !== undefined ? env.REACT_APP_TERMS_OF_SERVICE_SHORT : '')

    console.log("documentChatStore.inputfield?", documentChatStore._streamingChatStore.inputFieldDisabled)
    console.log("currentDoc Conversation", documentChatStore._streamingChatStore.conversationStore?._selectedConversation)
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', overflow: 'hidden' }}>
                <Divider sx={{ width: '100%' }} orientation='horizontal'></Divider>

                {/* model selection */}
                <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    {documentChatStore._streamingChatStore.modelSelectionStore?.modelSelectionVisible === true &&
                        <ModelSelectionBanner
                            modelSelectionVisible={documentChatStore._streamingChatStore.modelSelectionStore.modelSelectionVisible}
                            modelSelectionTitle={t('header.availableModels')}
                            labelSelectModel={t("header.labelSelectModel")}
                            availableModels={documentChatStore._streamingChatStore.modelSelectionStore.availableModels}
                            handleModelSelection={(selectedModel: SelectableModel) => {
                                console.log("modelId", selectedModel.id)
                                documentChatStore._streamingChatStore.modelSelectionStore?.setCurrentModel(selectedModel)
                            }} />}

                    {documentChatStore._streamingChatStore.modelSelectionStore?.modelSelectionVisible === false &&
                        documentChatStore._streamingChatStore.modelSelectionStore?._currentModel &&
                        <Box display='flex' flexDirection={'row'} sx={{ m: 1 }}>
                            <Typography sx={{ml: 2}} component="span" fontWeight={'bold'} >
                                {t('header.selectedModel')}&nbsp;
                            </Typography>
                            <Typography component="span" color="primary" fontWeight={'bold'}>
                                {documentChatStore._streamingChatStore.modelSelectionStore?._currentModel.name}
                            </Typography>
                        </Box>
                    }

                    {documentChatStore._streamingChatStore.conversationStore?._selectedConversation?.attachments !== undefined &&
                        documentChatStore._streamingChatStore.conversationStore?._selectedConversation?.attachments !== null && 
                        documentChatStore._streamingChatStore.conversationStore?._selectedConversation?.attachments.length > 0 &&
                        
                        <AttachmentViewSmall attachments={documentChatStore._streamingChatStore.conversationStore?._selectedConversation?.attachments} />
                    }
                </Box>
                <Box sx={{ flex: 1, overflowY: 'auto', mb: "100px" }}>

                    <ChatBox currentMessage={documentChatStore._streamingChatStore.currentMessage}
                        messages={documentChatStore._streamingChatStore.messagesUI}
                        currentTokenList={documentChatStore._streamingChatStore.currentStreamingTokens}
                        onCopyToClipboard={onCopyToClipboard}
                        onFeedbackThumbDown={onFeedbackThumbDown}
                        onFeedbackThumbUp={onFeedbackThumbUp}
                        showFeedBackElement={false} />
                </Box>

                <Box sx={{ position: 'absolute', bottom: '16px', left: '10%', width: '80%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Stack direction={"column"} spacing={2} alignItems={"center"}>

                            {documentChatStore.inplaceMenuVisible && <DocumentChatInplaceMenuItems
                                handleInplaceMenuSelectionSelection={handleInplaceMenuSelectionSelection} />}

                            <PromptSuggestionView

                                show={documentChatStore._streamingChatStore._promptSuggestionsStore?.showPromptSuggestions}
                                loading={documentChatStore._streamingChatStore._promptSuggestionsStore?.promptSuggestionsLoading}
                                promptSuggestions={documentChatStore._streamingChatStore._promptSuggestionsStore?.visiblePrompts}
                                promptSelectionCallback={documentChatStore.promptSelectionCallback.bind(documentChatStore)} />

                            {documentChatStore.documentPreProcessingVisible && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', m: 1 }}>
                                        <Typography sx={{ m: 1 }} variant={'body1'}>{t('feature.docChat.userMessages.documentPreProcessing')}</Typography>
                                        <CircularProgress />
                                    </Box>

                                </Box>
                            </Box>}

                            {documentChatStore.documentProcessingVisible && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', m: 1 }}>
                                        <Typography variant={'body1'}>{t('feature.docChat.userMessages.documentProcessing')}</Typography>
                                        <Typography variant={'body2'}>{t('feature.docChat.userMessages.documentProcessingSub')}</Typography>
                                    </Box>
                                    <CircularProgressBar percentage={documentChatStore.documentProcessingPercentage} />
                                </Box>
                            </Box>}

                            {documentChatStore.uploadInProgress && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', m: 1 }}>
                                        <Typography sx={{ m: 1 }} variant={'body1'}>{t('feature.docChat.userMessages.documentUploading')}</Typography>
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            </Box>}

                            <MessageInput onSendMessage={async (message: string) => {
                                documentChatStore._streamingChatStore.modelSelectionStore?.setModelSelectionVisible(false)
                                let result: HandleMesssageResult = await documentChatStore.checkContent(message)
                                if (result.contentStatus === ContentStatus.Stop) {
                                    setContentWarningCategories(result.filterCategories)
                                    setEntities(result.entities)
                                    setContentStatus(result.contentStatus)
                                    documentChatStore._streamingChatStore.setInitialMessage(result.message)
                                    documentChatStore.setOpenContentWarningDialog(true)

                                } else if ( result.contentStatus === ContentStatus.Warning ) {
                                    setContentWarningCategories(result.filterCategories)
                                    setEntities(result.entities)
                                    setContentStatus(result.contentStatus)
                                    documentChatStore._streamingChatStore.setInitialMessage(result.message)
                                    documentChatStore.setOpenContentWarningDialog(true)

                                } else if (result.contentStatus === ContentStatus.Okay ) {
                                    documentChatStore._streamingChatStore.setInitialMessage('')
                                    documentChatStore.sendMessage(message)
                                    setContentStatus(result.contentStatus)
                                } else {
                                    
                                }
                            }}
                                textfieldTitle={t("feature.docChat.textInputFieldString")}
                                initialMessage={documentChatStore._streamingChatStore.initialMessage}
                                setInitialMessage={documentChatStore._streamingChatStore.setInitialMessage.bind(documentChatStore._streamingChatStore)}
                                quickActions={documentChatStore.quickActions}
                                requestInProgress={documentChatStore._streamingChatStore.requestInProgress}
                                inputIcon={<AttachFileIcon />}
                                disabled={documentChatStore._streamingChatStore.inputFieldDisabled || documentChatStore._streamingChatStore.requestInProgress}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Box>

            <AttentionBanner
                open={documentChatStore.openContentWarningDialog}
                headerText={contentStatus === ContentStatus.Warning ? t('contentWarning.title') : t("contentWarningCritical.title")}
                contentText={contentStatus === ContentStatus.Warning ? t('contentWarning.message', { terms_of_use_short: terms_of_use_short_url }) : t("contentWarningCritical.message", { names: entities !== undefined ? entities.join('') : '', terms_of_use_short: terms_of_use_short_url })}
                categoryTitle={t('contentWarning.affectedCategories')}

                categories={contentWarningCategories}
                yesButton={t('contentWarning.buttonSubmitAnyway')}
                noButton={contentStatus === ContentStatus.Warning ? t('contentWarning.buttonCancel') : t('contentWarning.buttonConfirm')}
                severity='error'
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                showSubmitAnyway={contentStatus === ContentStatus.Warning} />

            <YesNoDialog open={documentChatStore.openClearDialog}
                title={t('feature.docChat.dialogClearChatHistory.title')}
                content={t('feature.docChat.dialogClearChatHistory.message')}
                yesButton={t('feature.docChat.dialogClearChatHistory.buttonConfirm')}
                noButton={t('feature.docChat.dialogClearChatHistory.buttonCancel')}
                onYes={documentChatStore.handleClearChatYes.bind(documentChatStore)}
                onNo={documentChatStore.handleClearChatNo.bind(documentChatStore)}
                colorButtonNo />

            <YesNoDialog open={documentChatStore.openDialogDeleteDocument}
                title={t('feature.docChat.dialogDeleteDocument.title')}
                content={t('feature.docChat.dialogDeleteDocument.message')}
                yesButton={t('feature.docChat.dialogDeleteDocument.buttonConfirm')}
                noButton={t('feature.docChat.dialogDeleteDocument.buttonCancel')}
                onYes={documentChatStore.handleDeleteDocumentYes.bind(documentChatStore)}
                onNo={documentChatStore.handleDeleteDocumentNo.bind(documentChatStore)}
                colorButtonNo />

            <DocumentUploadDialog open={documentChatStore.openDialogDocumentFromUrl}
                onClose={documentChatStore.openDialogDocumentFromUrlCancel.bind(documentChatStore)}
                onSubmit={(values: DocumentUploadValues) => {
                    documentChatStore.openDialogDocumentFromUrlSubmit(values.publicUrl)
                    documentChatStore.setDocumentTitle(values.title ? values.title : '')
                }}
                fieldConfig={{
                    showTitle: true
                }}
            />
            {/* <TextFieldDialog open={documentChatStore.openDialogDocumentFromUrl}
                title={t('feature.docChat.openDialogDocumentFromUrl.title')}
                content={t('feature.docChat.openDialogDocumentFromUrl.message')}
                labelUrl={t('feature.docChat.openDialogDocumentFromUrl.labelUrl')}
                submitButton={t('feature.docChat.openDialogDocumentFromUrl.buttonConfirm')}
                cancelButton={t('feature.docChat.openDialogDocumentFromUrl.buttonCancel')}
                onSubmit={documentChatStore.openDialogDocumentFromUrlSubmit.bind(documentChatStore)}
                onCancel={documentChatStore.openDialogDocumentFromUrlCancel.bind(documentChatStore)}
            /> */}

        </ >
    );
})

export { DocumentChatView, DocumentChatViewProps };