import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ChatBox from '../../../../components/ChatBox/ChatBox';
import { PromptSuggestionView } from '../../../../components/ChatBox/PromptSuggestions/PromptSuggestions';
import { MessageInput } from '../../../../components/ChatBox/MessageInput/MessageInput';

import { useChatStore } from '../../hook/useChatStore';
import { QuickActions } from '../../../../types/ChatTypes';
import AttentionBanner from '../../../../components/AttentionBanner';
import { HandleMesssageResult } from '../../../../stores/BaseStreamingChatStore';
import ModelSelectionBanner from '../../../../components/ModelSelectionBanner';
import { SelectableModel } from '../../../../types/types_api';
import { ContentStatus } from '../../../../AIShield/AIShieldTypes';
import { YesNoDialog } from '../../../../components/YesNoDialog/YesNoDialog';
import { env } from '../../../../env';


/**
 * Description placeholder
 * @date 25/09/2023 - 08:07:06
 *
 * @interface ChatBoxViewProps
 * @typedef {ChatBoxViewProps}
 */
interface ChatBoxViewProps {
    quickActions: QuickActions[]
    onFeedbackThumbUp?(messageId: string): void
    onFeedbackThumbDown?(messageId: string): void
}

/**
 * Description placeholder
 * @date 25/09/2023 - 08:06:53
 *
 * @type {React.FC<ChatBoxViewProps>}
 */
const ChatBoxView: React.FC<ChatBoxViewProps> = observer(({
    quickActions,
    onFeedbackThumbUp,
    onFeedbackThumbDown
}) => {

    const { t, i18n } = useTranslation();
    const chatStore = useChatStore()

    const [contentStatus, setContentStatus] = React.useState<ContentStatus>(ContentStatus.Okay);
    // const [containsCritical, setContainsCritical] = React.useState(false);
    const [contentWarningCategories, setContentWarningCategories] = React.useState<string[] | undefined>();
    const [entities, setEntities] = React.useState<string[] | undefined>();


    const handleClose = () => {
        chatStore.setOpenContentWarningDialog(false);
    };

    const handleSubmit = () => {
        if (chatStore._streamingChatStore.initialMessage !== undefined) {
            chatStore.setOpenContentWarningDialog(false);
            let updateAIShieldEvent = true
            chatStore.sendMessage(chatStore._streamingChatStore.initialMessage, undefined, updateAIShieldEvent)
            chatStore._streamingChatStore.setInitialMessage('')
        }
    };

    const onCopyToClipboard = (message: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(message)
        }
    }

    const terms_of_use_short_url = decodeURIComponent(env.REACT_APP_TERMS_OF_SERVICE_SHORT !== undefined ? env.REACT_APP_TERMS_OF_SERVICE_SHORT : '')

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', overflow: 'hidden' }}>
                <Divider sx={{ width: '100%' }} orientation='horizontal'></Divider>
                <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    {/* {chatStore._streamingChatStore?.modelSelectionStore?.modelSelectionVisible === true &&
                        <ModelSelectionBanner
                            modelSelectionVisible={chatStore._streamingChatStore?.modelSelectionStore.modelSelectionVisible}
                            modelSelectionTitle={t('header.availableModels')}
                            labelSelectModel={t("header.labelSelectModel")}
                            availableModels={chatStore._streamingChatStore?.modelSelectionStore.availableModels}
                            handleModelSelection={(selectedModel: SelectableModel) => {
                                console.log("model", selectedModel.id)
                                chatStore._streamingChatStore?.modelSelectionStore?.setCurrentModel(selectedModel)
                            }} />}
                    {chatStore._streamingChatStore?.modelSelectionStore?.modelSelectionVisible === false &&
                        chatStore._streamingChatStore?.modelSelectionStore?.currentModel &&
                        <div>{t('header.selectedModel')}{chatStore._streamingChatStore?.modelSelectionStore?.currentModel.name}</div>} */}

                    {chatStore._streamingChatStore.modelSelectionStore?.modelSelectionVisible === true &&
                        <ModelSelectionBanner
                            modelSelectionVisible={chatStore._streamingChatStore.modelSelectionStore.modelSelectionVisible}
                            modelSelectionTitle={t('header.availableModels')}
                            labelSelectModel={t("header.labelSelectModel")}
                            availableModels={chatStore._streamingChatStore.modelSelectionStore.availableModels}
                            handleModelSelection={(selectedModel: SelectableModel) => {
                                console.log("modelId", selectedModel.id)
                                chatStore._streamingChatStore.modelSelectionStore?.setCurrentModel(selectedModel)
                            }} />}

                    {chatStore._streamingChatStore.modelSelectionStore?.modelSelectionVisible === false &&
                        chatStore._streamingChatStore.modelSelectionStore?._currentModel &&
                        <Box display='flex' flexDirection={'row'} sx={{ m: 1 }}>
                            <Typography sx={{ml: 2}} component="span" fontWeight={'bold'} >
                                {t('header.selectedModel')}&nbsp;
                            </Typography>
                            <Typography component="span" color="primary" fontWeight={'bold'}>
                                {chatStore._streamingChatStore.modelSelectionStore?._currentModel.name}
                            </Typography>
                        </Box>
                    }
                </Box>

                <Box sx={{ flex: 1, mb: '100px', overflowY: 'auto' }}>
                    <ChatBox currentMessage={chatStore._streamingChatStore?.currentMessage}
                        messages={chatStore._streamingChatStore?.messagesUI}
                        currentTokenList={chatStore._streamingChatStore?.currentStreamingTokens}
                        onCopyToClipboard={onCopyToClipboard}
                        onFeedbackThumbDown={onFeedbackThumbDown}
                        onFeedbackThumbUp={onFeedbackThumbUp}
                        showFeedBackElement={false} />
                </Box>

                <Box sx={{ position: 'absolute', bottom: '16px', left: '10%', width: '80%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Stack direction={"column"} spacing={2} alignItems={"center"}>
                            <PromptSuggestionView
                                show={chatStore._streamingChatStore._promptSuggestionsStore?.showPromptSuggestions}
                                loading={chatStore._streamingChatStore._promptSuggestionsStore?.promptSuggestionsLoading}
                                promptSuggestions={chatStore._streamingChatStore._promptSuggestionsStore?.visiblePrompts}
                                promptSelectionCallback={chatStore._streamingChatStore.setInitialMessage.bind(chatStore._streamingChatStore)} />

                            <MessageInput onSendMessage={async (message: string) => {
                                chatStore._streamingChatStore.modelSelectionStore?.setModelSelectionVisible(false)
                                let result: HandleMesssageResult = await chatStore.checkContent(message)
                                if (result.contentStatus === ContentStatus.Stop) {
                                    setContentWarningCategories(result.filterCategories)
                                    setEntities(result.entities)
                                    setContentStatus(result.contentStatus)
                                    chatStore._streamingChatStore.setInitialMessage(result.message)
                                    chatStore.setOpenContentWarningDialog(true)

                                } else if ( result.contentStatus === ContentStatus.Warning ) {
                                    setContentWarningCategories(result.filterCategories)
                                    setEntities(result.entities)
                                    setContentStatus(result.contentStatus)
                                    chatStore._streamingChatStore.setInitialMessage(result.message)
                                    chatStore.setOpenContentWarningDialog(true)

                                } else if (result.contentStatus === ContentStatus.Okay ) {
                                    chatStore._streamingChatStore.setInitialMessage('')
                                    chatStore.sendMessage(message)
                                    setContentStatus(ContentStatus.Okay)

                                } else {
                                    
                                }
                            }}
                                textfieldTitle={t("feature.chat.textInputFieldString")}
                                initialMessage={chatStore._streamingChatStore.initialMessage}
                                setInitialMessage={chatStore._streamingChatStore.setInitialMessage.bind(chatStore._streamingChatStore)}
                                quickActions={quickActions}
                                requestInProgress={chatStore._streamingChatStore.requestInProgress}
                                disabled={chatStore._streamingChatStore.requestInProgress}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Box>

            <AttentionBanner
                open={chatStore.openContentWarningDialog}
                headerText={contentStatus === ContentStatus.Warning ? t('contentWarning.title') : t("contentWarningCritical.title")}
                contentText={contentStatus === ContentStatus.Warning ? t('contentWarning.message', { terms_of_use_short: terms_of_use_short_url }) : t("contentWarningCritical.message", { names: entities !== undefined ? entities.join('') : '', terms_of_use_short: terms_of_use_short_url })}
                categoryTitle={t('contentWarning.affectedCategories')}

                categories={contentWarningCategories}
                yesButton={t('contentWarning.buttonSubmitAnyway')}
                noButton={contentStatus === ContentStatus.Warning ? t('contentWarning.buttonCancel') : t('contentWarning.buttonConfirm')}
                severity='error'
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                showSubmitAnyway={contentStatus === ContentStatus.Warning} />

            <YesNoDialog open={chatStore.openClearDialog}
                title={t('feature.chat.dialogClearChatHistory.title')}
                content={t('feature.chat.dialogClearChatHistory.message')}
                yesButton={t('feature.chat.dialogClearChatHistory.buttonConfirm')}
                noButton={t('feature.chat.dialogClearChatHistory.buttonCancel')}
                onYes={chatStore.handleClearChatYes.bind(chatStore)}
                onNo={chatStore.handleClearChatNo.bind(chatStore)}
                colorButtonNo={true} />
        </ >
    );
})

export { ChatBoxView, ChatBoxViewProps };