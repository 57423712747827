import React, { Fragment, useEffect, useRef } from 'react';
import { Avatar, Box, Card, Typography, useTheme, CardActions, IconButton } from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';

import { styled } from '@mui/system';
import { format } from 'date-fns-tz';
import { ChatMessageProps } from '../../../types/ChatTypes';
import ReactMarkdown from 'react-markdown';

import rehypeHighlight from 'rehype-highlight';

import 'highlight.js/styles/default.css';

const BarMessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}));

const BarMessageContent = styled(Box)(({ theme }) => ({
    borderRadius: '16px',
    padding: theme.spacing(1, 2),
    paddingBottom: 1,
    maxWidth: '70%',
    wordWrap: 'break-word',
}));

interface BarChatMessageProps extends ChatMessageProps {
    showFeedback: boolean;
    onCopyToClipboard?: (message: string) => void,
    onFeedbackThumbUp?: (messageId: string) => void,
    onFeedbackThumbDown?: (messageId: string) => void
    answerAttachment?: React.ReactNode;
}

const BarChatMessage: React.FC<BarChatMessageProps> = ({
    message,
    id,
    sender,
    timestamp,
    avatarUrl,
    isUserMessage,
    feedback,
    scrollable = false,
    showFeedback = false,
    onCopyToClipboard,
    onFeedbackThumbUp,
    onFeedbackThumbDown,
    answerAttachment
}) => {

    const theme = useTheme()
    const endOfMessagesRef = useRef<HTMLElement | null>(null);
    const timeConversion = (timestamp: string): string => {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the local time zone
        const date = new Date(timestamp);
        const formattedTime = format(date, 'HH:mm', { timeZone });
        return formattedTime;
    }



    const newMessage = message.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );

    const extractCodeAndText = (message: string) => {
        const sections: { type: 'code' | 'text', content: string[] }[] = [];

        let isCodeSection = false;
        let currentSection: string[] = [];
        let currentLine = "";

        for (let i = 0; i < message.length; i++) {
            if (message.substr(i, 3) === '```') {
                // Finish the current line and section
                if (currentLine) {
                    currentSection.push(currentLine);
                }
                if (currentSection.length) {
                    sections.push({
                        type: isCodeSection ? 'code' : 'text',
                        content: currentSection
                    });
                }

                // Toggle code section flag
                isCodeSection = !isCodeSection;

                // Skip next two characters
                i += 2;

                // Reset current section and line content
                currentSection = [];
                currentLine = "";
            } else if (message[i] === '\n') {
                // Finish the current line
                currentSection.push(currentLine);
                currentLine = "";
            } else {
                currentLine += message[i];
            }
        }

        // Handling edge cases (e.g., closing triple backticks are not present)
        if (currentLine) {
            currentSection.push(currentLine);
        }
        if (currentSection.length) {
            sections.push({
                type: isCodeSection ? 'code' : 'text',
                content: currentSection
            });
        }

        return sections;
    }


    useEffect(() => {
        if (scrollable) {
            if (endOfMessagesRef.current) {
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [message, scrollable]);  // Assuming `messages` is a prop that gets updated when a new message is added


    const sections = extractCodeAndText(message);
    //console.log(sections)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                backgroundColor: isUserMessage ? theme.palette.grey[200] : theme.palette.grey[100],
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: 400, // set width for xs screen size
                        sm: 500, // set width for sm screen size
                        md: 720, // set width for md screen size
                    },
                    backgroundColor: 'red',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                    }}
                >
                    <BarMessageContainer sx={{
                        flexDirection: 'row',
                        backgroundColor: isUserMessage ? theme.palette.grey[200] : theme.palette.grey[100],
                    }}>
                        <Avatar variant={isUserMessage === true ? "circular" : "square"} alt={sender} src={avatarUrl} sx={{ margin: 2 }} />
                        <Card elevation={0} sx={{
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            flexGrow: 1,
                            backgroundColor: isUserMessage ? theme.palette.grey[200] : theme.palette.grey[100],
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <BarMessageContent>



                                    {sections.map((section, sectionIndex) => {
                                        // console.log(`\`\`\`${section.content[0]}\n${section.content.slice(1).join('\n')}\n\`\`\``)
                                        return (

                                            <>
                                                {
                                                    section.type === 'code' ? (
                                                        <Box key={sectionIndex} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                            <Box
                                                                component="div"
                                                                textAlign="left"
                                                                bgcolor="background.paper"
                                                                padding="1em"
                                                                margin="0.5em 0"
                                                                borderRadius="5px"
                                                                boxShadow={1}

                                                                sx={{ flexGrow: 1, width: '100%', overflow: "auto" }}
                                                            >
                                                                <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
                                                                    {`\`\`\`${section.content[0]}\n${section.content.slice(1).join('\n')}\n\`\`\``}
                                                                </ReactMarkdown>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        section.content.map((line, lineIndex) => (
                                                            <Typography key={lineIndex} sx={{ m: 0 }} fontSize={16} variant="body2" textAlign={'left'}>
                                                                {line}
                                                                {lineIndex !== section.content.length - 1 && <br />}
                                                            </Typography>
                                                        ))
                                                    )
                                                }
                                            </>


                                        );
                                    })}
                                    {scrollable && <Box ref={endOfMessagesRef}></Box>}
                                    {answerAttachment &&
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center' }}>
                                            {answerAttachment}
                                        </Box>}
                                </BarMessageContent>

                            </Box>

                            <CardActions sx={{ justifyContent: 'space-between', m: 0.1 }}>
                                <Typography variant="body1" fontStyle={'italic'} color="textSecondary" textAlign={'left'}>
                                    {timeConversion(timestamp)}
                                </Typography>

                                <Box sx={{ alignItems: 'flex-start', flexDirection: 'row' }}>
                                    {isUserMessage === false &&
                                        <IconButton sx={{ m: 0, p: 0.5 }} onClick={
                                            () => {
                                                if (onCopyToClipboard) {
                                                    if (id !== undefined) {
                                                        onCopyToClipboard(message);
                                                    }
                                                }
                                            }
                                        }>
                                            <ContentCopyIcon fontSize='small' />
                                        </IconButton>
                                    }
                                    {isUserMessage === false && showFeedback === true && <>
                                        <IconButton sx={{ m: 0, p: 0.5 }} onClick={
                                            () => {
                                                if (onFeedbackThumbUp) {
                                                    if (id !== undefined) {
                                                        onFeedbackThumbUp(id);
                                                    }
                                                }
                                            }
                                        }>
                                            {feedback === true &&
                                                <ThumbUpIcon fontSize='small' />}
                                            {feedback === false &&
                                                <ThumbUpOffAltOutlinedIcon fontSize='small' />}
                                            {feedback === null &&
                                                <ThumbUpOffAltOutlinedIcon fontSize='small' />}
                                        </IconButton>
                                        <IconButton sx={{ m: 0, p: 0.5 }} onClick={
                                            () => {
                                                if (onFeedbackThumbDown) {
                                                    if (id !== undefined) {
                                                        onFeedbackThumbDown(id);
                                                    }
                                                }
                                            }
                                        }>
                                            {feedback === true &&
                                                <ThumbDownOffAltOutlinedIcon fontSize='small' />}
                                            {feedback === false &&
                                                <ThumbDownIcon fontSize='small' />}
                                            {feedback === null &&
                                                <ThumbDownOffAltOutlinedIcon fontSize='small' />}
                                        </IconButton>
                                    </>
                                    }

                                    {isUserMessage === true && <>
                                        <Box></Box>
                                    </>
                                    }
                                </Box>
                            </CardActions>
                        </Card>

                    </BarMessageContainer>
                </Box>
            </Box>
        </Box>
    )
};


interface DialogProps {
    messages: ChatMessageProps[];
}

const Dialog: React.FC<DialogProps> = ({ messages }) => {

    // const endOfMessagesRef = useRef<HTMLElement | null>(null);

    // useEffect(() => {
    //     if (endOfMessagesRef.current) {
    //         endOfMessagesRef.current?.scrollIntoView({behavior: 'smooth' });
    //     }

    // }, [messages]);  // Assuming `messages` is a prop that gets updated when a new message is added


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px', overflow: 'auto', flexGrow: 1 }}>
            {messages.map((message, index) => (

                <BarChatMessage key={index} {...message} showFeedback={false} />

            ))}
            {/* <Box ref={endOfMessagesRef}></Box> */}
        </Box>
    )
};

export { BarChatMessage, Dialog, DialogProps, BarMessageContainer, BarMessageContent };