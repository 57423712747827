
import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { UserStoreMSAL } from "../stores/user/UserStoreMSAL";
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';

import RootStoreContext from './RootStoreProvider';
import checkGroupRights from '../auth/accessrights/GroupRights';

// import { stringToFakeNamespaceUUID, stringToUuidV5 } from '../auth/uuid_utils';
// Define a custom namespace.  Readers, create your own using something like// https://www.uuidgenerator.net/const 

const MsalInitialization = ({ children, userStore, validateGroupAccessEnabled, allowedGroups }) => {

    
    const [interaction, setInteraction] = useState(false)
    const { instance } = useMsal();

    const { rootStore } = useContext(RootStoreContext)
    // const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {


        console.log('MsalInitialization')
        let checkUserLogIn = async () => {
            console.log('window.location.pathname', window.location.pathname)
            let redirectablePaths = ['/', '/chat', '/documentchat']
            let match = redirectablePaths.some((path) => {
                if (window.location.pathname === path) {
                    return true
                }
                return false
            })
            console.log('MsalInitialization:match', match)
            if (match) {

                let userLogin = await userStore.checkUserLogIn()
                if (userLogin) {
                    // console.log(rootStore)
                    // if (rootStore !== undefined) {

                    //     let featurePlugins = getFeaturePlugins(rootStore)
                    //     if (featurePlugins.length > 0) {

                    //         console.log('initializePlugins')
                    //         rootStore.initializePlugins(featurePlugins)
                    //         rootStore.setPlugins(featurePlugins)
                    //     }
                    // }

                    // if (rootStore?.plugins !== undefined) {
                    //     let tools: SidebarSubMenuItem[] = []
                    //     rootStore?.plugins.forEach((feature: IFeaturePlugin) => {

                    //         let item: SidebarSubMenuItem = feature.menuItems(feature.activated.bind(feature))
                    //         if (item !== undefined) {
                    //             tools.push(item)
                    //         }
                    //     })
                    //     setSelectableTools(tools)
                    // }
                    // navigate('/chat')
                } else {
                    console.log("RouteAuthenticationGuardMsal :User not logged in")
                    const account = instance.getActiveAccount();
                    navigate('/')
                }
            }
        }

        checkUserLogIn()

        if (instance) {

            let isMsal = userStore instanceof UserStoreMSAL
            if (isMsal) {
                console.log('isMsal', isMsal)
                console.log('setting instance to user Store useEffect')
                userStore.instance = instance
            }

            const callbackId = instance.addEventCallback(async (event) => {
                switch (event.eventType) {

                    case EventType.LOGIN_SUCCESS:
                        if (event.payload.account) {
                            const account = event.payload.account;
                            const group = account.idTokenClaims.group
                            console.log('EventType.LOGIN_SUCCESS')
                            console.log('account', account)

                            if (validateGroupAccessEnabled === true) {
                                let allowedGroup = checkGroupRights(group, allowedGroups)
                                if (allowedGroup === false) {

                                    rootStore.setInvalidGroup(true)
                                    navigate('/unauthorized')
                                    break
                                }
                            }
                            instance.setActiveAccount(account);
                            console.log('MsalInitialization: LOGIN_SUCCESS');
                            console.log('account', account)
                            const silentRequest = {
                                scopes: ["openid", "offline_access", "profile"], // Adjust with your own scopes
                                account: account,  // The account object you want to acquire the token for
                            };

                            instance.acquireTokenSilent(silentRequest)
                                .then(response => {
                                    // Token acquisition succeeded
                                    // Update state to indicate user is authenticated
                                    // let token = response.idToken
                                    // console.log('acquireTokenSilent succeeded', response)
                                    // let sub = account.idTokenClaims.sub
                                    // if (sub === undefined) {
                                    //     navigate('/unauthorized'); // Navigate to the main page.
                                    //     return
                                    // }
                                    
                                    // userStore?.getOrCreateUser(sub, token).then( async (user) => {
                                    //     console.log('user', user)
                                        
                                    //     userStore?.setUser({
                                    //         guid: user.id, // uuid converter
                                    //         username: user.username
                                    //     })
                                    //     userStore?.setLoggedIn(true);
                                    //     console.log('user', user)
                                        
                                    //     // console.log('navigate to chat')
                                    //     // await rootStore.initAIShield()
                                    //     // navigate('/chat'); // Navigate to the main page.
                                    // })

                                })
                                .catch(error => {
                                    console.log(error)
                                    if (error instanceof instance.InteractionRequiredAuthError) {
                                        // This means interaction is required (i.e., user needs to sign in again)
                                        // You can decide here if you want to redirect the user to login
                                        navigate('/login')
                                    } else {
                                        // Handle other potential errors here
                                    }
                                });
                        }

                        break
                    case EventType.HANDLE_REDIRECT_START:
                        console.log('index:HANDLE_REDIRECT_START');
                        break;
                    case EventType.HANDLE_REDIRECT_END:
                        console.log('index:HANDLE_REDIRECT_END');
                        break;
                    case EventType.INITIALIZE_END:
                        console.log('index:INITIALIZE_END');
                        break;

                    case EventType.INITIALIZE_START:
                        console.log('index:INITIALIZE_START');
                        break;
                    case EventType.ACCOUNT_ADDED:
                        console.log('index:ACCOUNT_ADDED');
                        break;

                    case EventType.ACCOUNT_REMOVED:
                        console.log('index:ACCOUNT_REMOVED');
                        break;
                    case EventType.LOGIN_START:
                        console.log('index:INITIALIZE_START');
                        break;

                    case EventType.LOGIN_FAILURE:
                        console.log('index:LOGIN_FAILURE');
                        rootStore.clear()
                        navigate('/unauthorized'); // Navigate to the main page.
                        break;

                    case EventType.ACQUIRE_TOKEN_START:

                        console.log('index:ACQUIRE_TOKEN_START');
                        break;

                    case EventType.ACQUIRE_TOKEN_SUCCESS:

                        console.log('index:ACQUIRE_TOKEN_SUCCESS');
                        
                        // instance.loginRedirect();
                        if (userStore?.isLoggedIn === false) {

                            let account = instance.getActiveAccount()
                            const group = account.idTokenClaims.group
                            console.log('account', account)
                            
                            if (validateGroupAccessEnabled === true) {
                                let allowedGroup = checkGroupRights(group, allowedGroups)
                                if (allowedGroup === false) {
                                    // navigate('/unauthorized'); // Navigate to the main page.
                                    rootStore.setInvalidGroup(true)
                                    navigate('/unauthorized')
                                    break
                                }
                            }
                            const silentRequest = {
                                scopes: ["openid", "offline_access", "profile"], // Adjust with your own scopes
                                account: account,  // The account object you want to acquire the token for
                            };

                            instance.acquireTokenSilent(silentRequest)
                                .then(response => {
                                    // Token acquisition succeeded
                                    // Update state to indicate user is authenticated
                                    let token = response.idToken
                                    console.log('acquireTokenSilent succeeded', response)
                                    let sub = account.idTokenClaims.sub
                                    if (sub === undefined) {
                                        navigate('/unauthorized'); // Navigate to the main page.
                                        return
                                    }
                                    
                                    userStore?.getOrCreateUser(sub, token).then( async (user) => {
                                        console.log('user', user)
                                        
                                        userStore?.setUser({
                                            guid: user.guid, // uuid converter
                                            username: user.username
                                        })
                                        userStore?.setLoggedIn(true);
                                        console.log('user', user)
                                        
                                        // console.log('navigate to chat')
                                        // await rootStore.initAIShield()
                                        // navigate('/chat'); // Navigate to the main page.
                                    })

                                })
                                .catch(error => {
                                    console.log(error)
                                    if (error instanceof instance.InteractionRequiredAuthError) {
                                        // This means interaction is required (i.e., user needs to sign in again)
                                        // You can decide here if you want to redirect the user to login
                                        navigate('/login')
                                    } else {
                                        // Handle other potential errors here
                                    }
                                });

                        } else {
                            console.log('already logged in')
                            //await rootStore.initAIShield()
                            //rootStore.setAppReady(true)
                        }

                        break

                    case EventType.ACQUIRE_TOKEN_FAILURE:

                        console.log('index:ACQUIRE_TOKEN_FAILURE');
                        rootStore.clear()
                        navigate('/login'); // Navigate to the main page.
                        break

                    case EventType.ACQUIRE_TOKEN_NETWORK_START:

                        console.log('index:ACQUIRE_TOKEN_NETWORK_START');
                        break

                    case EventType.SSO_SILENT_START:

                        console.log('index:SSO_SILENT_START');
                        break

                    case EventType.SSO_SILENT_SUCCESS:

                        console.log('index:SSO_SILENT_SUCCESS');
                        if (userStore?.isLoggedIn === false) {

                            let account = instance.getActiveAccount()
                            const group = account.idTokenClaims.group
                            console.log('account', account)
                            if (validateGroupAccessEnabled === true) {
                                let allowedGroup = checkGroupRights(group, allowedGroups)
                                if (allowedGroup === false) {
                                    // navigate('/unauthorized'); // Navigate to the main page.
                                    rootStore.setInvalidGroup(true)
                                    navigate('/unauthorized')
                                    break
                                }
                            }

                            console.log('isMsal', isMsal)
                            console.log('setting instance to user Store useEffect')

                            let sub = account.idTokenClaims.sub
                            let token = account.idToken
                            userStore?.getOrCreateUser(sub, token).then( async (user) => {
                                console.log('user', user)
                                
                                userStore?.setUser({
                                    guid: user.guid, // uuid converter
                                    username: user.username
                                })
                                await rootStore.initAIShield()
                                userStore?.setLoggedIn(true);
                                navigate('/chat'); // Navigate to the main page.
                            })
                            

                        } else {

                            console.log('already logged in')
                            //rootStore.setAppReady(false)
                            //await rootStore.initAIShield()
                            //rootStore.setAppReady(true)
                            navigate('/chat'); // Navigate to the main page.

                        }
                        break

                    case EventType.SSO_SILENT_FAILURE:

                        console.log('index:SSO_SILENT_FAILURE');
                        try {

                            await instance.loginRedirect();

                        } catch (error) {
                            console.log(error);
                        }

                    case EventType.ACQUIRE_TOKEN_BY_CODE_START:

                        console.log('index:ACQUIRE_TOKEN_BY_CODE_START');
                        break

                    case EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS:

                        console.log('index:ACQUIRE_TOKEN_BY_CODE_FAILURE');
                        break

                    case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:

                        console.log('index:ACQUIRE_TOKEN_BY_CODE_SUCCESS');
                        break

                    case EventType.LOGOUT_START:

                        console.log('index:LOGOUT_START');
                        break

                    case EventType.LOGOUT_SUCCESS:

                        console.log('index:LOGOUT_SUCCESS');
                        break

                    case EventType.LOGOUT_FAILURE:

                        console.log('index:LOGOUT_FAILURE');
                        break

                    case EventType.LOGOUT_END:

                        console.log('index:LOGOUT_END');
                        break

                    default:
                        console.log('default', event.eventType)
                        break;
                }
            });


            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId); // Cleanup event callback on unmount.
                }
            };
        }
    }, [instance, userStore]);





    return <>
        {interaction === true && <><div>We are logging you ing</div><CircularProgress></CircularProgress></>}
        {children}
    </>
}

export default MsalInitialization