

import React from 'react';
import { SummarizationStoreProvider } from '../context/SummarizationStoreProvider';
import { SummarizationView } from './Summarization/SummarizationView';

import { QuickActions } from '../../../types/ChatTypes';
import { Box, Typography } from '@mui/material';
import { SideBarView } from '../../../types/UITypes';
import ChatHistory from '../../../components/ChatHistory/ChatHistory';
import { SummarizationStore } from '../stores/SummarizationStore';


export function chatFeatureNode(summarizationStore: SummarizationStore,
    quickActions: QuickActions[],
    onFeedbackThumbUp?: (messageId: string) => void,
    onFeedbackThumbDown?: (messageId: string) => void
): React.ReactNode {
    return (
        <SummarizationStoreProvider summarizationStore={summarizationStore}>
            <SummarizationView quickActions={quickActions}
                onFeedbackThumbUp={onFeedbackThumbUp}
                onFeedbackThumbDown={onFeedbackThumbDown} />
        </SummarizationStoreProvider>
    );
}

export function chatHistorySidebarElement(title: string, summarizationStore: SummarizationStore,
    onConversationSelected: (converstationId: string) => void,
    onConversationDeleted: (conversationId: string) => void): SideBarView {

    return {
        title: title,
        icon: "settings",
        sidebarElement: (<SummarizationStoreProvider summarizationStore={summarizationStore}>
            <Box display={'flex'} flexDirection={'column'}  >

            </Box>
        </SummarizationStoreProvider>)
    } as SideBarView;
}

// export function chatSettingsSidebarElement(title: string, summarizationStore: SummarizationStore): SideBarView {
//     return {
//         title: title,
//         icon: "settings",
//         sidebarElement: (<ChatStoreProvider chatStore={chatStore}>
//             <Box display={'flex'} flexDirection={'column'} >
//                 <Typography variant={'h6'}>Chat Settings</Typography>
//             </Box>
//         </ChatStoreProvider>)
//     } as SideBarView;
// }
