

import i18n from '../../../i18n';
import { SidebarSubMenuItem } from '../../../layouts/Dashboard/types';
import { Constants } from '../../../contants/Constants';
import ArticleIcon from '@mui/icons-material/Article';

export function documentChatMenuItemFactory(initFunction: any): SidebarSubMenuItem {
    return {
        text: i18n.t('sidebar.menuEntries.docChat'),
        icon: <ArticleIcon />,
        route: Constants.Routes.DocumentChat,
        initFunction
    } as SidebarSubMenuItem;
}
