import { FormEvent, Fragment, useContext, ReactNode, useEffect } from "react";
import {
    Button,
    Container,
    Avatar,
    Typography,
    Box,
    CssBaseline,
    Link,
    useTheme,
    IconButton,
    Card,
    CardContent,
    CardHeader
} from "@mui/material";

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import UserStoreContext from "../../contexts/UserStoreProvider";
import { useMsal } from "@azure/msal-react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadIcon from '@mui/icons-material/Download';
import { DocumentItem } from "../DocumentListDialog/DocumentListDialog";

import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'; // Example icon
import GavelIcon from '@mui/icons-material/Gavel'; // Example icon
import PolicyIcon from '@mui/icons-material/Policy'; // Example icon
import DescriptionIcon from '@mui/icons-material/Description'; // Example icon
import LaunchIcon from '@mui/icons-material/Launch';
import { env } from "../../env";

interface LoginForwardProps {
    pageTitle?: ReactNode;
}

const LoginForward: React.FC<LoginForwardProps> = ({ pageTitle }) => {

    const { t } = useTranslation();
    const { userStore } = useContext(UserStoreContext);

    const documents: DocumentItem[] = [
        { name: t("downloadDocumentsDialog.documents.terms_of_use"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231208_Nutzungsbedingungen_blueGPT_FinalV2.pdf", Icon: DescriptionIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.richtlinie"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231208_Vorlaeufige_Richtlinie_blueGPT_Final.pdf", Icon: PolicyIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.code_of_conduct"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/Code_of_Conduct_for_Azure_OpenAI_Service-Content_requirements.pdf", Icon: GavelIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.data_protection"), link: "https://genaiplatformdb5086bd.blob.core.windows.net/aadb2c/Documents/20231205_Datenschutzhinweise_blueGPT.pdf", Icon: PrivacyTipIcon, LinkIcon: DownloadIcon },
        { name: t("downloadDocumentsDialog.documents.community_guidelines"), link: "https://bluenet.lbbw.sko.de/de/ahfmgsb4tt", Icon: LaunchIcon, LinkIcon: LaunchIcon }
    ];

    // const termsOfServiceUrl = env.REACT_APP_TERMS_OF_SERVICE_URL

    const loginHandler = async (event: FormEvent) => {
        event.preventDefault();

        if (userStore !== undefined) {
            let username = ''
            let password = ''
            console.log(userStore)
            userStore.login(username, password)
        }
    };

    const { instance } = useMsal();
    const theme = useTheme();

    const isBlue = env.REACT_APP_NAME === 'LBBW'
    console.log('isBlue', isBlue)

    useEffect(() => {
        if (instance) {

        }
    }, [instance, userStore]);

    const documentList = (
        <List>
            {documents.map((doc: DocumentItem, index: number) => (
                <ListItem key={index} secondaryAction={
                    <IconButton edge="end" aria-label="download" component="a" href={doc.link} target="_blank" rel="noopener noreferrer">
                        <doc.LinkIcon htmlColor={theme.palette.primary.main} />
                    </IconButton>
                }>
                    <ListItemIcon>
                        <doc.Icon htmlColor={theme.palette.primary.main} />
                    </ListItemIcon>
                    <ListItemText primary={doc.name} />
                </ListItem>
            ))}
        </List>
    );

    return (
        <Fragment>
            <CssBaseline />
            <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#ffffff' }}>
                <Container maxWidth="md" sx={containerStyle} >
                    <Box sx={mainContentStyle}>
                        <Box sx={heroSectionStyle}>
                            <Typography variant="h2" gutterBottom color={'textPrimary'}>
                                {pageTitle || isBlue === false ? t('login.title') : t('login_blue.title')}
                            </Typography>
                            {isBlue === false &&
                                <Typography variant="h6" color="textSecondary" paragraph>
                                    {t('login.subtitle1')}
                                </Typography>
                            }
                            {isBlue === true &&
                                <LazyLoadImage
                                    height={'80px'}
                                    src={'./Schriftzug_bluegpt_Positiv_RGB_2.png'}
                                    alt={`App Logo`}
                                    effect="blur"
                                    style={{
                                        filter:
                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                                
                            }
                            { env.REACT_APP_STAGE_NAME !== undefined && 
                                <Box sx={{ bgcolor: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant='h4' sx={{ m: 1, fontWeight: 'bold', color: '#FF0000'  }}>  {env.REACT_APP_STAGE_NAME}</Typography>
                                </Box>  
                            }
                            <Box display="flex" flexDirection={"row"} justifyContent={'center'} >
                                <Button
                                    onClick={loginHandler}

                                    variant="contained"
                                    sx={{ mt: 8, mb: 2 }}
                                >
                                    {t('login_customer.signin')}
                                </Button>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={"row"} justifyContent={'center'} >
                            {documentList}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Fragment >
    );
}

export default observer(LoginForward);

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%', // Ensures the container takes at least the full height of the viewport
} as const;

const mainContentStyle = {
    flex: 1, // Lets the main content grow and consume available space
    paddingBottom: '2rem', // Some padding before the footer
    height: '100%'
} as const;

const heroSectionStyle = {
    py: 8,
    textAlign: 'center',
    backgroundImage: 'url(path-to-background-image.jpg)',  // Optional: Add an appealing background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',  // White color for better contrast with background
} as const;

const loginSectionStyle = {
    py: 4,
    mt: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    boxShadow: 3,
    padding: 3
} as const;

const avatarStyle = {
    m: 1
} as const;

const loginButtonStyle = {
    my: 2
} as const;

const footerStyle = {
    mt: 5,
    py: 3,
    borderTop: '1px solid #eaeaea'
} as const;
