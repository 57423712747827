import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1E1E1E',
        },
        secondary: {
            main: '#FFFFFF', // White
        },
        error: {
            main: '#D50000', // An error color, if needed
        },
        background: {
            default: '#F3F1EC', // Light gray for the background
        },
        text: {
            primary: '#000000', // Black color for primary text
            secondary: '#757575', // Gray color for secondary text
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    "&.MuiButton-root": {
                        backgroundColor: '#FFFFFF',
                        color: '#1E1E1E',
                        '&:hover': {
                            backgroundColor: '#FFFFFF', // Darken background color for hover state
                            borderColor: '#00FF00', // Darken border color for hover state
                        },
                    },
                    '&.MuiButton-primary': {
                        backgroundColor: '#00FF00', // Primary background color
                        color: '#1E1E1E', // White color for text
                        '&:hover': {
                            backgroundColor: '#1D1D1D', // Darken background color for hover state
                        },
                    },
                    '&.MuiButton-secondary': {
                        backgroundColor: '#00FF00', // White background color
                        color: '#1E1E1E', // Black color for text
                        '&:hover': {
                            backgroundColor: '#CCCCCC', // Gray background color for hover state
                        },
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        color: '#000000', // Black color for text
                    },
                    '& .MuiInputBase-input': {
                        color: '#000000', // Black color for text
                    },
                    '& .MuiInputLabel-root': {
                        color: '#757575', // Gray color for label
                    },
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#1E1E1E', // Black color for underline
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#1E1E1E', // Black color for underline
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                        color: '#1E1E1E', // Black color for label when focused
                    },
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#1E1E1E', // Black color for background
                        color: '#FFFFFF', // White color for text
                    }
                }
            }
        }
    },
});

export default responsiveFontSizes(theme);
