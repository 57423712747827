import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import ReactMarkdown from 'react-markdown';

interface InfoPopperProps {
    title: string;
    description: string;
}

export const InfoPopper: React.FC<InfoPopperProps> = ({ title, description }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'info-popper' : undefined;

    return (
        <Box >
            <IconButton aria-label="info" size="small" onClick={handleClick}>
                <InfoOutlinedIcon />
            </IconButton>
            <Popper sx={{
                zIndex: 10000,
                maxWidth: 400,
            }} id={id} open={open} anchorEl={anchorEl} placement="right" >
                <ClickAwayListener onClickAway={handleClose}>
                    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {title}
                            </Typography>
                            <ReactMarkdown>
                                {description}
                            </ReactMarkdown>
                        </CardContent>
                    </Card>
                </ClickAwayListener>
            </Popper>
        </Box >
    );
}
