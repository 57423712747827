
import { RootStore } from "./RootStore";
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

import i18n from '../i18n';

export class UIStore {
    rootStore: RootStore;

    @observable
    _updateUI: boolean = false

    _showDocumentListDialog: boolean = false

    _currentLanguage: string = i18n.language

    _availableLanguages: Array<string> = ['DE', 'EN']

    _availableLanguageMapping: Map<string, string> = new Map([
        ['EN', 'en'],
        ['DE', 'de']
    ])

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;

    }

    get updateUI() {
        return this._updateUI
    }

    @action
    setUpdateUI() {
        this._updateUI = !this.updateUI
    }

    get showDocumentListDialog() {
        return this._showDocumentListDialog
    }

    @action
    setShowDocumentListDialog(showDocumentListDialog: boolean) {
        this._showDocumentListDialog = showDocumentListDialog
    }

    onDocumentListDialogClose() {
        this.setShowDocumentListDialog(false)
    }

    onSelectedLanguageCallback(option: string) {
        i18n.changeLanguage(this._availableLanguageMapping.get(option) as string)
        
    }

    clear() {
        console.log('UIStore.clear')
        this._updateUI = false
        this._currentLanguage = i18n.language
    }
}