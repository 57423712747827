import axios, { AxiosInstance } from 'axios';

import { env } from '../env';

export interface AIShieldTrackingResponseModel {
    status: boolean
}

export enum AIShieldEventType {
    Warning = "warning",
    Stop = "stop",
}

interface CreateShieldEventResponse {
    id: string; // Assuming UUID type is defined in the imported library
    userId: string; // TypeScript uses camelCase for variable names
    categories: string[];
    prompt: string;
    content: string;
    status: AIShieldEventType; // Make sure AIShieldEventType is imported if it's defined in another file
}

class AIShieldService {

    private client: AxiosInstance;

    constructor(baseURL: string, service: string = 'conversations') {
        this.client = axios.create({ baseURL: baseURL + `/api/${service}` });
    }

    async createEvent(userId: string, 
        content: string, 
        categories: string[], 
        aIShieldEventType: AIShieldEventType, 
        token?: string):
        Promise<CreateShieldEventResponse | undefined> {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true,
            params: {

            }
        };

        let data = {
            user_id: userId,
            content: content,
            status: aIShieldEventType,
            categories: categories
        }

        console.log('recordAIShield', data, config, data)
        try {
            let response = await this.client.post<CreateShieldEventResponse>('ai_shield_event', data, config);
            return response.data as CreateShieldEventResponse
        } catch (error: any) {
            throw error
        }
    }

    async updateEvent(id: string, messageId: string, token?: string):
        Promise<AIShieldTrackingResponseModel | undefined> {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true,
            params: {

            }
        };

        let data = {
            id: id,
            message_id: messageId
        }

        console.log('updateAIShield', data, config, data)
        try {
            await this.client.post<AIShieldTrackingResponseModel>('ai_shield_event/update', data, config);

            return {
                status: true
            } as AIShieldTrackingResponseModel
        } catch (error: any) {
            throw error
        }
    }
}

export const aiShieldTrackingService = new AIShieldService(
    env.REACT_APP_BACKEND_URL ?? "http://localhost:3001",
    "conversations"
);
