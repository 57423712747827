import { action, makeAutoObservable, observable } from "mobx"

import { RootStore } from "./RootStore"
import { SelectableModel } from "../types/types_api"
import { modelInfoService } from "../services/ModelService"
import i18n from "../i18n"
import { v4 as uuidv4 } from 'uuid';

export class ModelSelectionStore {

    rootStore: RootStore | undefined = undefined

    @observable
    _currentModel: SelectableModel | undefined = undefined

    @observable
    _modelSelectionVisible: boolean = true

    @observable
    _availableModels: Array<SelectableModel> = []

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)

        this._currentModel = undefined
        this._modelSelectionVisible = true
        this._availableModels = []
        this.rootStore = rootStore

    }

    get currentModel(): SelectableModel | undefined {
        return this._currentModel
    }

    @action
    setCurrentModel(currentModel: SelectableModel | undefined) {
        this._currentModel = currentModel
    }

    get modelSelectionVisible() {
        return this._modelSelectionVisible
    }

    @action
    setModelSelectionVisible(modelSelectionVisible: boolean) {
        this._modelSelectionVisible = modelSelectionVisible
    }

    get availableModels(): Array<SelectableModel> {
        return this._availableModels
    }

    @action
    setAvailableModels(availableModels: Array<SelectableModel>) {
        this._availableModels = availableModels
    }

    async retrieveAvailableModels(skipModels: string[] = []) {
        // console.log("ModelSelectionStore.retrieveAvailableModels")
        try {
            let token = await this.rootStore?.userStore?.getAccessToken()
            if (token !== undefined) {
                let selectableModels = await modelInfoService.getAvailableModels(token)
                // can we move this to the backend
                let seletableModelsFiltered = selectableModels.filter(model => !skipModels.includes(model.id))
                for (let model of seletableModelsFiltered) {
                    model.guid = uuidv4()
                }
                // console.log("ModelSelectionStore.retrieveAvailableModels: selectableModels", selectableModels)
                this.setAvailableModels(seletableModelsFiltered)
                if (seletableModelsFiltered.length > 0) {
                    this.setCurrentModel(seletableModelsFiltered[0])
                }
                this.setModelSelectionVisible(true)
            }
        } catch (error) {

            console.log(error)
            this.rootStore?.notify(i18n.t("errorMessages.modelSelection.receiveModelsFailed"), { variant: 'error' })
        }
        // // later move to api
        // const selectableModels = [
        //     {
        //         "name": "GPT-3.5",
        //         "model_id": "gpt-35"
        //     },
        //     {
        //         "name": "GPT-4",
        //         "model_id": "gpt-4"
        //     }
        // ] as SelectableModel[]
        // this.setAvailableModels(selectableModels)
        // this.setCurrentModel(selectableModels[0])

    }

    initializeFromModelId(modelId: string) {

        const model = this._availableModels.find(model => model.id === modelId)
        if (model) {
            this._currentModel = model
        }
        this.setModelSelectionVisible(false)
    }

    clear() {
        console.log('ModelSelectionStore.clear')
        this._currentModel = undefined
        this._modelSelectionVisible = true
        this._availableModels = []
    }
}