import { AskQuestionStreamingMessage } from "../../types/WebletServiceTypes";
import { IEventConnection } from "./ConnectionInterfaces";
import { StreamingCommand } from "../WebSocketClient";

export class SSEConnection implements IEventConnection {

    private eventSource: EventSource | null = null;
    private _url: string = ''
    private _config: any = undefined

    private _onMessage: ((data: string) => void) | undefined = undefined

    constructor(private url: string, config: any) {
        this._url = url
        this._config = config
    }

    set onMessage(onMessage: ((data: string) => void) | undefined) {
        this._onMessage = onMessage
    }

    isConnectionOpen() {
        return true
    }


    connect() {
        this.eventSource = new EventSource(this.url, this._config);
        this.eventSource.onmessage = (event: MessageEvent) => {
            const receivedData: string = event.data;
            let object = JSON.parse(receivedData) as AskQuestionStreamingMessage

            if (this._onMessage) {
                this._onMessage(receivedData)
            }
            if (object.status === 'completed') {
                this.eventSource?.close()
            }
            if (object.status === 'error') {
                this.eventSource?.close()
            }
        };
    }

    send(data: string) {
        throw new Error("SSE does not support sending data from client to server.");
    }

    sendCommand(command: StreamingCommand) {
        throw new Error("SSE does not support sending data from client to server.");
    }

    onmessage(handler: (event: MessageEvent) => void) {
        if (this.eventSource) {
            this.eventSource.onmessage = handler;
        }
    }

    disconnect() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }
}