import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0061A3',
        },
        secondary: {
            main: '#66A0C7', // White
        },
        error: {
            main: '#D50000', // An error color, if needed
        },
        background: {
            default: '#F5F5F5', // Light gray for the background
        },
        text: {
            primary: '#000000', // Black color for primary text
            secondary: '#757575', // Gray color for secondary text
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#66A0C7', // Replace with your desired color
            },
            },
        },
    },
});

export default responsiveFontSizes(theme)
