

function checkGroupRights(group: string, allowedGroups: Array<string>) {
    if (group === undefined) {
        return true
    }
    let allowed = false
    allowedGroups.forEach(g => {
        if (group.includes(g)) {
            allowed = true

        }
    })
    return allowed
}


export default checkGroupRights

