

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

import { observer } from 'mobx-react';


interface YesNoDialogProps {
    title: string;
    content: string;
    yesButton: string;
    noButton: string;
    onYes: () => void;
    onNo: () => void;
    open: boolean;
    colorButtonNo?: boolean;
}

const YesNoDialog: React.FC<YesNoDialogProps> = observer(({ open, title, content, yesButton, noButton, onYes, onNo, colorButtonNo }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleYes = () => {
        onYes()
    };

    const handleNo = () => {
        onNo()
    };

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleNo}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title !== undefined ? title : "New Conversation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content !== undefined ? content : "Are you sure you want to start a new conversation?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' onClick={handleNo}>
                        {noButton !== undefined ? noButton : "No"}
                    </Button>
                    <Button variant='contained' onClick={handleYes} autoFocus color={colorButtonNo ? 'error' : 'primary'}>
                        {yesButton !== undefined ? yesButton : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>

        </ >

    );
})

export { YesNoDialog };