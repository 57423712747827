import React, { useState } from 'react';
import { Typography, Button, Popper } from '@mui/material';
import { ConversationAttachment } from '../../../types/types_api';
import SingleAttachmentViewSmall from './SingleAttachmentView';
import MultiAttachmentViewSmall from './MultiAttachmentView';

interface AttachmentViewSmallProps {
    attachments: ConversationAttachment[] | null
}   

const AttachmentViewSmall: React.FC<AttachmentViewSmallProps> = ({attachments}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // Mock data for attachments, replace with actual data from your store
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            {attachments !== null && attachments.length === 1 && (
                <>
                    <SingleAttachmentViewSmall attachment={attachments[0]} />
                </>
            )} 
            {attachments !== null && attachments.length > 1 &&(
                <>
                    <MultiAttachmentViewSmall attachments={attachments} />
                </>
            )}
            { attachments === null && (
                <Typography variant="caption" color="textSecondary" sx={{ fontStyle: "italic" }}>
                    No attachments
                </Typography>
            )}
        </div>
    );
};

export default AttachmentViewSmall;