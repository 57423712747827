import { ChatMessageProps } from "../../types/ChatTypes";
import { Message } from "../../types/types_api";

// Function to convert Message to ChatMessageProps
export function convertMessageToChatMessageProps(message: Message): ChatMessageProps {
    return {
        message: message.content,
        sender: message.type === 'human' ? 'User' : 'AI', // Adjust sender name as per your use case
        timestamp: message.created_at ? message.created_at : "",
        isUserMessage: message.type === 'human',
        feedback: message.feedback,
        id: message.id,
        // Add any additional fields here if necessary.
        avatarUrl: message.type === 'human' ? 'https://i.imgur.com/7k12EPD.png' : 'https://i.imgur.com/7k12EPD.png'
    };
}