
import { createContext, FC } from "react";
import { IUserStore } from "../stores/interfaces/IUserStore";

const UserStoreContext = createContext({ userStore: undefined as IUserStore | undefined })

interface UserStoreProviderProps {
    children?: React.ReactNode
    userStore?: IUserStore | undefined
}

export const UserStoreProvider: FC<UserStoreProviderProps> = ({ children, userStore }) => {

    return (
        <UserStoreContext.Provider value={{ userStore }}>
            {children}
        </UserStoreContext.Provider >
    )
}

export default UserStoreContext
