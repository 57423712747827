import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStoreProvider } from './contexts/RootStoreProvider';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { getTheme } from './theme/themeLoader';
import { SnackbarProvider, closeSnackbar } from 'notistack';

import { RootStore } from './stores/RootStore';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/msal/authConfig';
import { UserStoreMSAL } from './stores/user/UserStoreMSAL';
import { UserStore } from './stores/user/UserStore';

import { MsalProvider } from '@azure/msal-react';
import MsalInitialization from './contexts/MsalInitialization';
import RouteAuthenticationGuardMsal from './routes/RouteAuthenticationGuardMsal';
import RouteAuthenticationGuard from './routes/RouteAuthenticationGuard';
import LoginForward from './components/LoginForward/LoginForward';

import Login from './components/Login/Login';
import { Close } from '@mui/icons-material';
import { env } from './env';

import { Helmet, HelmetProvider } from 'react-helmet-async';  

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

export const msalInstance = new PublicClientApplication(msalConfig);
let isMsal = env.REACT_APP_AUTH === "msal"
if (isMsal) {

  function handleResponse(resp) {
    if (resp !== null) {
        let accountId = resp.account.homeAccountId;
        msalInstance.setActiveAccount(resp.account);
        //showWelcomeMessage(resp.account);
        console.log("handleResponse: ", resp.account);
    } else {
        // need to call getAccount here?
        const currentAccounts = msalInstance.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            msalInstance.setActiveAccount(activeAccount);
            let accountId = activeAccount.homeAccountId;
            console.log("handleResponse: ", resp.account);
        }
    }
}

  msalInstance.initialize().then(() => {

    msalInstance.handleRedirectPromise().then(handleResponse).catch(err => {
      console.error(err);
    });
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    if (env.REACT_APP_NAME === "GFT") {
      require("./styles/style_gft.css");
    } else if (env.REACT_APP_NAME === "LBBW") {
      require("./styles/style_lbbw.css");
    } else {
      require("./styles/style_gft.css");
    }

    let userStore = undefined
    let isMsal = env.REACT_APP_AUTH === "msal"
    // console.log(isMsal)
    if (isMsal) {
      userStore = new UserStoreMSAL()
    }
    else {
      userStore = new UserStore()
    }

    let rootStore = new RootStore()


    const root = ReactDOM.createRoot(
      document.getElementById('root')
    );

    const allowedGroups = env.REACT_APP_ALLOWED_GROUPS?.split(",").map((group) => group.trim())
    const validateGroupAccessEnabled = env.REACT_APP_VALIDATE_GROUP_ACCESS_ENABLED === "true"

    root.render(
      <ThemeProvider theme={getTheme()}>
        <HelmetProvider>
          <Helmet>
            { env.REACT_APP_STAGE_NAME !== undefined && 
              <title> {env.REACT_APP_STAGE_NAME}</title>
            }
            { env.REACT_APP_STAGE_NAME === undefined && 
              <title> {env.REACT_APP_NAME_DISPLAY}</title>
            }
          </Helmet>
          <RootStoreProvider rootStore={rootStore}>
            <BrowserRouter>
              <React.StrictMode>
                <SnackbarProvider
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  maxSnack={3}
                  action={
                    (id) => (
                      <Close sx={{ color: 'white', ':hover': { transform: 'scale(1.25)', cursor: 'pointer' } }} onClick={() => closeSnackbar(id)} />
                    )
                  }>
                  {isMsal === true &&
                    <MsalProvider instance={msalInstance}>
                      <MsalInitialization userStore={userStore} validateGroupAccessEnabled={validateGroupAccessEnabled} allowedGroups={allowedGroups} >
                        <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuardMsal userStore={userStore} />}
                          loginComponent={<LoginForward />}
                        />
                      </MsalInitialization>
                    </MsalProvider>}
                  {isMsal === false &&
                    <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuard />}
                      loginComponent={<Login />}
                    />
                  }
                </SnackbarProvider>
              </React.StrictMode>
            </BrowserRouter>
          </RootStoreProvider>
        </HelmetProvider>
      </ThemeProvider>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

  })
} else {



  let userStore = undefined


  let isMsal = env.REACT_APP_AUTH === "msal"
  // console.log(isMsal)
  if (isMsal) {
    userStore = new UserStoreMSAL()
  }
  else {
    userStore = new UserStore()
  }

  let rootStore = new RootStore()


  const root = ReactDOM.createRoot(
    document.getElementById('root')
  );

  const favIcon = env.REACT_APP_AVATAR_URL_AI ? env.REACT_APP_AVATAR_URL_AI : "favicon.ico"

  root.render(
    <ThemeProvider theme={getTheme()}>
      <HelmetProvider>
        <Helmet>
          { env.REACT_APP_STAGE_NAME !== undefined && 
            <title> {env.REACT_APP_STAGE_NAME}</title>
          }
          { env.REACT_APP_STAGE_NAME === undefined && 
            <title> {env.REACT_APP_NAME_DISPLAY}</title>
          }
          <link rel="icon" href={favIcon} />
        </Helmet>
        <RootStoreProvider rootStore={rootStore}>
        <BrowserRouter>
          <React.StrictMode>
            <SnackbarProvider
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              maxSnack={3}
              action={
                (id) => (
                  <Close sx={{ color: 'white', ':hover': { transform: 'scale(1.25)', cursor: 'pointer' } }} onClick={() => closeSnackbar(id)} />
                )
              }>
              {isMsal === true &&
                <MsalProvider instance={msalInstance}>
                  <MsalInitialization userStore={userStore} >
                    <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuardMsal />}
                      loginComponent={<LoginForward />}
                    />
                  </MsalInitialization>
                </MsalProvider>}
              {isMsal === false &&
                <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuard />}
                  loginComponent={<Login />}
                />
              }
            </SnackbarProvider>
          </React.StrictMode>
        </BrowserRouter>
      </RootStoreProvider>
      </HelmetProvider>
    </ThemeProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}


