
// https://dev.to/rajeshroyal/reactjs-disable-consolelog-in-production-and-staging-3l38
export const GlobalDebug = (function () {
    const savedConsole = console;

    /**
     * @param debugOn - Determines whether to turn debug mode on or off
     * @param suppressAll - Determines whether to suppress all console outputs or not
     */
    return function (debugOn: boolean, suppressAll: boolean) {
        const suppress = suppressAll || false;

        if (!debugOn) {
            // suppress the default console functionality
            console.log = function () { };

            // suppress all types of consoles
            if (suppress) {
                console.info = function () { };
                console.warn = function () { };
                console.error = function () { };
            } else {
                console.info = savedConsole.info;
                console.warn = savedConsole.warn;
                console.error = savedConsole.error;
            }
        } else {
            console.log = savedConsole.log;
            console.info = savedConsole.info;
            console.warn = savedConsole.warn;
            console.error = savedConsole.error;
        }
    };
})();

