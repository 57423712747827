import { FormEvent, Fragment, useContext, ReactNode, useEffect } from "react";
import {
    Button,
    Container,
    Avatar,
    Typography,
    Box,
    CssBaseline,
    Link,
    useTheme
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import UserStoreContext from "../../contexts/UserStoreProvider";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LaunchIcon from '@mui/icons-material/Launch';
import { env } from "../../env";

interface LoginForwardProps {

}

const InformationPage: React.FC<LoginForwardProps> = ({ }) => {

    const { t } = useTranslation();
    const { userStore } = useContext(UserStoreContext);

    const termsOfServiceUrl = env.REACT_APP_TERMS_OF_SERVICE_URL

    const loginHandler = async (event: FormEvent) => {
        event.preventDefault();

        if (userStore !== undefined) {
            let username = ''
            let password = ''
            console.log(userStore)
            userStore.login(username, password)
        }
    };

    const { instance } = useMsal();
    const theme = useTheme();

    const isBlue = env.REACT_APP_NAME === 'LBBW'

    useEffect(() => {

    }, [instance, userStore]);

    return (
        <Fragment>
            <CssBaseline />
            <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#ffffff' }}>
                <Container maxWidth="md" sx={containerStyle}>
                    {/* Hero Section */}

                    <Box sx={mainContentStyle}>

                        <Box sx={heroSectionStyle}>
                            <Typography variant="h2" gutterBottom color={'textPrimary'}>
                                {t('login_blue.title')}
                            </Typography>
                            {isBlue === true &&
                                <LazyLoadImage
                                    height={'80px'}
                                    src={'./Schriftzug_bluegpt_Positiv_RGB_2.png'}
                                    alt={`App Logo`}
                                    effect="blur"
                                    style={{
                                        filter:
                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            }
                            <Typography sx={{ mt: 8 }} variant="h4" gutterBottom color={'textPrimary'}>
                                {t('login.unauthorized.title')}
                            </Typography>

                            <Typography variant="h6" color="textSecondary" paragraph>
                                {t('login.unauthorized.subtitle')}
                            </Typography>

                            <Box display="flex" flexDirection={"row"} justifyContent={'center'} >
                                <Button
                                    component={Link}
                                    href="https://bluenet.lbbw.sko.de/de/ahfmgsb4tt"
                                    target="_blank"
                                    startIcon={<LaunchIcon />}
                                    variant="contained"
                                    sx={{ mt: 8, mb: 2 }}
                                >
                                    {t('login.unauthorized.button')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {/* Footer */}
                    {/* <Box sx={footerStyle}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'© '}
                        {new Date().getFullYear()} GenAI
                        {'.'}
                        <Link color="inherit" href="#">
                            Privacy Policy
                        </Link>
                        {' | '}
                        <Link color="inherit" href={termsOfServiceUrl}>
                            Terms of Service
                        </Link>
                    </Typography>
                </Box> */}
                </Container>
            </Box>
        </Fragment>
    );
}

export default observer(InformationPage);

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures the container takes at least the full height of the viewport
} as const;

const mainContentStyle = {
    flex: 1, // Lets the main content grow and consume available space
    paddingBottom: '2rem' // Some padding before the footer
} as const;

const heroSectionStyle = {
    py: 8,
    textAlign: 'center',
    backgroundImage: 'url(path-to-background-image.jpg)',  // Optional: Add an appealing background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',  // White color for better contrast with background
} as const;

const footerStyle = {
    mt: 5,
    py: 3,
    borderTop: '1px solid #eaeaea'
} as const;
