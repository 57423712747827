import React, { useState } from 'react';
import { Typography, Button, Popper, IconButton, Link, Box, Tooltip, useTheme } from '@mui/material';
import { ConversationAttachment, ConversationAttachmentDocument } from '../../../types/types_api';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';


interface SingleAttachmentViewSmallProps {
    attachment: ConversationAttachment
}   

const SingleAttachmentViewSmall: React.FC<SingleAttachmentViewSmallProps> = ({attachment}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // Mock data for attachments, replace with actual data from your store
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const sliceLength = 30;

    const theme = useTheme();

    const { t } = useTranslation();


    return (
        <>
            {attachment.attachment_type === 'document' ? (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'}}>
                        <Typography
                            variant="body1"
                            sx={{ mx: 1 }}
                            fontWeight={'bold'}
                            color={'primary'}
                            >{t('feature.docChat.documentAttachmentView.filename')}</Typography>   
                        <Tooltip sx={{
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: theme.palette.background.paper,
                            }
                        }} title={
                            <Typography variant="body1" >
                                {(attachment as ConversationAttachmentDocument).filename}
                            </Typography>} placement="bottom">                
                            <Typography 
                                component={Link} 
                                variant="body1" 
                                sx={{ mx: 1 }} 
                                color={'text.primary'}
                                href={(attachment as ConversationAttachmentDocument).document_url}
                                target='_blank' rel='noreferrer' >
                                {(attachment as ConversationAttachmentDocument).filename.length > sliceLength
                                    ? `${(attachment as ConversationAttachmentDocument).filename.slice(0, sliceLength-3)}...`
                                    : (attachment as ConversationAttachmentDocument).filename}
                            </Typography>
                        </Tooltip>
                    </Box>
                
                    {/* <IconButton
                        aria-label="download"
                        href={(attachment as ConversationAttachmentDocument).document_url}
                        download
                        color="primary"
                        sx={{
                            "&:hover": {
                                backgroundColor: 'action.hover', // Interactive feedback on hover
                            },
                        }}
                    >
                        <CloudDownloadIcon />
                    </IconButton> */}
                </>
            ) : (
                <>
                </>
            )}
            
        </>
    );
};

export default SingleAttachmentViewSmall;