import { observer } from "mobx-react";
import { SidebarSubMenuItem } from "../../layouts/Dashboard/types";
import React, { useContext, useEffect } from "react";
import RootStoreContext from "../../contexts/RootStoreProvider";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import InfoPopper from "../InfoPopper";

interface ToolSelectionProps {
    selectableTools: SidebarSubMenuItem[];
    label: string;
}

const ToolSelection: React.FC<ToolSelectionProps> = observer(({ label, selectableTools }) => {

    const { rootStore } = useContext(RootStoreContext)
    console.log('ToolSelection', selectableTools)

    const navigate = useNavigate()
    const [currentTool, setCurrentTool] = React.useState('');
    const handleChange = (event: SelectChangeEvent) => {
        setCurrentTool(event.target.value);

        for (let i = 0; i < selectableTools.length; i++) {
            if (selectableTools[i].text === event.target.value) {
                let initFunction = selectableTools[i].initFunction
                if (initFunction !== undefined) {
                    initFunction()
                }
                navigate(selectableTools[i].route)
            }
        }
    };

    useEffect(() => {
        if (selectableTools.length > 0) {
            // for (let i = 0; i < selectableTools.length; i++) {
            //     if (selectableTools[i].text === currentTool) {
            //         let initFunction = selectableTools[i].initFunction
            //         if (initFunction !== undefined) {
            //             initFunction()

            //         }
            //     }
            // }
            setCurrentTool(selectableTools[0].text)
            let initFunction = selectableTools[0].initFunction
            if (initFunction !== undefined) {
                initFunction()
            }
            navigate(selectableTools[0].route)
        }
    }, [selectableTools])

    return (
        <Grid container sx={{ m: 1 }} alignItems={'center'}>
            <Grid item xs={10}>
                <FormControl sx={{ m: 1 }} fullWidth size="small">
                    <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
                    <Select
                        sx={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={currentTool}
                        label={label}
                        onChange={handleChange}
                    >
                        {selectableTools.map((menuEntry: SidebarSubMenuItem, index: number) => {
                            return (<MenuItem key={menuEntry.route} value={menuEntry.text}>
                                <Typography textAlign={'left'}>{menuEntry.text}</Typography>
                            </MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                {rootStore !== undefined && rootStore.activePlugin !== undefined &&
                    <InfoPopper title={rootStore.activePlugin.pluginInfo().title}
                        description={rootStore.activePlugin.pluginInfo().description} />
                }
            </Grid>
        </Grid>
    );
})

export { ToolSelection }