import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#F50029',
        },
        secondary: {
            main: '#FFFFFF', // White
        },
        error: {
            main: '#D50000', // An error color, if needed
        },
        background: {
            default: '#F3F1EC', // Light gray for the background
        },
        text: {
            primary: '#000000', // Black color for primary text
            secondary: '#757575', // Gray color for secondary text
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

export default responsiveFontSizes(theme)
