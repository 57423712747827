import { HandleMesssageResult } from "../stores/StreamingChatStore"


export enum ContentStatus {
    Okay = "okay",
    Warning = "warning",
    Stop = "stop",
    Error = "error"
}

export interface IContentFilter {
    init(callback?: (progress: number) => void): Promise<void>
    checkInput(text: string): Promise<IContentFilterResult>
    clear(): void
    
    containsCriticalCategories(categories: Array<String>): boolean
}

export interface IContentFilterResult {
    allowed: boolean
    contentStatus: ContentStatus
    categories_short: string[]
    categories: string[]
    entities: string[]
}
