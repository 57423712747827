import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

import { observer } from 'mobx-react';

import { BarChatMessage } from './ChatViewMessageBars/ChatViewMessageBars';
import { ChatMessageProps, Reference } from '../../types/ChatTypes';
import { Constants } from '../../contants/Constants';
import { AnswerAttachment } from '../AnswerAttachment/AnswerAttachment';
import { useTranslation } from 'react-i18next';


interface Props {
    currentMessage: ChatMessageProps,
    messages: ChatMessageProps[],
    currentTokenList: string[],
    onCopyToClipboard?: (message: string) => void,
    onFeedbackThumbUp?: (messageId: string) => void,
    onFeedbackThumbDown?: (messageId: string) => void
    showFeedBackElement?: boolean
}

const ChatBox: React.FC<Props> = ({ currentMessage,
    messages,
    currentTokenList,
    onCopyToClipboard,
    onFeedbackThumbUp,
    onFeedbackThumbDown,
    showFeedBackElement }) => {

    const boxRef = useRef<HTMLDivElement>(null);
    const endOfMessagesRef = useRef<HTMLElement | null>(null);

    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     if (boxRef.current) {
    //         const element = boxRef.current;
    //         element.scrollTop = element.scrollHeight;
    //     }
    // }, [messages]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, currentTokenList]);


    let exampleReferences: Reference[] = [
        {
            id: "R1",
            source: "The rise of AI in modern applications.",
            page: "15",
            document_name: "Tech Chronicles 2023",
            document_id: "D1"
        },
        {
            id: "R2",
            source: "Statistical methodologies used in deep learning.",
            page: "23",
            document_name: "Tech Chronicles 2023",
            document_id: "D1"
        },
        {
            id: "R3",
            source: "Environmental impacts of blockchain mining.",
            page: "78",
            document_name: "Tech Chronicles 2023",
            document_id: "D1"
        },
        {
            id: "R4",
            source: "Influence of quantum computing on cryptography.",
            page: "32",
            document_name: "Future Technologies 2025",
            document_id: "D2"
        },
        {
            id: "R5",
            source: "Adapting renewable energy solutions for urban areas.",
            page: "50",
            document_name: "Future Technologies 2025",
            document_id: "D2"
        },
        {
            id: "R6",
            source: "Historical analysis of internet evolution.",
            page: "7",
            document_name: "Digital Journey: Past and Present",
            document_id: "D3"
        },
        {
            id: "R7",
            source: "The socio-economic impacts of global digital transformation.",
            page: "29",
            document_name: "Digital Journey: Past and Present",
            document_id: "D3"
        },
    ];

    //exampleReferences = []

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                    {messages.map((message, index) => {
                        let references = message.references
                        return (
                            <BarChatMessage  {...message}
                                key={message.id}
                                showFeedback={(showFeedBackElement === true && message.isUserMessage === false)}
                                onCopyToClipboard={onCopyToClipboard}
                                onFeedbackThumbUp={onFeedbackThumbUp}
                                onFeedbackThumbDown={onFeedbackThumbDown}
                                scrollable={false}
                                avatarUrl={
                                    !message.isUserMessage ? Constants.UI.AvatarUrlAI : ''
                                }

                                answerAttachment={
                                    (!message.isUserMessage && references !== undefined && references.length > 0) ?
                                        <AnswerAttachment title={t('answer.references')} references={references} /> : undefined}
                            />
                        )
                    })}

                    {currentTokenList.length > 0 &&
                        <>
                            <BarChatMessage key="currentMessage" message={currentTokenList.join('')}
                                sender={currentMessage.sender}
                                timestamp={new Date().toISOString()}

                                isUserMessage={currentMessage.isUserMessage}
                                scrollable={true}
                                showFeedback={false}
                                avatarUrl={
                                    Constants.UI.AvatarUrlAI
                                } />

                        </>}
                    <Box ref={endOfMessagesRef} />
                </Box>
            </Box>
        </Box>
    );
};

export default observer(ChatBox);