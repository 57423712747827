
import init, { CategorizedKeywordMatcher } from "../aishield_regex/aishield";
import { env } from "../env";
import aiShieldConfig from "./AIShieldConfig.json";
import { ContentStatus, IContentFilter, IContentFilterResult } from "./AIShieldTypes";



interface TokenClassificationResult {
    task: 'token-classification';
    type: 'result';
    data: Array<{
        type: string;
        text: string;
    }>;
}

export class AIShield implements IContentFilter {

    _keywordMatcher: CategorizedKeywordMatcher | undefined = undefined
    _initialized: boolean = false
    _criticalCategories: Array<string> = []
    _blockedCategories: Array<string> = []
    worker: Worker | undefined = undefined
    constructor() {
        this._keywordMatcher = undefined
        this._initialized = false
        this.worker = undefined
    }

    async init(callback?: (progress: number) => void) {
        if (!this._initialized) {

            this.worker = new Worker(new URL('./NERWorker.js', import.meta.url), {
                type: 'module',
            });

            aiShieldConfig.rules.soft_block.forEach((category) => {
                if (this._criticalCategories.includes(category) === false) {
                    this._criticalCategories.push(category)
                }
            })

            aiShieldConfig.rules.hard_block.forEach((category) => {
                if (this._blockedCategories.includes(category) === false) {
                    this._blockedCategories.push(category)
                }
            })


            // console.log(this._criticalCategories)
            // console.log("AIShield.init: initializing")

            await init(); // Ensure this is correctly awaited if it's async
            console.log("AIShield initialized");
            this._keywordMatcher = new CategorizedKeywordMatcher();

            for (let keywordCategory of aiShieldConfig.keywordCategories) {
                // console.log("AIShield.init: adding keyword category", keywordCategory.name);
                this._keywordMatcher?.add_keyword_category(keywordCategory.short_name, keywordCategory.keywords);
            }

            for (let regexCategory of aiShieldConfig.regexCategories) {
                this._keywordMatcher?.add_regex_category(regexCategory.short_name, regexCategory.regexes);
            }

            this.worker.addEventListener('message', (event) => {
                const message = event.data;
                // console.log("AIShield.init: message from worker", message);
            });

            // Download model here
            let data = {
                source_path: env.REACT_APP_AISHIELD_DATA_URL,
                task: 'download_model',
                text: "textCap",
                elementIdToUpdate: 'contentFilterResult',
                targetType: 'tokens'
            };
            await this.downloadModel(data, callback); // Assuming this returns a promise

            this._initialized = true;
        }
    }

    async downloadModel(input: any, callback?: (v: number) => void): Promise<TokenClassificationResult> {
        const w = this.worker
        return new Promise((resolve, reject) => {
            // Send a message to the worker
            if (this.worker !== undefined) {
                this.worker.postMessage(input);

                // Listen for a response from the worker
                this.worker.onmessage = (event) => {

                    if (event.data.type === "download" && event.data.data.status === 'progress' && event.data.data.file === 'onnx/model_quantized.onnx') {
                        //console.log("AIShield.downloadModel: progress", event.data.data.progress)
                        if (callback) {
                            callback(Math.floor(event.data.data.progress))
                        }
                        //console.log("AIShield.downloadModel: finished", event.data.data.file)
                        //console.log("AIShield.downloadModel: finished", event.data.data.status)
                    }

                    if (event.data.data.status === 'ready') {
                        resolve(event.data);
                    }
                };

                // Optionally: Handle errors
                this.worker.onerror = (error) => {
                    reject(new Error("Error from worker: " + error.message));
                };
            }
        });
    }

    async detectNER(input: any): Promise<TokenClassificationResult> {
        return new Promise((resolve, reject) => {
            // Send a message to the worker
            if (this.worker !== undefined) {
                this.worker.postMessage(input);

                // Listen for a response from the worker
                this.worker.onmessage = (event) => {
                    if (event.data.type === 'result') {
                        resolve(event.data);
                    }
                };

                // Optionally: Handle errors
                this.worker.onerror = (error) => {
                    reject(new Error("Error from worker: " + error.message));
                };
            }
        });
    }

    async checkInput(text: string): Promise<IContentFilterResult> {

        let contentFilterResult: IContentFilterResult = {
            allowed: true,
            contentStatus: ContentStatus.Okay,
            categories_short: [],
            categories: [],
            entities: []
        }

        let textCap = this.capitalizeEachFirstLetter(text)
        let data = {
            task: 'token-classification',
            text: textCap,
            elementIdToUpdate: 'contentFilterResult',
            targetType: 'tokens'
        }

        //console.log("AIShield.checkInput: sending message to worker", data)
        let namedEntities: TokenClassificationResult = await this.detectNER(data)//worker.postMessage(data);
        //console.log("AIShield.checkInput: received message from worker", namedEntities)

        let categories_short: Array<string> = []

        let foundName: boolean = false
        for (let namedEntity of namedEntities.data) {
            if (namedEntity.type === "PER") {
                foundName = true
                break
            }
        }

        //const publicNames = ["Timothy Donald Cook", "Satya Narayana Nadella", "Jeff Bezos"];
        //const nerNames = ["Jeff Hansen", "Tim Cook", "Satya Nadella"];

        const nerNames: string[] = []
        namedEntities.data.forEach((namedEntity) => {
            if (namedEntity.type === "PER") {
                nerNames.push(namedEntity.text)
            }
        })

        // const matchedNames = matchNames(nerNames, publicNames);
        // console.log(matchedNames);



        // console.log("AIShield.checkInput: namedEntities", namedEntities)
        let nameMapping = aiShieldConfig.nameMapping as Record<string, string>;
        if (this._keywordMatcher !== undefined) {

            console.log("AIShield.checkInput: found _keywordMatcher")
            let spansJsValue = this._keywordMatcher.find_keyword_spans(text);
            if (spansJsValue.length > 0) {
                let categoriesKeywords: Array<string> = spansJsValue.map((span: any) => {
                    return span.category
                })
                console.log("AIShield.checkInput: found categories", spansJsValue)
                let set = new Set(categoriesKeywords);

                console.log("AIShield.checkInput: nameMapping", nameMapping)
                set.forEach((category) => {
                    if (category in nameMapping) {
                        //categories.push(nameMapping[category])
                        categories_short.push(category)
                    }
                })

                if (foundName === true) {
                    categories_short.push("name")
                }

                let categories = categories_short.map((category) => {
                    return nameMapping[category]
                })

                let containsCriticalCategories = this.containsCriticalCategories(categories_short)
                let contentStatus: ContentStatus = ContentStatus.Warning
                if (containsCriticalCategories === true) {
                    contentStatus = ContentStatus.Stop
                }
                let contentFilterResult: IContentFilterResult = {
                    allowed: false,
                    contentStatus: contentStatus,
                    categories_short: categories_short,
                    categories: categories,
                    entities: nerNames
                }
                return contentFilterResult
            }
        }

        if (foundName === true) {
            categories_short.push("name")
        }
        if (categories_short.length > 0) {

            let categories = categories_short.map((category) => {
                return nameMapping[category]
            })

            let containsCriticalCategories = this.containsCriticalCategories(categories_short)
            let contentStatus: ContentStatus = ContentStatus.Warning
            if (containsCriticalCategories === true) {
                contentStatus = ContentStatus.Stop
            }
            let contentFilterResult: IContentFilterResult = {
                allowed: false,
                contentStatus: contentStatus,
                categories: categories,
                categories_short: categories_short,
                entities: nerNames
            }
            return contentFilterResult
        }
        return contentFilterResult;
    }

    containsCriticalCategories(criticalCategoriesShort: Array<string> ): boolean {

        const matchesCritical = this._blockedCategories.some((group: string) => {
            let match = criticalCategoriesShort?.includes(group)
            
            return match
        });

        return matchesCritical
    }

    // containsSoftCategories(result: HandleMesssageResult): boolean {
    //     if (result.filterCategoriesShort !== undefined) {

    //         const matchesDialog1 = this._criticalCategories.some((group: string) => {
    //             let match = result.filterCategoriesShort?.includes(group)
    //             console.log("AIShield.containsCriticalCategories: match", match)
    //             console.log("AIShield.containsCriticalCategories: result.filterCategoriesShort", result.filterCategoriesShort)
    //             console.log("AIShield.containsCriticalCategories: this._criticalCategories", this._criticalCategories)
    //             console.log("AIShield.containsCriticalCategories: group", group)
    //             return match
    //         });

    //         console.log(matchesDialog1)

    //         const matchesDialog2 = this._blockedCategories.some((group: string) => {
    //             let match = result.filterCategoriesShort?.includes(group)
    //             console.log("AIShield.containsCriticalCategories: match", match)
    //             console.log("AIShield.containsCriticalCategories: result.filterCategoriesShort", result.filterCategoriesShort)
    //             console.log("AIShield.containsCriticalCategories: this._blockedCategories", this._blockedCategories)
    //             console.log("AIShield.containsCriticalCategories: group", group)
    //             return match
    //         });
    //         if (matchesDialog2) {
    //             return false
    //         }

    //         if (matchesDialog1) {
    //             return true
    //         }
    //     }
    //     return false
    // }

    private capitalizeEachFirstLetter(str: string): string {
        return str
            .toLowerCase()
            .replace(/(?:^|\s|')\S/g, (char) => char.toUpperCase());
    }

    clear() {
        this._initialized = false
        this._keywordMatcher = undefined
    }
}