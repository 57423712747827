import './App.css';

import './i18n';

import { useContext, useEffect } from 'react';
import { GlobalDebug } from './utils/GlobalDebug';

import PagesRoutes from './Pages';
import { UserStoreProvider } from './contexts/UserStoreProvider';
import { IUserStore } from './stores/interfaces/IUserStore';
import RootStoreContext from './contexts/RootStoreProvider';
import { useNavigate } from 'react-router-dom';
import { UserMenuOptions } from './types/UITypes';
import { getFeaturePlugins } from './plugin/featureFactory';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { env } from './env';

export interface AppProps {
  userStore?: IUserStore
  authenticationGuard?: React.ReactNode
  loginComponent?: React.ReactNode
  logoutComponent?: React.ReactNode
}

const App: React.FC<AppProps> = ({ userStore, authenticationGuard, loginComponent }) => {

  const { rootStore } = useContext(RootStoreContext)

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const isProduction = true;

  const appVersion = process.env.REACT_APP_VERSION_NUMBER
  let userMenuOptions: UserMenuOptions[] = []

  if (env.REACT_APP_NAME === "LBBW") {
    userMenuOptions = [
      {
        title: t('downloadDocumentsDialog.menutitle', { appName: env.REACT_APP_NAME_DISPLAY }),
        icon: null,
        action: () => {
          // console.log("Logout button clicked")
          if (rootStore?.uiStore) {
            rootStore?.uiStore.setShowDocumentListDialog(true)
          }
        }
      }
    ];
  } else {
    userMenuOptions = [
      // {
      //   title: t('downloadDocumentsDialog.menutitle', { appName: env.REACT_APP_NAME_DISPLAY }),
      //   icon: null,
      //   action: () => {
      //     // console.log("Logout button clicked")
      //     if (rootStore?.uiStore) {
      //       rootStore?.uiStore.setShowDocumentListDialog(true)

      //     }
      //   }
      // },
      {
        title: 'Logout',
        icon: null,
        action: () => {
          // console.log("Logout button clicked")
          if (userStore) {
            userStore.logout()
            navigate('/login')
          }
        }
      }
    ];
  }

  useEffect(() => {

  }, [])

  return <>
    <UserStoreProvider userStore={userStore}>
      {/* <CacheBuster
        currentVersion={appVersion}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<div></div>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      > */}
      <div className="App">

        {rootStore !== undefined && <PagesRoutes
          authenticationGuard={authenticationGuard}
          loginComponent={loginComponent}
          userMenuOptions={userMenuOptions} />
        }
      </div >
      {/* </CacheBuster> */}
    </UserStoreProvider>
  </>
}

export default observer(App);
