import { RootStore } from "../stores/RootStore";
import { createContext, FC } from "react";

const RootStoreContext = createContext({ rootStore: undefined as RootStore | undefined })

interface RootStoreProviderProps {
    children?: React.ReactNode
    rootStore: RootStore
}

export const RootStoreProvider: FC<RootStoreProviderProps> = ({ children, rootStore }) => {
    return (
        <RootStoreContext.Provider value={{ rootStore }}>
            {children}
        </RootStoreContext.Provider>
    )
}

export default RootStoreContext
