
import { createContext, FC } from "react";
import { SummarizationStore } from "../stores/SummarizationStore"


const SummarizationContext = createContext({ summarizationStore: undefined as SummarizationStore | undefined })

interface SummarizationStoreProviderProps {
    children?: React.ReactNode
    summarizationStore?: SummarizationStore | undefined
}

export const SummarizationStoreProvider: FC<SummarizationStoreProviderProps> = ({ children, summarizationStore }) => {

    return (
        <SummarizationContext.Provider value={{ summarizationStore }}>
            {children}
        </SummarizationContext.Provider >
    )
}

export default SummarizationContext
