import { IEventConnection } from "./ConnectionInterfaces";
import { SSEConnection } from "./SSEConnection";
import { GlobalWebSocket } from "./WebSocketConnection";

export interface StreamingCommand {
    command: string
    runId: string
    service?: string //
}

export class ConnectionFactory {

    createConnection(type: 'ws' | 'sse', url: string, config?: any): IEventConnection {
        if (type === 'ws') {
            return GlobalWebSocket.getInstance(url);
        }
        return new SSEConnection(url, config);
    }
}

export const connectionFactory = new ConnectionFactory();