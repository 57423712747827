import React, { useState } from 'react';
import { Typography, Button, Popper, Popover, Fade, Link } from '@mui/material';
import { ConversationAttachment, ConversationAttachmentDocument } from '../../../types/types_api';

import { Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';



interface SingleAttachmentViewSmallProps {
    attachments: ConversationAttachment[]
}   

const MultiAttachmentViewSmall: React.FC<SingleAttachmentViewSmallProps> = ({attachments}) => {
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // For a single document, don't open the popover
        
        // For multiple documents, open the popover
        setAnchorEl(event.currentTarget);
        
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const {t} = useTranslation();


    return (
        <>
            <Button variant="outlined" aria-describedby={id} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                {attachments.length} {t('feature.docChat.documentAttachmentView.filename')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                TransitionComponent={Fade} // Smooth transition for popover
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: 2, // Rounded corners for the popover
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                        mt: 0.5, // Slight margin above the popover for visual separation
                        overflow: 'hidden', // Prevents inner content from showing outside the border radius
                    }
                }}
                >
                
                <>
                    <Typography component={'span'} variant='body1' color="primary" fontWeight={'bold' }sx={{ ml: 2, mt: 1, pb: 0}}>Documents</Typography>
                    <List dense>
                        {attachments.map((attachment: ConversationAttachment, index: number) => {
                            
                            if (attachment.attachment_type === 'document') {

                                return (
                                <ListItem key={index} sx={{ pl: 2, pr: 2, pb: 1 }}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography component="span" variant="body2" color="primary" fontWeight="medium">
                                                    {index + 1}.
                                                </Typography>
                                                <Typography 
                                                    component={Link} 
                                                    variant="body2" 
                                                    sx={{ mx: 1 }} 
                                                    color={'text.primary'}
                                                    href={(attachment as ConversationAttachmentDocument).document_url}
                                                    target='_blank' rel='noreferrer' >
                                                    {(attachment as ConversationAttachmentDocument).filename}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>)
                            } else {
                                return (
                                    <></>
                                )
                            }
                            })
                        }
                    </List>
                </>
                
            </Popover>
            
        </>
    );
};

export default MultiAttachmentViewSmall;