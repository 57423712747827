

declare global {
    interface Window {
        env: any
    }
}
type EnvType = {
    REACT_APP_BACKEND_URL: string,
    REACT_APP_WEBSOCKET_URL: string,
    REACT_APP_NAME: string,
    REACT_APP_NAME_DISPLAY: string,
    REACT_APP_ONLY_COMPANY_LOGO: string,
    REACT_APP_PROD: string,
    REACT_APP_AUTH: string,
    REACT_APP_FEATURES: string,
    REACT_APP_TERMS_OF_SERVICE_URL: string,
    REACT_APP_TERMS_OF_SERVICE_SHORT: string,
    REACT_APP_TERMS_OF_SERVICE_MARKDOWN: string,
    REACT_APP_RELEASE_NOTES_URL: string,
    REACT_APP_MSAL_REDIRECT: string,
    REACT_APP_MSAL_LOGOUT_URL: string,
    REACT_APP_MSAL_LOGOUT_REDIRECT_URL: string,
    REACT_APP_MSAL_CLIENT_ID: string,
    REACT_APP_MSAL_B2C_NAME: string,
    REACT_APP_MSAL_B2C_SIGNIN_POLICY: string,
    REACT_APP_FRONTEND_URL_BASE: string,
    REACT_APP_VALIDATE_GROUP_ACCESS_ENABLED: string,
    REACT_APP_ALLOWED_GROUPS: string,
    REACT_APP_ENV: string,

    REACT_APP_VERSION_HASH: string,
    REACT_APP_VERSION_NUMBER: string,
    REACT_APP_AVATAR_URL_AI: string,
    REACT_APP_ENABLE_CONTENT_FILTER: string,
    REACT_APP_SHOW_TERMS_OF_USE: string,
    NODE_ENV: string,
    REACT_APP_AUTOLOGOUT_TIMEOUT: number | undefined,
    REACT_APP_FORWARD_AI_FILTERED_MESSAGES: boolean
    REACT_APP_AISHIELD_DATA_URL: string
    REACT_APP_STAGE_NAME: string | undefined
}
export const env: EnvType = { ...process.env, ...window.env }
