import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, Popover, Card, CardHeader, CardContent, ButtonBase } from '@mui/material';
import { SelectableModel } from '../../types/types_api';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';


export interface ModelSelectionBannerProps {
    modelSelectionVisible: boolean;
    modelSelectionTitle: string;
    labelSelectModel: string;
    availableModels: SelectableModel[];
    handleModelSelection?: (selectedModelId: SelectableModel) => void;
}

const ModelSelectionBanner: React.FC<ModelSelectionBannerProps> = ({
    modelSelectionVisible,
    modelSelectionTitle,
    labelSelectModel,
    availableModels,
    handleModelSelection,
}) => {
    const [selectedModel, setSelectedModel] = useState<SelectableModel | null>(availableModels.length > 0 ? availableModels[0] : null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (availableModels.length > 0) {
            setSelectedModel(availableModels[0]);
        }
    }, [availableModels]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleModelSelect = (model: SelectableModel) => {
        setSelectedModel(model);
        handleClosePopover();
        if (handleModelSelection) {
            handleModelSelection(model);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'model-selection-popover' : undefined;

    return (
        <Box
            display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}
            style={{ display: modelSelectionVisible ? 'block' : 'none' }}
        >
            <Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pl: 2, pr: 2 }} elevation={0} >
                <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                    <Button sx={{m: 1}} aria-describedby={id}  variant="outlined" onClick={handleOpenPopover} endIcon={<ArrowDropDownIcon/>}>
                        <Typography sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        
                        }}>{selectedModel ? 
                            selectedModel.name : 
                                labelSelectModel}
                        </Typography>
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Card>
                            <CardContent>
                            <Typography variant='h6' color='primary'> {t('header.availableModels')} </Typography>
                                {availableModels.map((model) => (
                                    <ButtonBase 
                                        key={model.guid} 
                                        onClick={() => handleModelSelect(model)}
                                        sx={{
                                            m: 0,
                                            mt: 1,
                                        }}
                                        style={{ 
                                            width: '100%', 
                                            justifyContent: 'flex-start',
                                            backgroundColor: selectedModel?.guid === model.guid ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                                <Box sx={{m : 2}}>
                                                    {model.name === 'GPT 4' ? <AutoAwesomeOutlinedIcon /> :<ElectricBoltOutlinedIcon />}
                                                </Box>
                                                <Box sx={{m : 2}}>
                                                {selectedModel?.guid === model.guid ? <Typography variant='body2' fontWeight={'bold'}> {model.name} </Typography> : <Typography variant='body2'> {model.name} </Typography> }
                                                </Box>
                                            </Box>
                                            {selectedModel?.guid === model.guid ? <CheckCircleOutlineIcon color='primary' style={{ marginLeft: 'auto' }} /> : <CircleOutlinedIcon/>}
                                        </Box>
                                    </ButtonBase>
                                ))}
                            </CardContent>
                        </Card>
                    </Popover>
                </Box>
            </Paper>
        </Box>
    );
};

export default observer(ModelSelectionBanner);